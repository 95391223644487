import PropTypes from "prop-types";
import { Box, useDisclosure } from "@chakra-ui/react";
import ArrayOfModalEditor from "../array-of-prop-modal-editor/array-of-prop-modal-editor";
import { FiTrash2 } from "react-icons/fi";
import { echoPortal } from "../../../echo-portal/echo-portal";
import QuestionAlert from "../../echo-components/base-components/components/question-alert-component/question-alert.component";

const EchoArrayOfValueCell = ({
  propValue,
  onChange,
  propTypes,
  designerPortalRef,
  componentId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlert
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  return (
    <>
      <ArrayOfModalEditor
        value={propValue}
        onSave={onChange}
        open={isOpen}
        onClose={onClose}
        propTypes={propTypes}
        containerRef={designerPortalRef}
        componentId={componentId}
      />
      <Box
        display="flex"
        justifyContent={propValue ? "space-between" : "flex-end"}
        flexDirection="row"
        marginLeft="8px"
      >
        <Box
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {Array.isArray(propValue) && `Length: ${propValue.length}`}
        </Box>
        <button onClick={onOpen} tabIndex={-1}>
          ...
        </button>
        <button onClick={handleOnDelete} tabIndex={-1}>
          <FiTrash2 size="16px" />
        </button>
      </Box>
    </>
  );
};

EchoArrayOfValueCell.propTypes = {
  propTypes: PropTypes.any,
  propValue: PropTypes.array,
  onChange: PropTypes.func,
  designerPortalRef: PropTypes.shape({ current: PropTypes.any }),
  componentId: PropTypes.number,
};

EchoArrayOfValueCell.defaultProps = {
  propValue: [],
};

export default EchoArrayOfValueCell;
