import { mobileCheck } from "@echo/ui";
import { getDateValue } from "../utils/change-handlers";
import { getSeparator } from "../utils/get-separator";
import { handleBackspace, handleEnter } from "../utils/keydown-handlers";
import { useSelection } from "./use-selection";
import { getFormattedDate } from "../../echo-components/base-components/components/datagrid-component/hooks/use-formatted-date";
import { isDateMatched, isKeyAllowed } from "../utils/validate-character";

export const useDatepickerHandlers = (dateFormat, callback) => {
  const { getSelectionRange } = useSelection();

  const onKeyDown = async (e) => {
    const isAllowed = isKeyAllowed(e.key);
    if (!isAllowed) e.preventDefault();

    const date = e.target.value;
    const pos = e.target.selectionEnd;
    const { usedSeparatorsObj } = getSeparator(dateFormat, date);

    switch (e.key) {
      case "Enter": {
        handleEnter(e, date, dateFormat, callback);
        return;
      }
      case "Backspace": {
        handleBackspace(e, date, dateFormat);
        return;
      }
      case "Delete": {
        const separator = usedSeparatorsObj.find((s) => s.idx >= pos)?.char;
        if (date[pos] === separator) {
          e.preventDefault();
        }
      }
    }
  };

  const onChange = (e) => {
    const date = getDateValue(e, dateFormat);
    callback(date);
  };

  const onSelect = (event) => {
    const value = event.target.value;
    const input = event.target;
    const { selectionStart, selectionEnd } = input;
    const { separators } = getSeparator(dateFormat, value);
    if (selectionStart !== selectionEnd) {
      let newStart = selectionStart;
      let newEnd = selectionEnd;

      const isSeparator =
        separators.some((s) => s.char === value[selectionStart]) ||
        separators.some((s) => s.char === value[selectionEnd - 1]);

      if (isSeparator) {
        if (separators.some((s) => s.char === value[selectionStart])) {
          newStart++;
        }
        if (separators.some((s) => s.char === value[selectionEnd - 1])) {
          newEnd--;
        }
      }

      if (newStart !== selectionStart || newEnd !== selectionEnd) {
        input.setSelectionRange(newStart, newEnd);
      }
    }
  };

  const onClick = (e) => {
    const isMobile = mobileCheck();
    e.stopPropagation();

    if (
      e.target &&
      typeof e.target.setSelectionRange === "function" &&
      !isMobile
    ) {
      const selectionPos = e.target.selectionStart;
      const { start, end } = getSelectionRange(e.target.value, selectionPos);
      e.target.setSelectionRange(start, end);
    }
  };

  const onDateSelect = ({ selectable, date }) => {
    if (!selectable) return false;
    if (date instanceof Date && !isNaN(date.getTime())) {
      const value = getFormattedDate(date, dateFormat);
      callback(value);
      return true;
    }
    return false;
  };

  const onPaste = (e) => {
    const regex = /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/gi;
    const key = e.clipboardData.getData("text");
    if (!regex.test(key)) {
      if (isDateMatched(key, dateFormat)) return;
      e.preventDefault();
    }
  };

  return { onChange, onSelect, onKeyDown, onClick, onDateSelect, onPaste };
};
