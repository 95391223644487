import { format, isDate } from "date-fns";

const getValueFromObj = (obj) => {
  if (isDate(obj)) return format(obj, "dd-MM-yyyy");
  if (Array.isArray(obj)) return getValueFromObj(obj[0]);

  return Object.values(obj)[0];
};

const getValue = async (func) => {
  try {
    const res = await func();
    if (typeof res === "object") return getValueFromObj(res);
    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export const resolveDefaultValue = async (value) => {
  const type = typeof value;

  try {
    if (type === "function") {
      const res = await getValue(value);
      return res;
    }

    return value;
  } catch (e) {
    throw new Error(e);
  }
};
