import { GET, POST } from "../base-fetch";
import { authenticateToGoogle, googleAuthStatus } from "./utils/google-auth";
import { getAuthStatus, getStoredToken } from "./utils/store-google-token";

const waitForAuthData = async () => {
  const data = await new Promise((resolve) => {
    const interval = setInterval(() => {
      const storedData = getStoredToken();
      if (storedData) {
        clearInterval(interval);
        resolve(storedData);
      }
    }, 1000);
  });

  return data;
};

export const authenticate = async (userId) => {
  const authStatus = getAuthStatus();
  const { progress, success } = googleAuthStatus;

  if (authStatus === progress || authStatus === success)
    return await waitForAuthData();

  return await authenticateToGoogle(userId);
};

export const modifyMessages = async ({ refreshToken, ids, labels, type }) =>
  await POST(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "messages/modify-messages",
    { refreshToken },
    { ids, labels, type },
  );

export const sendMessage = async ({ refreshToken, message }) =>
  await POST(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "messages/send-message",
    { refreshToken },
    { message },
  );

export const getMessagesList = async ({
  count,
  pageToken,
  queryString,
  mailbox,
  refreshToken,
}) =>
  await POST(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "messages/get-messages-list",
    { refreshToken },
    { count, queryString, mailbox, pageToken },
  );

export const readMessage = async ({ id, refreshToken }) =>
  await GET(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "messages/get-message",
    { id, refreshToken },
  );

export const getLabels = async ({ refreshToken }) =>
  // eslint-disable-next-line no-undef
  await GET(process.env.EMAIL_SERVICE_URL + "labels/get-labels", {
    refreshToken,
  });

export const deleteMails = async ({ ids, refreshToken }) =>
  await POST(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "messages/delete-messages",
    {},
    { ids, refreshToken },
  );
