(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@chakra-ui/react"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "@chakra-ui/react"], factory);
	else if(typeof exports === 'object')
		exports["ui"] = factory(require("react"), require("@chakra-ui/react"));
	else
		root["ui"] = factory(root["React"], root["@chakra-ui/react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__363__) => {
return 