import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../utils/local-storage/local-storage";
import { localStorageKeys } from "../../../utils/local-storage/local-storage-keys";

export const setAuthStatus = (status) =>
  setLocalStorageItem(localStorageKeys.GOOGLE_AUTH_STATUS, status);

export const getAuthStatus = () =>
  getLocalStorageItem(localStorageKeys.GOOGLE_AUTH_STATUS);

export const storeToken = (authInfo) =>
  setLocalStorageItem(localStorageKeys.GOOGLE_AUTH_INFO, authInfo);

export const getStoredToken = () =>
  getLocalStorageItem(localStorageKeys.GOOGLE_AUTH_INFO);
