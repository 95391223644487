import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { executeBlock } from "../../../process-executor/process-executor";
import { showErrorToast } from "../echo-error-toast";

function getAbsPosition(element) {
  var rect = element.getBoundingClientRect();
  return { x: rect.left, y: rect.top };
}

const ContextMenu = ({
  id,
  guid,
  nodeId,
  dataSource,
  context,
  children,
  sourceParams,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const [source, setSource] = useState();

  useEffect(() => {
    if (
      isOpen &&
      !source &&
      sourceParams &&
      dataSource &&
      typeof dataSource === "function"
    ) {
      Promise.resolve(dataSource(sourceParams))
        .then((result) => {
          setSource(result);
        })
        .catch((err) => console.error(err));
    }
  }, [isOpen, dataSource, source, sourceParams]);

  const childRef = useRef(null);
  const listRef = useRef(null);

  const childElement = React.Children.only(children);

  const handleOnContextMenu = (e) => {
    e.preventDefault();
    const menu = listRef?.current;
    if (menu) {
      const popper = menu.parentElement;

      Object.assign(popper.style, {
        top: `0px`,
        left: `0px`,
      });

      const { x: childX, y: childY } = getAbsPosition(popper);
      const x = e.clientX - childX;
      const y = e.clientY - childY;

      Object.assign(popper.style, {
        top: `${y}px`,
        left: `${x}px`,
      });

      setIsOpen(true);
    }
  };

  const handleOnClick = async (processId, name, label) => {
    try {
      await executeBlock(
        context,
        processId,
        {},
        [sourceParams, { processId, label, name }],
        {
          callerId: id,
          callerGuid: guid,
          callerNodeId: nodeId,
        },
      );
    } catch (e) {
      showErrorToast(e);
    }
  };

  return (
    <>
      {React.cloneElement(childElement, {
        ...childElement.props,
        onContextMenu: handleOnContextMenu,
        ref: childElement.props.ref || childRef,
      })}
      <Menu
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <MenuList
          ref={listRef}
          onAnimationEnd={() => {
            const menu = listRef?.current?.parentElement;
            if (menu) {
              menu.focus();
            }
          }}
        >
          {Array.isArray(source) &&
            source
              .map((srcElement) => {
                if (
                  typeof srcElement === "object" &&
                  !Array.isArray(srcElement)
                ) {
                  const srcEntries = Object.values(srcElement);
                  if (srcEntries.length >= 2) {
                    const [processId, name, label] = srcEntries;
                    return (
                      <MenuItem
                        key={`menu-item-${processId}-${name}`}
                        onClick={(e) =>
                          handleOnClick(processId, name, label, e)
                        }
                      >
                        {label || name}
                      </MenuItem>
                    );
                  }
                }
                return null;
              })
              .filter((cmp) => cmp !== null)}
        </MenuList>
      </Menu>
    </>
  );
};

ContextMenu.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.any,
  dataSource: PropTypes.any,
  id: PropTypes.number,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  sourceParams: PropTypes.object,
};

ContextMenu.defaultProps = {};

export default ContextMenu;
