import PropTypes from "prop-types";
import DataGrid from "../../echo-components/base-components/components/datagrid-component/datagrid.component";

const columns = [
  { name: "propName", displayName: "Property name" },
  { name: "propType", displayName: "Property type" },
];

const ComponentPropertiesList = ({ value, onRowClick }) => {
  return (
    <DataGrid
      objectKey="COMPONENT_PROPERTIES_LIST"
      value={value || []}
      columns={columns}
      onRowClick={onRowClick}
      pageSize={15}
    />
  );
};

ComponentPropertiesList.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      propName: PropTypes.string.isRequired,
      propType: PropTypes.oneOf([
        "func",
        "string",
        "number",
        "object",
        "any",
        "array",
      ]).isRequired,
    }).isRequired
  ),
  onRowClick: PropTypes.func,
};

ComponentPropertiesList.defaultProps = {};

export default ComponentPropertiesList;
