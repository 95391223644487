import { useEffect, useState } from "react";
import { getComponent } from "../../../../../../../services/components-service";

export const useComponentDefinition = (componentId) => {
  const [definition, setDefinition] = useState(null);

  useEffect(() => {
    if (componentId) {
      getComponent({ id: componentId }).then((result) => {
        if (result) {
          setDefinition(result);
        }
      });
    }
  }, [componentId]);

  return definition;
};
