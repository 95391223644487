import { Box, Select, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const PageSizeSelect = (props) => {
  const { onSelect, savePageSize, options, selected } = props;

  const handleSelect = (e) => {
    const itemsPerPage = parseFloat(e.target.value);
    onSelect(itemsPerPage);
    savePageSize(itemsPerPage);
  };

  return (
    <Box display="flex" alignItems="center" gap="10px">
      <Text whiteSpace="nowrap">Ilość wierszy:</Text>
      <Select
        onChange={handleSelect}
        width={"fit-content"}
        borderColor={"grey"}
        value={parseFloat(selected)}
      >
        {options.map(({ value, label }, id) => (
          <option value={value} key={id}>
            {label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

PageSizeSelect.displayName = "PageSizeSelect";

PageSizeSelect.propTypes = {
  onSelect: PropTypes.func,
  savePageSize: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
