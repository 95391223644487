import React from "react";
import { Select } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const ComboEditCell = (props) => {
  const { onChange, value, options, autoFocus } = props;

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <Select autoFocus={autoFocus} onChange={handleChange} value={value}>
      {options?.map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      ))}
    </Select>
  );
};

ComboEditCell.propTypes = {
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  autoFocus: PropTypes.bool,
};
