export const getFilterObj = (current, colName, value) => {
  const keys = Object.keys(current);
  const f = keys.find((k) => k === colName);

  if (!f) return { ...current, [colName]: { ...value } };

  const inputValue = current[f].value ? current[f].value : undefined;
  return { ...current, [f]: { ...value, value: inputValue } };
};

const getColumnFilter = (filterObj) => {
  const keys = Object.keys(filterObj);
  const filteredKeys = keys.filter((k) => k.toLowerCase() !== "contains");
  const withoutValue = filteredKeys.filter((k) => k.toLowerCase() !== "value");

  const result = filteredKeys.reduce((acc, curr) => {
    return { ...acc, [curr]: filterObj[curr] };
  }, {});

  if (withoutValue.length > 0) return result;
  return { ...result, contains: true };
};

export const getFilterState = (filterObj, colName) => {
  const keys = Object.keys(filterObj);
  if (keys.length < 1) return { [colName]: { contains: true } };

  const f = keys.find((k) => k === colName);
  if (!f) return { ...filterObj, [colName]: { contains: true } };

  const columnFilter = getColumnFilter(filterObj[f]);
  return { ...filterObj, [f]: { ...columnFilter } };
};
