import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { withBaseComponent } from "../../../with-base-component";
import FlexDesignerComponent from "./flex.designer.component";
import FlexRendererComponent from "./flex.renderer.component";

const FlexComponent = ({ rootProps, ...props }) => {
  const { designerMode } = props;
  if (designerMode) {
    return <FlexDesignerComponent {...{ ...props, ...rootProps }} />;
  } else {
    return <FlexRendererComponent {...{ ...props, ...rootProps }} />;
  }
};

FlexComponent.propTypes = {
  id: PropTypes.number.isRequired,
  key: PropTypes.string,
  rootProps: PropTypes.object,
  childs: PropTypes.array.isRequired,
  designerMode: PropTypes.bool,
  nodeRef: PropTypes.object,
  depth: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.funcOf(PropTypes.object),
  onMouseOver: PropTypes.funcOf(PropTypes.object),
  onMouseLeave: PropTypes.funcOf(PropTypes.object),
  onComponentClick: PropTypes.funcOf(PropTypes.number),
  onMouseDown: PropTypes.funcOf(PropTypes.any),
  onMouseUp: PropTypes.funcOf(PropTypes.any),
  onTouchEnd: PropTypes.funcOf(PropTypes.any),
  onMoveElement: PropTypes.funcOf(PropTypes.object),
  onCancelHover: PropTypes.func,
  onDropAreaChange: PropTypes.funcOf(PropTypes.number),
  dragMode: PropTypes.bool,
  activeAreaId: PropTypes.number,
};

FlexComponent.defaultProps = {
  style: { width: "100px" },
  onClick: shimFunctions.shimFunction1,
  onMouseOver: shimFunctions.shimFunction1,
  onMouseLeave: shimFunctions.shimFunction1,
  onComponentClick: shimFunctions.shimFunction1,
  onMoveElement: shimFunctions.shimFunction1,
  onMouseUp: shimFunctions.shimFunction0,
  onMouseDown: shimFunctions.shimFunction0,
  onTouchEnd: shimFunctions.shimFunction0,
  onCancelHover: shimFunctions.shimFunction0,
  onDropAreaChange: shimFunctions.shimFunction1,
  dragMode: false,
  activeAreaId: null,
  designerMode: false,
  depth: null,
};

export default withBaseComponent(FlexComponent);
