export const parseDataValue = (dataValue) => {
  try {
    var o = JSON.parse(dataValue);
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {
    return false;
  }
  return false;
};
