import { areSameObjects } from "./are-same-objects";

export const areSameArrays = (arr1, arr2) => {
  if (!arr1 || !arr2) return false;
  if (!arr1[0] || !arr2[0]) return false;
  if (typeof arr1[0] !== typeof arr2[0]) return false;

  const boolArr = arr1.map((el, idx) => {
    if (typeof el === "object") {
      if (Array.isArray(el)) {
        return areSameArrays(el, arr2[idx]);
      }

      return areSameObjects(el, arr2[idx]);
    }

    return el === arr2[idx];
  });

  return boolArr.some((b) => b === false);
};
