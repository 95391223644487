import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { useContainerComponent } from "./hooks/use-container-component";
import { ListRenderView } from "./views/list-render-view/list-render-view";
import { ListDesignerView } from "./views/list-designer-view/list-designer-view";
import { getEventListenersPropType } from "./prop-type";
import { useEffect } from "react";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { showErrorToast } from "../../../../echo-error-toast";

const ListComponent = ({ rootProps, ...props }) => {
  const {
    id,
    guid,
    nodeId,
    designerMode,
    name,
    depth,
    style,
    childs,
    onClick,
    onMouseLeave,
    onMouseOver,
    nodeRef,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
  } = props;

  const [apiContext, componentDefinition, mode] = useApiContext(
    id,
    guid,
    nodeId,
  );

  const events = { onClick, onMouseLeave, onMouseOver };

  const { handleAreaOnMouseLeave, handleAreaOnMouseOver, handleOnAreaClick } =
    useContainerComponent(events, depth);

  const updateComponentDefinitionProperty = (newProperty, propertyName) => {
    if (mode === "designer") {
      const api = apiContext?.api;
      if (api) {
        api.updateComponentProperty(id, newProperty, propertyName);
      }
    }
  };

  const updateProps = (newProps) =>
    updateComponentDefinitionProperty(
      { ...componentDefinition?.componentProps, ...newProps },
      "componentProps",
    );

  useEffect(() => {
    if (childs[0]) {
      updateProps({ childs: childs[0].childrenComponents });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childs[0]?.childrenComponents]);

  useEffect(() => {
    if (childs.length > 1) {
      showErrorToast({
        reasonTitle: "Too many components applied to List.",
        location: `Location: ${name}`,
        shortMessage:
          "List should contain only one component that will be rendered.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childs.length]);

  const wrapperStyles = {
    height: "100%",
    boxSizing: "border-box",
    width: "100%",
    position: "relative",
    display: "flex",
    ...style,
    overflowX: designerMode ? "inherit" : style?.overflowX,
    overflowY: designerMode ? "inherit" : style?.overflowY,
  };

  return (
    <Box
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onTouchEnd={onTouchEnd}
      ref={nodeRef}
      style={{ ...wrapperStyles }}
      onMouseOver={handleAreaOnMouseOver}
      onMouseLeave={handleAreaOnMouseLeave}
      data-value={designerMode ? JSON.stringify({ depth, id }) : undefined}
      onClick={handleOnAreaClick}
    >
      {designerMode ? (
        <ListDesignerView {...props} />
      ) : (
        <ListRenderView {...{ ...props, ...rootProps }} />
      )}
    </Box>
  );
};

ListComponent.displayName = "ListComponent";

ListComponent.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  dataSource: PropTypes.func,
  rowEvent: PropTypes.func,
  rowEventListeners: getEventListenersPropType(
    PropTypes.shape({ name: PropTypes.string, event: PropTypes.string }),
  ),
  enableRowAdd: PropTypes.bool,
  designerMode: PropTypes.any,
  guid: PropTypes.any,
  nodeId: PropTypes.any,
  path: PropTypes.any,
  childs: PropTypes.any,
  onComponentClick: PropTypes.any,
  onMoveElement: PropTypes.any,
  activeAreaId: PropTypes.any,
  activePropertiesComponentId: PropTypes.any,
  depth: PropTypes.any,
  dragMode: PropTypes.any,
  onDropAreaChange: PropTypes.any,
  style: PropTypes.any,
  onClick: PropTypes.any,
  onMouseLeave: PropTypes.any,
  onMouseOver: PropTypes.any,
  nodeRef: PropTypes.any,
  onMouseDown: PropTypes.any,
  onMouseUp: PropTypes.any,
  onTouchEnd: PropTypes.any,
  rootProps: PropTypes.any,
};

export default withBaseComponent(ListComponent);
