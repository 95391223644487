import { executeBlock } from "../../../process-executor/process-executor";
import { showErrorToast } from "../../shared/echo-error-toast";

export const getCrudData = (
  { context, component, id, params = [] },
  callback
) => {
  const readAction = component?.crudRead ?? component?.crudActions;
  if (readAction && id) {
    executeBlock(
      context,
      11,
      {
        ...readAction.staticParams,
        queryParams: id ? [{ name: "id", value: id }, ...params] : params,
      },
      [],
      {
        callerId: component?.id,
        callerGuid: component?.guid,
        callerNodeId: component?.nodeId,
      }
    )
      .then((result) => {
        if (result) {
          callback(result);
        }
      })
      .catch((err) => {
        window.console.error(err);
        if (err.reasonTitle) {
          showErrorToast(err);
        } else {
          showErrorToast({
            reasonTitle: "Error while loading data from server",
            location: "getCrudData",
            shortMessage: err.toString().slice(0, 300),
            message: err.toString(),
          });
        }
      });
  } else if (!readAction && !id) {
    callback(null);
  }
};
