/**
 * Check if property exists case insensitive
 *
 * @param {object} obj - Object to check
 * @param {string} propertyName - Name of property
 * @return {boolean}
 */
export const caseInsensitivePropertyExists = (obj, propertyName) => {
  if (typeof obj === "object" && !Array.isArray(obj)) {
    return (
      Object.keys(obj).find((key) => key.toLowerCase() === propertyName) !==
      null
    );
  }
  return false;
};

/**
 * Get first existing property by name case insensitive.
 *
 * @param {object} obj - Source object
 * @param {propertyName} operation - Name of property.
 * @return {any}
 */
export const getPropertyCaseInsensitive = (obj, propertyName) => {
  if (typeof obj === "object" && !Array.isArray(obj)) {
    const key = Object.keys(obj).find(
      (key) => key.toLowerCase() === propertyName
    );
    if (key !== null) {
      return obj[key];
    }
  }
  return undefined;
};
