import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  Stack,
} from "@chakra-ui/react";
import { useState } from "react";
import "../../../styles/login-page.css";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const LoginAuthStep = ({
  loginButtonPressed,
  login,
  onLoginChange,
  password,
  onPasswordChange,
  errorString,
  onErrorStringChange,
  countFalsePass,
  show,
}) => {
  const [visibilityPass, setVisibilityPass] = useState(false);

  return (
    <ScaleFade in={show} unmountOnExit>
      <Stack width="248px">
        <FormControl
          variant="floating"
          isInvalid={(loginButtonPressed && !login) || errorString !== ""}
        >
          <Input
            width="100%"
            id="login-input"
            placeholder=" "
            value={login}
            onChange={(e) => onLoginChange(e.target.value)}
          />
          <FormLabel>Login</FormLabel>
          {loginButtonPressed && !login && (
            <FormHelperText fontSize="smaller">
              Login field cannot be empty
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          variant="floating"
          isInvalid={(loginButtonPressed && !password) || errorString !== ""}
        >
          <InputGroup>
            <Input
              id="password-input"
              width="100%"
              placeholder=" "
              type={visibilityPass ? "text" : "password"}
              // autoComplete='current-password'
              value={password}
              onChange={(e) => {
                onPasswordChange(e.target.value);
                onErrorStringChange("");
              }}
            />
            <FormLabel>Password</FormLabel>
            <InputRightElement>
              <Button
                m="0.5rem"
                mr="calc(0.5rem - 5px)"
                size="sm"
                onClick={() => setVisibilityPass(!visibilityPass)}
              >
                {visibilityPass ? <ViewIcon /> : <ViewOffIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button type="primary">Login</Button>
        <FormControl>
          {loginButtonPressed && !password && (
            <FormHelperText fontSize="smaller">
              Password field cannot be empty
            </FormHelperText>
          )}
          {loginButtonPressed && errorString && (
            <FormHelperText fontSize="smaller">{errorString}</FormHelperText>
          )}
          {countFalsePass > 2 ? (
            <span className="forgetPassword">Zapomniałem hasła</span>
          ) : null}
        </FormControl>
      </Stack>
    </ScaleFade>
  );
};

LoginAuthStep.propTypes = {
  loginButtonPressed: PropTypes.bool,
  login: PropTypes.string,
  onLoginChange: PropTypes.func,
  password: PropTypes.string,
  onPasswordChange: PropTypes.func,
  errorString: PropTypes.string,
  onErrorStringChange: PropTypes.func,
  countFalsePass: PropTypes.number,
  show: PropTypes.bool,
};

LoginAuthStep.defaultProps = {};

export default LoginAuthStep;
