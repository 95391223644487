import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const EmailViewerComponent = (props) => {
  const { value, rootProps } = props;
  const { nodeRef, ...restRootProps } = rootProps;

  const htmlState = useResolveProp(value, true);

  return (
    <Box ref={nodeRef} {...restRootProps} padding="10px" overflow="auto">
      {htmlState && <Box dangerouslySetInnerHTML={{ __html: htmlState }}></Box>}
    </Box>
  );
};

EmailViewerComponent.propTypes = {
  value: PropTypes.string,
  rootProps: PropTypes.object,
};

export default withBaseComponent(EmailViewerComponent);
