import { ACTIONS } from "../mapping-reducer";

const validateActionType = (
  displayLists,
  actionType,
  sourceListKey,
  sourceOrder,
  destinationOrder,
) => {
  if (
    actionType &&
    (actionType !== ACTIONS.CHANGE_ORDER ||
      (actionType === ACTIONS.CHANGE_ORDER &&
        sourceOrder !== destinationOrder)) &&
    displayLists
      .find((list) => list.key === sourceListKey)
      ?.allowedTo?.includes(actionType) !== true
  ) {
    return `${actionType.replace("_", " ")} action is not allowed here.`;
  }

  return null;
};

const validateBindTypes = (state, actionType, prop) => {
  if (
    actionType === ACTIONS.BIND &&
    state.mappings.length < state.sourceProps.length &&
    state?.sourceProps[state.mappings.length]?.type?.toLowerCase() !==
      prop?.type?.toLowerCase()
  ) {
    return "Incorrect types";
  }
  return null;
};

const validateBindSlotAvailability = (state, actionType) => {
  if (
    actionType === ACTIONS.BIND &&
    state.mappings.length >= state.sourceProps.length
  ) {
    return "Every property path from source is already mapped";
  }
  return null;
};

const validateOrderChange = (
  state,
  actionType,
  sourceListKey,
  sourceOrder,
  destinationOrder,
) => {
  if (
    actionType === ACTIONS.CHANGE_ORDER &&
    sourceListKey === "sourceProps" &&
    (sourceOrder < state.mappings.length ||
      destinationOrder < state.mappings.length)
  ) {
    return "Order change of mapped elements is not allowed";
  }
  return null;
};

export const validateAction = (
  state,
  displayLists,
  actionType,
  prop,
  sourceListKey,
  sourceOrder,
  destinationOrder,
) => {
  const validationErrors = [
    validateActionType(displayLists, actionType, sourceListKey),
    validateBindTypes(state, actionType, prop),
    validateBindSlotAvailability(state, actionType),
    validateOrderChange(
      state,
      actionType,
      sourceListKey,
      sourceOrder,
      destinationOrder,
    ),
  ];

  return validationErrors.filter((message) => !!message).join("\n");
};
