import { Box, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { format, isToday } from "date-fns";
import { pl } from "date-fns/locale";
import {
  getFontColor,
  GRID_TEMPLATE_COLUMNS,
  PRIMARY_COLOR,
} from "../utils/calendar-utils";

export const CalendarHeader = (props) => {
  const { weekDays, onContextMenu } = props;

  return (
    <Box
      display="flex"
      gap="5px"
      position="sticky"
      top="0"
      zIndex="150"
      width="100%"
      onContextMenu={onContextMenu}
    >
      <Box opacity="0" fontSize="18px" transform="translateY(-12px)">
        00:00
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="gray.300"
        background="white"
        flexGrow="1"
      >
        {weekDays.map((day, idx) => (
          <Box
            position="relative"
            _after={
              idx === weekDays.length - 1
                ? {}
                : {
                    content: `""`,
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                    height: "20px",
                    width: "1px",
                    background: "gray.300",
                  }
            }
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="5px"
            key={idx}
          >
            <Text
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              color={isToday(day) ? PRIMARY_COLOR : ""}
            >
              {format(day, "eee", { locale: pl }).toUpperCase()}
            </Text>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="35px"
              width="35px"
              padding="5px"
              borderRadius="100%"
              margin="0"
              // fontSize="18px"
              position="relative"
              color={isToday(day) ? getFontColor(PRIMARY_COLOR) : ""}
              background={isToday(day) ? PRIMARY_COLOR : ""}
              fontWeight={isToday(day) ? "semibold" : ""}
            >
              <Text display="block">{format(day, "d")}</Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

CalendarHeader.propTypes = {
  weekDays: PropTypes.array,
  onContextMenu: PropTypes.func,
};
