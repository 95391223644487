import { getRandomIntInclusive } from "@echo/tools";
import { toast } from "react-toastify";

export const createConnection = (
  element,
  { newConnectionState, setNewConnectionState, connections, setConnections }
) => {
  if (!newConnectionState?.sourceId) {
    setNewConnectionState({
      sourceId: element.id,
      destinationId: null,
    });
  } else if (newConnectionState.sourceId && !newConnectionState.destinationId) {
    if (
      connections.some(
        (processLink) =>
          processLink.sourceId === newConnectionState.sourceId &&
          processLink.destinationId === element.id
      )
    ) {
      toast.error(
        "There actually is connection between these process positions."
      );
    } else if (newConnectionState.sourceId === element.id) {
      setNewConnectionState({
        sourceId: null,
      });
    } else {
      Promise.all([
        setConnections([
          ...connections,
          {
            id: -getRandomIntInclusive(1, 999999999),
            sourceId: newConnectionState.sourceId,
            destinationId: element.id,
          },
        ]),
        setNewConnectionState({
          sourceId: null,
        }),
      ]);
    }
  }
};
