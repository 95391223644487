import { extendTheme } from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-14px)",
  fontSize: "var(--chakra-fontSizes-xs) !important",
};

export const theme = extendTheme({
  colors: {
    redCa: "#EE2E24",
    violetCa: "#3C1053",
    grayCa: "#6F7070",
    lightGrayCa: "#C5C7C7",
    blueCa: "#3182ce",
  },
  fontSizes: {
    sm: "12px",
    md: "14px",
  },
  sizes: {
    10: "25px",
  },
  space: {
    2: "2px",
  },
  components: {
    Button: {
      baseStyle: {
        border: "1px solid var(--chakra-colors-lightGrayCa)",
        background: "transparent",
        bg: "transparent",
        _hover: {
          border: "1px solid var(--chakra-colors-grayCa)",
          background: "var(--chakra-colors-lightGrayCa)",
          bg: "var(--chakra-colors-lightGrayCa)",
        },
        _focus: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: {
          border: "1px solid var(--chakra-colors-lightGrayCa)",
          background: "transparent",
          bg: "transparent",
          _hover: {
            background: "var(--chakra-colors-grayCa)",
            bg: "var(--chakra-colors-grayCa)",
            border: "none",
          },
          _focus: {
            boxShadow: "none",
          },
        },
        ghost: {
          border: "none",
          _hover: {
            border: "none",
            backgroundColor: "transparent",
            bg: "transparent",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderLeft: "1px solid var(--chakra-colors-lightGrayCa)",
          borderTop: "1px solid var(--chakra-colors-lightGrayCa)",
          borderRight: "1px solid var(--chakra-colors-lightGrayCa)",
          borderBottom: "1px solid black",
          backgroundColor: "black",
          width: "auto",
          _focus: {
            //borderBottom: '1px solid var(--chakra-colors-redCa)',
            border: "1px solid var(--chakra-colors-blueCa)",
            borderColor: "var(--chakra-colors-blueCa)",
            boxShadow: "0 0 0 1px var(--chakra-colors-blueCa)",
          },
        },
      },
      variants: {
        outline: {
          field: {
            border: "1px solid var(--chakra-colors-lightGrayCa)",
            width: "auto",
            _hover: {
              border: "1px solid var(--chakra-colors-blueCa)",
              borderColor: "var(--chakra-colors-blueCa)",
              boxShadow: "0 0 0 1px var(--chakra-colors-blueCa)",
            },
            _focus: {
              border: "1px solid var(--chakra-colors-blueCa)",
              borderColor: "var(--chakra-colors-blueCa)",
              boxShadow: "0 0 0 1px var(--chakra-colors-blueCa)",
            },
          },
        },
        unstyled: {
          field: {
            border: "none",
            width: "auto",
            textAlign: "center",
            _focus: {
              border: "none",
              borderColor: "transparent",
              boxShadow: "none",
            },
          },
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, .text-area-value + label, .combo-select-value + label, .signature + label":
              {
                ...activeLabelStyles,
              },
            input: {
              borderRadius: "var(--chakra-radii-none)",
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
              color: "var(--chakra-colors-lightGrayCa)",
              fontWeight: "400",
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      svg: {
        margin: "0px",
      },
      select: {
        borderRadius: "none !important",
      },
      ".chakra-numberinput div *": {
        fontSize: "calc(1rem * 0.70) ",
      },
      ".chakra-select__icon-wrapper": {
        height: "auto !important",
      },
      "table *": {
        borderColor: "var(--chakra-colors-gray-200) !important",
      },
      "table th": {
        fontSize: "var(--chakra-fontSizes-md)",
      },
      "table td": {
        fontSize: "var(--chakra-fontSizes-sm)",
      },
      "table tbody input": {
        fontSize: "var(--chakra-fontSizes-sm) !important",
        height: "22px !important",
        width: "100% !important",
        padding: "none !important",
        borderColor: "var(--chakra-colors-gray-200)",
      },
      "table thead td": {
        borderColor: "var(--chakra-colors-gray-200)",
      },
      "table td button": {
        height: "var(--chakra-size-5) !important",
      },
      ".chakra-form-control .chakra-form__label": {
        transform: "scale(0.85) translateY(-13px)",
        fontSize: "var(--chakra-fontSizes-xs)!important",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxWidth: "100%",
      },
      ".chakra-stack .chakra-form-control .chakra-form__label :not(.login-formt *)":
        {
          transform: "none",
          fontSize: "var(--chakra-fontSizes-md)!important",
        },
      ".chakra-form__label": {
        color: "slategray !important",
      },
      // ".chakra-form-control:not(.data-grid .chakra-form-control)": {
      //   margin: "10px 5px",
      // },
      // ".chakra-form-control > *": {
      //   fontSize: "var(--chakra-fontSizes-md)",
      // },
      ".login-form input, .login-form select, .login-form button": {
        fontSize: "16px !important",
        height: "40px !important",
        marginTop: "5px",
      },
      ".login-form label": {
        fontSize: "16px !important",
        top: "5px !important",
        padding: "5px",
        transform: "scale(0.85) translateY(-19px) !important",
      },
      ".login-form .chakra-form-control": {
        margin: "5px 0 5px 0",
      },
      ".login-form .chakra-input__right-element": {
        width: "auto",
        height: "auto",
      },
      ".login-form .chakra-input__right-element button": {
        height: "35px !important",
        width: "35px !important",
        marginTop: "7px",
      },
      ".login-form": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        height: "100%",
      },
      ".pointer-hand-hover.menu-element-hover": {
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        height: "30px",
        flexDirection: "column",
        color: "#1f261f",
      },
      ".menu-element-hover:hover ": {
        backgroundColor: "var(--chakra-colors-lightGrayCa)",
        color: "Black",
      },
      // '.chakra-modal__content .chakra-modal__footer ': {
      //   display: 'none',
      // },
      ".tabs-box": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid var(--chakra-colors-grayCa)",
        minHeight: "30px",
        height: "30px",
        boxSizing: "border-box",
        fontSize: "var(--chakra-fontSizes-md)",
      },
      ".tabs-elem": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        minHeight: "30px",
        overflowX: "auto",
      },
      ".tabs-elem Button": {
        height: "auto",
      },
      ".tabs-box .pointer-hand-hover button:focus": {
        border: "none",
        boxShadow: "none",
      },
      ".tabs-box .pointer-hand-hover.active-tab": {
        borderBottom: "2px solid var(--chakra-colors-redCa)",
        fontWeight: "500",
        alignItems: "baseline",
      },
      ".tabs-box .pointer-hand-hover:hover": {
        backgroundColor: "var(--chakra-colors-lightGrayCa)",
      },
      ".tabs-box-form": {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid var(--chakra-colors-grayCa)",
      },
      ".tabs-elem-form": {
        display: "flex",
        height: "40px",
        alignItems: "center",
      },
      ".tabs-elem-form:hover": {
        backgroundColor: "var(--chakra-colors-lightGrayCa)",
      },
      ".data-grid .datagrid-search-panel": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "5px 0",
        borderBottom: "1px black solid",
        borderLeft: "1px black solid",
        borderRight: "1px black solid",
      },
      ".data-grid .datagrid-search-panel input": {
        padding: "5px 20px !important",
        width: "100%",
      },
      ".data-grid .header-cell button": {
        background: "none",
      },
      ".data-grid .header-cell button:hover": {
        background: "none",
      },
      ".data-grid .header-cell button:focus": {
        boxShadow: "none",
      },
      ".filter-cell input": {
        padding: "5px",
        width: "100%",
      },
      ".ql-container": {
        overflow: "auto",
      },
    },
  },
});
