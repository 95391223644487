const extractValue = (unit, valueString) => {
  const extractedString = valueString.replace(unit, "");
  return isNaN(extractedString) ? null : parseFloat(extractedString);
};

const getBaseColumnWidth = (
  visibleColumns,
  tableDimensions,
  staticColumnsWidthSum,
) => {
  const undefinedWidthColumns = visibleColumns.filter(
    (c) =>
      !c.width ||
      !(
        c.width.toLowerCase().includes("px") ||
        c.width.toLowerCase().includes("rem")
      ),
  );
  if (
    staticColumnsWidthSum >= tableDimensions?.width ||
    staticColumnsWidthSum + undefinedWidthColumns.length * 150 >=
      tableDimensions?.width
  ) {
    return 150;
  } else {
    return (
      (tableDimensions?.width - staticColumnsWidthSum - 2) /
      undefinedWidthColumns.length
    );
  }
};

const getStaticColumnWidthSum = (
  visibleColumns,
  selectionMode,
  selectColumnWidth,
) =>
  visibleColumns.reduce(
    (partialSum, col) => {
      let value = 0;
      if (!col || !col.width) {
        return partialSum;
      } else if (col.width.includes("px")) {
        value = extractValue("px", col.width);
      } else if (col.width.includes("rem")) {
        const rem = extractValue("rem", col.width) || 0;
        value =
          rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
      }
      return (partialSum += value);
    },
    selectionMode === "single" ||
      selectionMode === "multiple" ||
      selectionMode === "empty"
      ? extractValue("px", selectColumnWidth)
      : 0,
  );

const getPixelWidth = (
  width,
  baseColumnWidth,
  staticColumnsWidthSum,
  tableDimensions,
) => {
  if (!width) {
    return baseColumnWidth;
  } else if (!isNaN(width)) {
    return parseFloat(width);
  } else if (width.includes("px")) {
    return extractValue("px", width);
  } else if (width.includes("%")) {
    const percentage = (extractValue("%", width) || 0) / 100;
    return percentage * (staticColumnsWidthSum || tableDimensions?.width);
  } else if (width.includes("rem")) {
    const rem = extractValue("rem", width) || 0;
    return (
      rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }
  return 150;
};

export const addColumnPxSizes = (
  visibleColumns,
  selectionMode,
  selectColumnWidth,
  tableDimensions,
) => {
  const staticColumnsWidthSum = getStaticColumnWidthSum(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
  );

  const baseColumnWidth = getBaseColumnWidth(
    visibleColumns,
    tableDimensions,
    staticColumnsWidthSum,
  );

  return visibleColumns.map((columnDef) => ({
    ...columnDef,
    pxWidth: Math.round(
      getPixelWidth(
        columnDef.width,
        baseColumnWidth,
        staticColumnsWidthSum,
        tableDimensions,
      ),
    ),
  }));
};
