const checkType = (value) => {
  if (
    value &&
    typeof value === "string" &&
    value
      .substring(0, 1024)
      .match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/)
  ) {
    return "base64";
  } else if (
    value &&
    typeof value === "string" &&
    value
      .substring(0, 1024)
      .match(
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.?[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      )
  ) {
    return "url";
  }
  return "unknown";
};

export default checkType;
