import { getBelowContainerId } from "../../../utils/get-below-container-id";
import ToolboxItem from "./toolbox-item/toolbox-item";
import PropTypes from "prop-types";
import {
  Box,
  TabPanel,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Input,
} from "@chakra-ui/react";
import {
  getDeleteButtonProps,
  getSaveButtonProps,
} from "./utils/get-default-components";
import { ToolboxItemsList } from "./toolbox-items-list/toolbox-items-list";
import { useToolboxComponents } from "./hooks/use-toolbox-components";

const Toolbox = ({
  items,
  dragMode,
  onDropItem,
  onStartDrag,
  onStopDrag,
  onAreaChange,
  formComponents,
}) => {
  const {
    componentsList,
    containersList,
    templatesList,
    dataComponentsList,
    filterList,
    filterValue,
  } = useToolboxComponents(items, formComponents);

  const handleOnDrag = (e) => {
    const id = getBelowContainerId(e);
    onAreaChange(id);
  };

  const lists = [
    componentsList,
    containersList,
    templatesList,
    dataComponentsList,
  ];

  return (
    <Box display="block" position="relative" height="100%">
      <Box display="flex" height="100%" width="100%" flexFlow="column">
        <Box className="tabs-box" height="100%" flex={1}>
          <Tabs
            className="tabs-elem"
            isLazy
            display="flex"
            flexDirection={"column"}
          >
            <TabList>
              <Tab key={`tab-Main-Components`} onClick={() => filterList("")}>
                Main
              </Tab>
              <Tab key={`tab-Create-Components`} onClick={() => filterList("")}>
                Containers
              </Tab>
              <Tab
                key={`tab-Template-Components`}
                onClick={() => filterList("")}
              >
                Templates
              </Tab>
              <Tab key={`tab-Source-Components`} onClick={() => filterList("")}>
                Data
              </Tab>
            </TabList>
            <Box padding="5px 5px 0 5px">
              <Input
                value={filterValue}
                onChange={(e) => filterList(e.target.value)}
                borderColor="#222"
              />
            </Box>
            <TabPanels
              display="block"
              overflowY="auto"
              overflowX="auto"
              width="100%"
              height="100%"
            >
              {lists.map((list, idx) => (
                <TabPanel key={idx}>
                  <b>{list.title}</b>
                  <ToolboxItemsList
                    items={list.items}
                    dragMode={dragMode}
                    onItemDragStart={onStartDrag}
                    onItemDragStop={onStopDrag}
                    onItemDrag={handleOnDrag}
                    onItemDrop={onDropItem}
                    type={list.type}
                  />
                  {list.type === "formComponents" && (
                    <ToolboxItem
                      dragMode={dragMode}
                      onStart={onStartDrag}
                      onStop={onStopDrag}
                      onDrag={handleOnDrag}
                      item={items.find((i) => i.displayName === "Button")}
                      defaultProps={getSaveButtonProps()}
                      displayName="Save"
                      onDropItem={onDropItem}
                    />
                  )}
                  {list.type === "formComponents" && (
                    <ToolboxItem
                      dragMode={dragMode}
                      onStart={onStartDrag}
                      onStop={onStopDrag}
                      onDrag={handleOnDrag}
                      item={items.find((i) => i.displayName === "Button")}
                      defaultProps={getDeleteButtonProps()}
                      displayName="Delete"
                      onDropItem={onDropItem}
                    />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

Toolbox.propTypes = {
  items: PropTypes.array,
  dragMode: PropTypes.bool,
  onDropItem: PropTypes.func,
  onStartDrag: PropTypes.func,
  onStopDrag: PropTypes.func,
  onAreaChange: PropTypes.func,
  formComponents: PropTypes.array,
};

export default Toolbox;
