import { showErrorToast } from "../../../../../echo-error-toast";

export const getDatagridFilterOptions = () => ({
  contains: false,
  notContains: false,
  equal: false,
  notEqual: false,
  greaterThan: false,
  greaterOrEqual: false,
  lowerThan: false,
  lowerOrEqual: false,
  empty: false,
  notEmpty: false,
});

export const getInitialDataGridState = (options) => {
  return {
    loading: true,
    page: 0,
    count: 0,
    itemsPerPage: options?.itemsPerPage ?? 20,
    data: [],
    globalFilter: "",
    columnDefinitions: null,
    sort: {},
    options: options ?? {
      columnFilter: false,
      globalFilter: false,
      sort: false,
    },
    filterOptions: getDatagridFilterOptions(),
    columnFilters: {},
    refreshKey: 0,
  };
};

export const dataGridReducer = (prevState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...prevState,
        loading: false,
        data: withIdExtraction(action.data),
        source: action.source,
        count: action.count ?? 0,
        dataSourceId: action.dataSourceId,
        settingId: null,
        dataSourceKey: action.dataSourceKey,
        columnDefinitions:
          prevState.columnDefinitions &&
          Array.isArray(prevState.columnDefinitions) &&
          prevState.columnDefinitions.length > 0 &&
          action.dataSourceId === prevState.dataSourceId &&
          action.dataSourceKey === prevState.dataSourceKey
            ? prevState.columnDefinitions
            : action.columnDefinitions || prevState.columnDefinitions,
      };
    case "DATA_ONLY":
      return {
        ...prevState,
        source: action.source,
        data: withIdExtraction(action.data),
      };
    case "REFRESH_DATA":
      return {
        ...prevState,
        refreshKey: prevState.refreshKey + 1,
        loading: true,
      };
    case "UPDATE_ROW":
      return {
        ...prevState,
        data: getDataWithUpdatedRow(action.newRow, prevState.data),
      };
    case "CHANGE_PAGE":
      return {
        ...prevState,
        page: action.page,
      };
    case "UPDATE_COLUMN_FILTER":
      return {
        ...prevState,
        columnFilters: {
          ...prevState.columnFilters,
          page: 0,
          [action.column]: action.filter,
        },
      };
    case "UPDATE_GLOBAL_FILTER":
      return {
        ...prevState,
        page: 0,
        globalFilter: action.filter,
      };
    case "UPDATE_COLUMN_SORT":
      return {
        ...prevState,
        page: 0,
        sort: action.ctrlKey
          ? invertSort(prevState.sort, action.column, action.ctrlKey)
          : invertSort(prevState.sort, action.column),
      };
    case "SET_COLUMN_DEFINITIONS":
      return {
        ...prevState,
        columnDefinitions: action.columnDefinitions,
        settingId: action.settingId,
      };
    case "UPDATE_FILTER_OPTIONS":
      return { ...prevState, page: 0, filterOptions: action.filterOptions };
    case "UPDATE_COLUMN_FILTERS":
      return { ...prevState, page: 0, columnFilters: action.columnFilters };
    case "STORE_SETTING_ERROR":
      return {
        ...prevState,
        settingId: -1,
      };
    case "SET_PAGE_SIZE":
      return { ...prevState, page: 0, itemsPerPage: action.payload };
    case "SET_SORT_OBJECT":
      return {
        ...prevState,
        sort: action.sortObject,
      };
    default:
      return prevState;
  }
};

const withIdExtraction = (data) => {
  for (let index in data) {
    const elem = data[index];
    const id = getRowId(elem);
    if (typeof id === "undefined") {
      showErrorToast({
        reasonTitle: "Error occurred in datagrid",
        location: "Datagrid",
        shortMessage: "Error. Id must be specified in each row.",
        message: "Error. Id must be specified in each row.",
      });
      return [];
    }
    data[index]._id = id;
  }
  return data;
};

const invertSort = (sortObject, columnName, ctrlKey) => {
  if (sortObject[columnName] === "asc") {
    if (ctrlKey) return { ...sortObject, [columnName]: "desc" };
    else return { [columnName]: "desc" };
  } else if (sortObject[columnName] === "desc") {
    delete sortObject[columnName];
    return sortObject;
  } else {
    if (ctrlKey) return { ...sortObject, [columnName]: "asc" };
    else return { [columnName]: "asc" };
  }
};

const getDataWithUpdatedRow = (newRow, data) => {
  const newData = [...data];
  const rowIndex = newData.findIndex((r) => getRowId(r) === getRowId(newRow));
  if (rowIndex > -1) {
    newData[rowIndex] = { ...newData[rowIndex], ...newRow };
  }

  return newData;
};

const getRowId = (row) =>
  row._id ?? row.id ?? row.Id ?? row.ID ?? row.iD ?? undefined;
