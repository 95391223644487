import PropTypes from "prop-types";
import EchoDefinedComponent from "./echo-defined-component";
import EchoStandardComponent from "./base-components/echo-standard-component";
import { removeOpt } from "../../../utils/css/remove-opt";
import { calculateProps } from "../../../echo-component/function-wrapper/calculate-prop";

const EchoComponent = ({
  styleOptions,
  componentProps,
  systemProps,
  context,
  ...rest
}) => {
  if (rest.source && rest.source.startsWith("!?echo-defined")) {
    return (
      <EchoDefinedComponent
        {...rest}
        styleOptions={removeOpt(styleOptions)}
        componentProps={calculateProps(
          componentProps,
          context,
          systemProps.id,
          systemProps.guid,
          systemProps.nodeId,
          systemProps.path,
          systemProps?.localContext,
        )}
        context={context}
        systemProps={calculateProps(
          systemProps
            ? {
                ...systemProps,
                styleOptions: removeOpt(systemProps.styleOptions),
              }
            : undefined,
          context,
          systemProps.id,
          systemProps.guid,
          systemProps.nodeId,
          systemProps.path,
          systemProps?.localContext,
        )}
      />
    );
  } else {
    return (
      <EchoStandardComponent
        {...rest}
        styleOptions={removeOpt(styleOptions)}
        componentProps={calculateProps(
          componentProps,
          context,
          systemProps.id,
          systemProps.guid,
          systemProps.nodeId,
          systemProps.path,
          systemProps?.localContext,
        )}
        context={context}
        systemProps={calculateProps(
          systemProps
            ? {
                ...systemProps,
                styleOptions: removeOpt(systemProps.styleOptions),
              }
            : undefined,
          context,
          systemProps.id,
          systemProps.guid,
          systemProps.nodeId,
          systemProps.path,
          systemProps?.localContext,
        )}
      />
    );
  }
};

EchoComponent.propTypes = {
  source: PropTypes.string.isRequired,
  componentProps: PropTypes.object,
  onStateChange: PropTypes.func,
  styleOptions: PropTypes.object,
  context: PropTypes.any,
  isLoading: PropTypes.bool,
  systemProps: PropTypes.shape({
    id: PropTypes.number.isRequired,
    guid: PropTypes.string.isRequired,
    nodeId: PropTypes.string.isRequired,
    childs: PropTypes.array,
    styleOptions: PropTypes.object,
    onAreaClick: PropTypes.func,
    state: PropTypes.any,
    onComponentClick: PropTypes.funcOf(PropTypes.number),
    onDropAreaChange: PropTypes.funcOf(PropTypes.number),
    dragMode: PropTypes.bool,
    depth: PropTypes.number,
    path: PropTypes.string.isRequired,
    localContext: PropTypes.any,
  }),
};

EchoComponent.isWrapper = true;

EchoComponent.defaultProps = {
  componentProps: {},
  styleOptions: {},
  systemProps: {},
};

export default EchoComponent;
