import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { FiLink2 } from "react-icons/fi";
import { tileIconSet } from "../tile/tile-icons";
import Tile from "../tile/tile";
import { useScaleSize } from "./hooks";
import { ContextMenu } from "@echo/ui";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";

const TileGraphComponent = ({
  tile,
  saveDiagram,
  highlightConnector,
  onConnectorClick,
  editModalComponent,
  onDelete,
  scale,
  label,
  isNew,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    openContextMenu,
    closeContextMenu,
    clickPosition,
    isContextMenuOpened,
  } = useContextMenu();

  const paddingTop = useScaleSize(4, scale);
  const labelFontSize = useScaleSize(10, scale);

  const handleOpen = useCallback(() => {
    if (isNew && typeof saveDiagram === "function") {
      saveDiagram().then(() => onOpen());
    } else {
      onOpen();
    }
  }, [isNew, onOpen, saveDiagram]);

  const contextMenuActions = [
    { label: "Delete", action: onDelete, color: "red" },
  ];

  if (typeof editModalComponent === "function") {
    contextMenuActions.unshift({ label: "Edit", action: handleOpen });
  }

  return (
    tile && (
      <>
        <ContextMenu
          isOpen={isContextMenuOpened}
          onClose={closeContextMenu}
          scale={1 / scale}
          clickPosition={clickPosition}
          actions={contextMenuActions}
        />
        <Box
          className="disable-ppz-pan"
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={`${10 + paddingTop}px`}
          onContextMenu={openContextMenu}
        >
          <Box
            position="absolute"
            className="disable-ppz-pan"
            lineHeight="10px"
            whiteSpace="nowrap"
            textAlign="center"
            marginTop="-12px"
            fontSize={`${labelFontSize}px`}
          >
            {label || `{${tile.label}}`}
          </Box>
          <Tile color={tile.color} type={tile.type} icon={tile.icon} />
          <IconButton
            className="disable-ppz-pan disable-ppz-drag"
            variant="ghost"
            color={highlightConnector ? "blue.500" : undefined}
            size="16px"
            onClick={onConnectorClick}
          >
            <FiLink2 className="disable-ppz-pan disable-ppz-drag" size="16px" />
          </IconButton>
          {isOpen &&
            typeof editModalComponent === "function" &&
            editModalComponent({ isOpen, handleOpen, onClose })}
        </Box>
      </>
    )
  );
};

TileGraphComponent.propTypes = {
  tile: PropTypes.shape({
    type: PropTypes.oneOf([1, 2, 3, "circle", "square", "diamond"]),
    label: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.oneOf(tileIconSet),
  }).isRequired,
  onConnectorClick: PropTypes.func,
  highlightConnector: PropTypes.bool,
  editModalComponent: PropTypes.func,
  saveDiagram: PropTypes.func,
  onDelete: PropTypes.func,
  scale: PropTypes.number,
  label: PropTypes.string,
  isNew: PropTypes.bool,
};

TileGraphComponent.defaultProps = {};

export default TileGraphComponent;
