import {
  addMinutes,
  differenceInMinutes,
  format,
  getDay,
  isSameDay,
} from "date-fns";
import {
  getSetting,
  saveSetting,
} from "../../../../../../../services/user-services";
import { executeBlock } from "../../../../../../../process-executor/process-executor";

export const DAY_HOURS = Array.from({ length: 24 }).map((_, id) => {
  if (id < 10) return `0${id}:00`;
  return `${id}:00`;
});

export const GRID_COLUMNS = 7;
export const GRID_ROWS = DAY_HOURS.length * 4;

export const GRID_TEMPLATE_COLUMNS = `repeat(${GRID_COLUMNS}, minmax(65px, 1fr))`;
export const GRID_TEMPLATE_ROWS = `repeat(${GRID_ROWS}, minmax(10px, 1fr))`;

export const PRIMARY_COLOR = "#0079ff";
export const PRIMARY_FONT_COLOR_DARK = "#2E3135";
export const PRIMARY_FONT_COLOR_LIGHT = "#FFF";

export const getFontColor = (hex) => {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return luminance > 0.5 ? PRIMARY_FONT_COLOR_DARK : PRIMARY_FONT_COLOR_LIGHT;
};

export const getEvents = async (dataSource, context, component) => {
  const componentInfo = {
    callerId: component?.id,
    callerGuid: component?.guid,
    callerNodeId: component?.nodeId,
  };
  try {
    const res = await executeBlock(
      context,
      dataSource.id,
      {},
      [],
      componentInfo,
    );

    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export const getAvailableCalendars = (events, userId) => {
  const uniqueIds = [...new Set(events.map((el) => el.calendarId))];

  const availableCalendars = Promise.all(
    uniqueIds.map(async (id) => ({
      id,
      name: events.find((el) => el.calendarId === id).calendarName,
      isVisible: await getIsVisible(id, userId),
      background: await getBackground(id, userId),
    })),
  );

  return availableCalendars;
};

const getIsVisible = async (id, userId) => {
  const key = `calendar_${id}_isVisible`;
  let value;
  let settingId;
  try {
    const result = await getSetting(userId, null, key);
    value = await JSON.parse(result.value);
    settingId = await result.id;
  } catch {
    value = false;
    settingId = null;
  }

  return { value, settingId };
};

const getBackground = async (id, userId) => {
  const key = `calendar_${id}_background`;
  let value;
  let settingId;
  try {
    const result = await getSetting(userId, null, key);
    value = await JSON.parse(result.value);
    settingId = await result.id;
  } catch {
    value = PRIMARY_COLOR;
    settingId = null;
  }
  return { value, settingId };
};

export const saveCalendarSetting = async (setting, calendarId, userId) => {
  const key = `calendar_${calendarId}_${setting.type}`;
  await saveSetting({
    id: setting.id,
    userId: userId,
    key,
    value: JSON.stringify(setting.value),
  });
};

//-- event utils --//
export const mapEvents = (events) =>
  events.map((ev) => ({
    id: ev.Id,
    title: ev.Title,
    from: new Date(ev.StartDate),
    to: new Date(ev.EndDate),
    description: ev.Description,
    calendarId: ev.CalendarId,
    calendarName: ev.CalendarName,
  }));

const getDateString = (date) => format(date, "yyyy-MM-dd HH:mm:ss.SSS");

export const mapEventToSql = (event) => ({
  Id: event.id,
  Title: event.title,
  StartDate: getDateString(event.from),
  EndDate: getDateString(event.to),
  Description: event.description,
});

export const getMovedEvent = (event, area) => {
  if (!event || !area) return event;
  const eventStart = area;
  const eventEnd = addMinutes(
    eventStart,
    differenceInMinutes(event.to, event.from),
  );
  return { ...event, from: eventStart, to: eventEnd };
};

export const getEventDay = (event) => {
  const day = getDay(event.from);
  if (day === 0) return 7;
  return day;
};

export const getEventStart = (event) => {
  const start = event.from;
  const hour = start.getHours();
  const minutes = start.getMinutes();
  const result = hour * 4 + minutes / 15;
  return result + 1;
};

export const getEventEnd = (event) => {
  const end = event.to;
  const hour = end.getHours();
  const minutes = end.getMinutes();
  const result = (hour === 0 ? 24 : hour) * 4 + minutes / 15;
  return Math.ceil(result + 1);
};

export const getHourByRowId = (rowId) => Math.floor(rowId / 4);

const mapEventsToColumnRow = (events) =>
  events.map((ev) => ({
    id: ev.id,
    date: ev.from,
    column: getEventDay(ev),
    start: getEventStart(ev),
    end: getEventEnd(ev),
  }));

const getEventsForSameDay = (events, currEvent) =>
  events.filter((ev) => isSameDay(ev.date, currEvent.date));

const getEventsWithSameStart = (events, currEvent) =>
  events.filter((ev) => ev.start === currEvent.start);

const getEventsInside = (events, currEvent) =>
  events.filter((ev) => currEvent.start > ev.start && currEvent.start < ev.end);

export const getEventZIndex = (currEvent) => currEvent.start;

export const getEventMargin = (eventsList, currEvent) => {
  const events = mapEventsToColumnRow(eventsList);
  const sameDayEvents = getEventsForSameDay(events, currEvent);
  const startSameEvents = getEventsWithSameStart(sameDayEvents, currEvent);

  if (startSameEvents.length > 0) {
    const arrId = startSameEvents.findIndex((ev) => ev.id === currEvent.id);
    const margin = arrId * 15;
    return margin - arrId * 2;
  }

  return 0;
};

export const getEventWidth = (eventsList, currEvent, marginLeft) => {
  const events = mapEventsToColumnRow(eventsList);
  const sameDayEvents = getEventsForSameDay(events, currEvent);
  const startSameEvents = getEventsWithSameStart(sameDayEvents, currEvent);

  if (startSameEvents.length > 1) {
    const width = 100 / startSameEvents.length;

    if (marginLeft + width + 30 - startSameEvents.length > 100) {
      return 100 - marginLeft;
    }

    return width + 30 - startSameEvents.length;
  }

  const insideEvents = getEventsInside(sameDayEvents, currEvent);
  if (insideEvents.length > 0) {
    return 100 - insideEvents.length * 10;
  }

  return 100;
};
//-- event utils --//
