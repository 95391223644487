import { Button } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const DayOfMonth = ({
  dateObj,
  propsConfigs,
  isInRange,
  renderProps,
  onMouseEnter,
}) => {
  const { date, selected, selectable, today } = dateObj;
  const { getDateProps } = renderProps;
  const { selectedBg, ...customBtnProps } =
    propsConfigs?.dayOfMonthBtnProps || {};
  let bg = selected || isInRange ? selectedBg || "purple.200" : "transparent";
  bg = !selectable ? customBtnProps?.disabledBg || "red.200" : bg;
  const halfGap = 0.125; //default Chakra-gap-space-1 is 0.25rem
  return (
    <Button
      {...getDateProps({
        dateObj,
        disabled: !selectable,
        onMouseEnter: onMouseEnter,
      })}
      disabled={!selectable}
      size="sm"
      aspectRatio="1.25/1"
      variant={"ghost"}
      bg={bg}
      _hover={{
        bg: "purple.400",
      }}
      // this intends to fill the visual gap from Grid to improve the UX
      // so the button active area is actually larger than when it's seen
      _after={{
        content: "''",
        position: "absolute",
        top: `-${halfGap}rem`,
        left: `-${halfGap}rem`,
        bottom: `-${halfGap}rem`,
        right: `-${halfGap}rem`,
        borderWidth: `${halfGap}rem`,
        borderColor: "transparent",
      }}
      {...customBtnProps}
      border="1px solid"
      borderColor={
        today
          ? customBtnProps?.borderColor || "blue.400 !important"
          : "transparent !important"
      }
      data-testid="datepicker-calendar-button-test"
    >
      {selectable ? date.getDate() : "X"}
    </Button>
  );
};

DayOfMonth.propTypes = {
  renderProps: PropTypes.any.isRequired, //RenderProps;
  isInRange: PropTypes.bool,
  dateObj: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  propsConfigs: PropTypes.shape({
    dateNavBtnProps: PropTypes.any, //ButtonProps,
    dayOfMonthBtnProps: PropTypes.shape({
      electedBg: PropTypes.any, //BackgroundProps['bg'],
      disabledBg: PropTypes.any, //BackgroundProps['bg'],
    }),
    inputProps: PropTypes.any, //InputProps,
  }),
};
