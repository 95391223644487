import { jwtDecode } from "jwt-decode";

const getToken = () => {
  const userDataJson = window.localStorage.getItem("user_data");
  if (userDataJson === null) {
    return null;
  }

  const userData = JSON.parse(userDataJson);
  if (!userData) {
    return null;
  }

  const { token, tokenExpirationDate, permissions } = JSON.parse(userDataJson);
  const expirationDate = new Date(tokenExpirationDate);

  if (expirationDate < new Date(new Date().getTime() + 15 * 60000)) {
    refreshToken(token)
      .then((newToken) => {
        var tokenData = jwtDecode(newToken);
        const newExpirationDate = new Date(0);
        newExpirationDate.setUTCSeconds(tokenData.exp);
        window.localStorage.setItem(
          "user_data",
          JSON.stringify({
            login: tokenData.login,
            userId: isNaN(tokenData.id) ? tokenData.id : Number(tokenData.id),
            menuId: tokenData.menuId,
            companyId: tokenData.companyId,
            token: newToken,
            tokenExpirationDate: newExpirationDate,
            metaData: {},
            permissions,
            contextId: tokenData.contextId,
            contextLoginId: tokenData.contextLoginId,
            isSuperuser: tokenData.isSuperuser === "True",
            isSourceContextSuperuser:
              tokenData.isSourceContextSuperuser === "True",
          }),
        );
      })
      .catch(() => window.localStorage.setItem("user_data", null));
  }

  return token;
};

export default getToken;

export const refreshToken = (token) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(process.env.API_URL + "Auth/refreshToken", {
      method: "GET",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.text())
      .then((result) => {
        resolve(result);
      })
      .catch(reject);
  });

export const setToken = (token) => {
  var tokenData = jwtDecode(token);
  const login = getContextLogin(tokenData);
  const newExpirationDate = new Date(0);
  newExpirationDate.setUTCSeconds(tokenData.exp);
  window.localStorage.setItem(
    "user_data",
    JSON.stringify({
      login,
      userId: isNaN(tokenData.id) ? tokenData.id : Number(tokenData.id),
      menuId: tokenData.menuId,
      companyId: tokenData.companyId,
      token: token,
      tokenExpirationDate: newExpirationDate,
      metaData: {},
      contextId: tokenData.contextId,
      contextLoginId: tokenData.contextLoginId,
      isSuperuser: tokenData.isSuperuser === "True",
      isSourceContextSuperuser: tokenData.isSourceContextSuperuser === "True",
    }),
  );
};

const getContextLogin = (tokenData) => {
  const {
    id: userId,
    contextId,
    contextLoginId: contextLogin,
    login,
  } = tokenData;
  if (contextId) {
    return userId == contextId ? login : `${contextLogin} (${login})`;
  }
  return login;
};
