import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { DatagridV2 } from "@echo/datagrid";
import { Box, useDisclosure } from "@chakra-ui/react";
import { useDatagrid } from "./hooks/use-datagrid-v2";
import { useSelection } from "./hooks/use-selection";
import ColumnVisibilityManager from "../datagrid-component/header/column-visibility-manager/column-visibility-manager";
import { ColumnSettings } from "../datagrid-component/column-settings/column-settings";
import { getDatagridColumnsPropType } from "./prop-type";
import { useComponentRegister } from "../../../../../../components-registry/hooks/use-component-register";
import { useContext, useMemo } from "react";
import UserContext from "../../../../../../user-context";

const DatagridV2Component = (props) => {
  const {
    rootProps,
    nodeRef,
    onRowClick,
    onRowDoubleClick,
    onSelectChange,
    selectionMode,
    disableFilters,
    disableHeader,
    disableNavOnLoading,
    context,
  } = props;

  const { userId, companyId } = useContext(UserContext);
  const { state, api } = useDatagrid(props);
  const { selectedItems, selectSingle, selectMany } = useSelection(
    state.data,
    onSelectChange,
    selectionMode,
  );

  const {
    onColumnChange,
    onFilterChange,
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    updateModel,
    refreshData,
    onRowEdit,
    onRowAdd,
  } = api;

  const {
    isOpen: isColumnsManagerOpen,
    onClose: onColumnsManagerClose,
    onOpen: onColumnsManagerOpen,
  } = useDisclosure();

  const {
    isOpen: isColumnsSettingsOpen,
    onClose: onColumnsSettingsClose,
    onOpen: onColumnsSettingsOpen,
  } = useDisclosure();

  const clearFilters = () => onFilterChange({});

  const contextMenuActions = [
    {
      label: "Manage columns",
      action: onColumnsManagerOpen,
      color: "red",
    },
    {
      label: "Advanced columns settings",
      action: onColumnsSettingsOpen,
    },
    { label: "Clear filters", action: clearFilters },
    { label: "Refresh data", action: refreshData },
  ];

  const component = useMemo(
    () => ({
      name,
      getColumns: () => state.settings.columns,
      getData: () => state.data,
      getSelectedRows: () => selectedItems,
      refreshData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, state.settings.columns, state.data, selectedItems],
  );

  useComponentRegister(context, component);

  const actions = {
    onRowClick,
    onRowDoubleClick,
    onFilterChange,
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onColumnWidthChange: (columns) =>
      onColumnChange(columns, userId, null, "user", companyId),
    onColumnOrder: (columns) =>
      onColumnChange(columns, userId, null, "user", companyId),
    onColumnVisibilityChange: (columns) =>
      onColumnChange(columns, userId, null, "user", companyId),
    onColumnsSettingsChange: onColumnChange,
    onRowSelect: selectSingle,
    onSelectAll: selectMany,
    onRowEdit,
    onRowAdd,
  };

  return (
    <Box
      ref={nodeRef}
      overflow="hidden"
      width="100%"
      height="100%"
      {...rootProps}
    >
      <ColumnVisibilityManager
        isOpen={isColumnsManagerOpen}
        onClose={onColumnsManagerClose}
        columns={state.settings.columns}
        onChange={onColumnChange}
      />
      <ColumnSettings
        isOpen={isColumnsSettingsOpen}
        onClose={onColumnsSettingsClose}
        defaultColumns={state.settings.columns}
        settingsId={state.settings.id}
        settingsKey={state.settings.key}
        onChange={onColumnChange}
        sourceObject={state.source}
        updateModel={updateModel}
      />
      <DatagridV2
        data={state.data}
        columns={state.columns}
        actions={actions}
        page={state.page}
        filters={state.filters}
        sort={state.settings.sorting}
        itemsCount={state.itemsCount}
        loading={state.loading}
        selectionMode={props.selectionMode}
        enableAdding={props.enableAdding}
        enableEdit={props.enableEdit}
        settings={state.settings}
        contextMenuActions={contextMenuActions}
        selectedItems={selectedItems}
        disableFilters={disableFilters}
        disableHeader={disableHeader}
        disablePagination={disableNavOnLoading && state.loading}
      />
    </Box>
  );
};

DatagridV2Component.propTypes = {
  name: PropTypes.string,
  dataSource: PropTypes.any,
  columns: getDatagridColumnsPropType(PropTypes.object),
  context: PropTypes.any,
  rootProps: PropTypes.any,
  nodeRef: PropTypes.any,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowAdd: PropTypes.func,
  onRowEdit: PropTypes.func,
  onSelectChange: PropTypes.func,
  disableHeader: PropTypes.bool,
  disableFilters: PropTypes.bool,
  disableNavOnLoading: PropTypes.bool,
  selectionMode: PropTypes.oneOf(["single", "multiple", "none"]),
  enableAdding: PropTypes.bool,
  enableEdit: PropTypes.bool,
  columnKey: PropTypes.string,
  designerMode: PropTypes.bool,
};

DatagridV2Component.defaultProps = {};

export default withBaseComponent(DatagridV2Component);
