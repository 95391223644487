/* eslint-disable no-unused-vars */
// prettier-ignore
export const shimFunction = () => { /* function shim for component designer */}
// prettier-ignore
export const shimFunctions = {
    shimFunction0:  () => shimFunction(),
    shimFunction1:  (arg0) => shimFunction(),
    shimFunction2:  (arg0, arg1) => shimFunction(),
    shimFunction3:  (arg0, arg1, arg2) => shimFunction(),
    shimFunction4:  (arg0, arg1, arg2, arg3) => shimFunction(),
    shimFunction5:  (arg0, arg1, arg2, arg3, arg4) => shimFunction(),
    shimFunction6:  (arg0, arg1, arg2, arg3, arg4, arg5) => shimFunction(),
    shimFunction7:  (arg0, arg1, arg2, arg3, arg4, arg5, arg6) => shimFunction(),
    shimFunction8:  (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7) => shimFunction(),
    shimFunction9:  (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8) => shimFunction(),
    shimFunction10: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9) => shimFunction(),
    shimFunction11: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) => shimFunction(),
    shimFunction12: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11) => shimFunction(),
    shimFunction13: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12) => shimFunction(),
    shimFunction14: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13) => shimFunction(),
    shimFunction15: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14) => shimFunction(),
    shimFunction16: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15) => shimFunction(),
    shimFunction17: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16) => shimFunction(),
    shimFunction18: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17) => shimFunction(),
    shimFunction19: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18) => shimFunction(),
    shimFunction20: (arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10, arg11, arg12, arg13, arg14, arg15, arg16, arg17, arg18, arg19) => shimFunction(),
}

// eslint-disable-next-line consistent-return
export const getShim = (propName, info) => {
  switch (info.propTypeName) {
    case 'string': {
      return propName;
    }
    case 'bool':
      return false;
    case 'number':
      return 0;
    case 'array':
      return [];
    case 'func':
      return shimFunction;
    case 'funcOf':
      return shimFunctions[`shimFunction${info.allowedParameters.length}`];
    case 'symbol': // TODO
      break;
    case 'any': // TODO
      break;
    case 'arrayOf': // TODO
      break;
    case 'element': // TODO
      break;
    case 'elementType': // TODO
      break;
    case 'instanceOf': // TODO
      break;
    case 'node': // TODO
      break;
    case 'objectOf': // TODO
      break;
    case 'oneOfType': // TODO
      break;
    case 'shape': // TODO
      break;
    case 'exact': // TODO
      break;
    case 'object': // TODO
      break;
    case 'oneOf': // TODO
      break;
    default:
      throw new Error(`Cannot find shim for propType: ${info.propTypeName}`);
  }
};
