export const getRecordValues = (record, columns) => {
  const values = columns
    .filter((col) => col.name !== "checkbox")
    .map((col) => ({
      column: col,
      value: record[col.name],
    }));
  return values;
};

const isArray = (el) => Array.isArray(el);
const isObject = (el) => typeof el === "object";

export const getResultData = (res) => {
  if (!isObject(res) || (isArray(res) && !isObject(res[0]))) {
    throw new Error("Value must be key value pair.");
  }

  if (isArray(res)) return res[0];
  return res;
};
