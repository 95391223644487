import { useEffect, useRef } from "react";
import { arrayEqual } from "../array";

export function useCustomEffect(effect, deps, equalFunc = arrayEqual) {
  const ref = useRef({ iteration: 0, deps });

  const equalityCheck = equalFunc(deps, ref.current?.deps || []);

  if (typeof equalityCheck !== "boolean") {
    window.console.warn(
      "Equality function passed to 'useCustomEffect' hook should always return value of type 'boolean'"
    );
  }

  if (typeof equalityCheck === "boolean" && !equalityCheck) {
    ref.current = { iteration: (ref.current.iteration || 0) + 1, deps };
  } else {
    ref.current = { iteration: ref.current.iteration || 0, deps };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [ref.current?.iteration]);
}
