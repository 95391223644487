export const componentApiBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { componentsRegistry } = componentContext;
      const { getComponent } = componentsRegistry;
      const { componentName, method } = staticParams;
      const component = await getComponent(componentName);

      if (!component) {
        throw new Error(
          `Component ${componentName} is not registered in context.`,
        );
      }

      const func = component[method];
      if (!func) throw new Error("Component API method is not implemented.");

      return await func(staticParams);
    },
  };
};
