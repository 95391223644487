import { useEffect, useRef, useState } from "react";

const getPromiseValue = (value) => {
  const type = typeof value;
  if (value) {
    switch (type) {
      case "object": {
        if (Array.isArray(value) && value[0]) {
          const key = Object.keys(value[0])[0];
          return value[0][key];
        } else {
          const key = Object.keys(value)[0];
          return value[key];
        }
      }

      case "string":
      case "number":
      default:
        return value.toString();
    }
  }
  return "";
};

export const useDebouncedValueState = (
  value,
  onChangeCallback,
  name,
  initialValue = null,
  delay = 400,
) => {
  const [internalValue, setInternalValue] = useState(initialValue);
  const hookRef = useRef({ updateHash: 0 });

  const handleOnChange = (newValue) => {
    if (onChangeCallback && typeof onChangeCallback === "function") {
      onChangeCallback(newValue, name ? { path: name } : undefined);
    }
  };

  const handleSetInternalValue = (value) => {
    hookRef.current.updateHash++;
    setInternalValue(value);
  };

  useEffect(() => {
    if (
      value !== null &&
      // eslint-disable-next-line eqeqeq
      value !== internalValue
    ) {
      if (typeof value === "function") {
        const func = value();

        func.then((res) => {
          const result = getPromiseValue(res);
          setInternalValue(result);
        });
      } else {
        setInternalValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    let isMounted = true;
    const handler = setTimeout(() => {
      if (isMounted && hookRef.current.updateHash !== 0) {
        handleOnChange(internalValue);
      }
    }, delay);

    return () => {
      isMounted = false;
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hookRef.current.updateHash]);

  return [internalValue, handleSetInternalValue];
};
