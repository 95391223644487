export const generateNumeration = (state, definition) => {
  var numerationDef = definition.numeration;
  var numeration = "";

  while (numerationDef.includes("<") && numerationDef.includes(">")) {
    if (numerationDef.indexOf("<") !== 0) {
      numeration += numerationDef.substring(1, numerationDef.indexOf("<"));
      numerationDef = numerationDef.substring(
        numerationDef.indexOf("<"),
        numerationDef.length
      );
    } else {
      var searchElem = numerationDef.substring(1, numerationDef.indexOf(">"));
      numerationDef = numerationDef.substring(
        numerationDef.indexOf(">"),
        numerationDef.length
      );
      const docDateKey =
        Object.keys(state || {}).find(
          (key) => key.toLowerCase() === "docdate"
        ) || "docDate";
      switch (searchElem) {
        case "symbol":
          numeration += definition.code;
          break;
        case "auto":
          numeration += "<auto>";
          break;
        case "year":
          numeration +=
            state && state[docDateKey]
              ? new Date(state[docDateKey]).getFullYear()
              : new Date().getFullYear();
          break;
        case "month":
          numeration +=
            state && state[docDateKey]
              ? new Date(state[docDateKey]).getMonth() + 1
              : new Date().getMonth() + 1;
          break;
        default:
          var stateElem = Object.keys(state || {}).find(
            (key) => key.toLowerCase() === searchElem
          );
          numeration +=
            state && state[stateElem]
              ? state && state[stateElem]
              : "<" + searchElem + ">";
          break;
      }
    }
  }

  return numeration;
};
