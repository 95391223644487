import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

const EchoInput = forwardRef((props, ref) => {
  const {
    label,
    id,
    nodeRef,
    colorScheme,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    onMouseOver,
    onMouseLeave,
    style,
    size,
    variant,
    onClick,
    icon,
    ...restProps
  } = props;

  return (
    <FormControl
      ref={nodeRef}
      variant={variant}
      id={id}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isReadOnly={isReadOnly}
      isRequired={isRequired}
      width="fit-content"
      height="fit-content"
      style={style}
      size={size}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Input
        width="100%"
        height={style?.fontSize ? "auto" : undefined}
        fontSize={style?.fontSize}
        ref={ref}
        {...restProps}
      />
      {label && <FormLabel>{label}</FormLabel>}
      {icon && icon}
    </FormControl>
  );
});

EchoInput.displayName = "EchoInput";

EchoInput.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  type: PropTypes.oneOf([undefined, "password", "number", "text"]),
  rootProps: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  nodeRef: PropTypes.any,
  colorScheme: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.any,
};

EchoInput.defaultProps = {
  variant: "floating",
  placeholder: " ",
};

export default EchoInput;
