import React from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import LineGraph from "../smooth-line/";
import PropTypes from "prop-types";
import { ContextMenu } from "@echo/ui";
import { useContextMenu } from "@echo/ui/src/lib/components/context-menu/hooks/useContextMenu";

const getLineProps = (from, to, height, width, offsetY) => {
  const correction = to.x === from.x ? 10 : 0;

  return {
    name: "simple",
    width,
    height,
    padding: [10, 10, 10, 10],
    lines: [
      {
        key: "line-series",
        data: [
          [0, -offsetY],
          [0 + correction, -35 - offsetY],
          [to.x - from.x - correction, from.y - to.y + 35 - offsetY],
          [to.x - from.x, from.y - to.y - offsetY],
        ],
        color: "#c03",
        smooth: true,
      },
    ],
  };
};

const calculateDimensions = (from, to) => {
  const correction = to.x === from.x ? 10 : 0;
  const width =
    Math.max(to.x, from.x) - Math.min(to.x, from.x) + 20 + correction;
  const heightDiff = Math.max(to.y, from.y) - Math.min(to.y, from.y);
  const offsetY = to.y - 35 <= from.y ? Math.abs(to.y - from.y - 35) : 0;
  const offsetX = to.x >= from.x ? 0 + correction / 2 : width - 20;

  const height = offsetY > 0 ? offsetY + 55 : heightDiff + 20;

  return {
    width,
    height,
    offsetY,
    offsetX,
  };
};

const ConnectionComponent = ({
  from,
  to,
  editModalComponent,
  label,
  onDelete,
}) => {
  const {
    openContextMenu,
    closeContextMenu,
    clickPosition,
    isContextMenuOpened,
  } = useContextMenu();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dimensions = calculateDimensions(from, to);

  const lineProps = getLineProps(
    from,
    to,
    dimensions.height,
    dimensions.width,
    dimensions.offsetY,
  );

  const contextMenuActions = [
    { label: "Delete", action: onDelete, color: "red" },
  ];

  if (typeof editModalComponent === "function") {
    contextMenuActions.unshift({ label: "Edit", action: onOpen });
  }

  return (
    <>
      <ContextMenu
        isOpen={isContextMenuOpened}
        onClose={closeContextMenu}
        clickPosition={clickPosition}
        actions={contextMenuActions}
      />
      <Box
        position="absolute"
        top={`${from.y - dimensions.offsetY - 10}px`}
        left={`${from.x - dimensions.offsetX - 10}px`}
        onContextMenu={openContextMenu}
      >
        <LineGraph {...lineProps} />
        <Box
          position="absolute"
          width={`${dimensions.width}px`}
          height={`${dimensions.height}px`}
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
        >
          {label && (
            <Box
              position="absolute"
              top={`${dimensions.height / 2 - 35}px`}
              className="disable-ppz-drag"
            >
              ({label})
            </Box>
          )}
        </Box>
      </Box>
      {typeof editModalComponent === "function" &&
        editModalComponent({ isOpen, onOpen, onClose })}
    </>
  );
};

ConnectionComponent.propTypes = {
  from: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  editModalComponent: PropTypes.func,
  label: PropTypes.string,
  onDelete: PropTypes.func,
};

ConnectionComponent.defaultProps = {};

export default ConnectionComponent;
