import PropTypes from "prop-types";
import InputValueCell from "./input-value-cell";
import EchoBoolValueCell from "./echo-bool-value-cell";
import EchoComboValueCell from "./echo-combo-value-cell";
import EchoEditorValueCell from "./echo-editor-value-cell";

const ValueCell = ({
  propName,
  propValue,
  propType,
  onChange,
  customTypes,
  onAction,
  options,
  disabled,
  model,
}) => {
  switch (propType) {
    case "input":
      return (
        <InputValueCell
          propValue={propValue}
          onChange={onChange}
          disabled={disabled}
          propType={propType}
        />
      );
    case "combo":
      return (
        <EchoComboValueCell
          propValue={propValue}
          onChange={onChange}
          disabled={disabled}
          options={options}
          propType={propType}
        />
      );
    case "bool":
      return (
        <EchoBoolValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
          disabled={disabled}
          propType={propType}
        />
      );
    case "postgresql-editor":
      return (
        <EchoEditorValueCell
          propType={propType}
          propValue={propValue}
          onChange={onChange}
          disabled={disabled}
          onAction={onAction}
        />
      );
    default:
      if (customTypes.map((t) => t.name).includes(propType)) {
        const cType = customTypes.find((t) => t.name === propType);
        if (cType) {
          return cType.component({
            propValue,
            onChange,
            disabled,
            propName,
            propType,
            model,
            options,
          });
        }
      }
      window.console.error(
        `Unsupported value cell type '${propType}' in properties table`,
      );
      return <div />;
  }
};

ValueCell.propTypes = {
  propName: PropTypes.string,
  propValue: PropTypes.any,
  propType: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  onAction: PropTypes.func,
  options: PropTypes.any,
  customTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ),
};

ValueCell.defaultProps = {
  customTypes: [],
  disabled: false,
  model: {},
};

export default ValueCell;
