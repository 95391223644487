import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import PermissionList from "./permission-list";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import PermissionEditForm from "./permission-edit-form";
import { savePermission } from "../../../services/permission-service";
import { showErrorToast } from "../echo-error-toast";

const PermissionEditor = ({
  isOpen,
  onClose,
  properties,
  modalProps,
  componentId,
  location,
}) => {
  const [mode, setMode] = useState("list");
  const [activeElement, setActiveElement] = useState(null);

  const handleMultifunctionButton = useCallback(() => {
    if (mode === "edit") {
      savePermission(activeElement)
        .then(() => {
          Promise.all([setActiveElement(undefined), setMode("list")]);
        })
        .catch((err) =>
          showErrorToast({
            reasonTitle: "Error. Cannot save permission",
            location: "Permission",
            shortMessage: err.toString().slice(0, 300),
            message: err.toString(),
          })
        );
    } else if (mode === "list") {
      setMode("edit");
    }
  }, [activeElement, mode]);
  // const setEditMode = useCallback(() => {}, []);
  const handleSelectionChange = useCallback(
    (selection) => setActiveElement(selection),
    []
  );
  const handleCancel = useCallback(() => {
    if (mode === "list") {
      onClose();
    } else {
      Promise.all([setActiveElement(null), setMode("list")]);
    }
  }, [mode, onClose]);

  const handleChange = useCallback(
    (newElement) => setActiveElement(newElement),
    []
  );

  const getButtonText = useCallback(() => {
    if (mode === "list") {
      return activeElement ? "Edit" : "Add";
    } else {
      return "Save";
    }
  }, [activeElement, mode]);

  const handleOnRowDoubleClick = useCallback(
    (row) => Promise.all([setActiveElement(row), setMode("edit")]),

    []
  );

  const handleOnDelete = useCallback(() => {
    Promise.all([setActiveElement(null), setMode("list")]);
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Permissions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {mode === "edit" ? (
            <PermissionEditForm
              mapLocation={(loc) => `${location}_${loc}`}
              value={activeElement}
              onChange={handleChange}
              properties={properties}
              componentId={componentId}
              onDelete={handleOnDelete}
            />
          ) : (
            <PermissionList
              componentId={componentId}
              onSelectionChange={handleSelectionChange}
              onRowDoubleClick={handleOnRowDoubleClick}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleMultifunctionButton}
            colorScheme="red"
            variant="ghost"
          >
            {getButtonText()}
          </Button>
          <Button
            onClick={onClose}
            colorScheme="red"
            isDisabled={mode !== "list"}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

PermissionEditor.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      propertyNameMap: PropTypes.string.isRequired,
      permissionCheckProcess: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired })
  ).isRequired,
  onChange: PropTypes.func,
  modalProps: PropTypes.shape({
    portalProps: PropTypes.shape({
      containerRef: PropTypes.shape({ current: PropTypes.any }),
    }),
  }),
  componentId: PropTypes.number,
  location: PropTypes.string,
};

PermissionEditor.defaultProps = {};

export default PermissionEditor;
