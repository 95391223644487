import { getComponentById } from "./get-component-by-id";

const runDispatcher = (action, dispatcher, mode) => {
  if (!action) {
    window.console.error("'useDispatcher': action is undefined");
    throw new Error("'useDispatcher': action is undefined");
  }
  if (!dispatcher || mode !== "designer") {
    const msg =
      mode !== "designer"
        ? "'useDispatcher': Cannot use dispatcher. Dispatcher is undefined"
        : "'useDispatcher': Cannot use dispatcher in render mode";
    window.console.error(msg);
    throw new Error(msg);
  }
  dispatcher(action);
};

const createApi = (definition, mode, state, setState, dispatcher) => {
  return {
    getComponentDefinition: (id, guid, nodeId) =>
      getComponentById(id, guid, nodeId, definition),
    getRootComponentDefinition: () => definition,
    getMode: () => mode,
    getDragMode: () => state?.dragMode,
    setActivePropertiesComponentId: (newActivePropertiesComponentId) =>
      setState({
        ...state,
        activePropertiesComponentId: newActivePropertiesComponentId,
      }),
    getActivePropertiesComponentId: () => state?.activePropertiesComponentId,
    changeMode: (newMode) => setState({ ...state, mode: newMode }),
    GetDropAreaId: () => state.dropAreaId,
    ChangeDropAreaId: (id) => setState({ ...state, dropAreaId: id }),
    updateComponentProperty: (id, newProperty, propName) => {
      runDispatcher(
        {
          type: "UPDATE_COMPONENT_PROPERTY",
          selectedComponent: id,
          model: newProperty,
          propertyName: propName,
        },
        dispatcher,
        mode,
      );
    },
    addComponent: (definition, defaultProps, parentId) =>
      runDispatcher(
        {
          type: "DROP_COMPONENT",
          definition,
          defaultProps,
          activeAreaId: parentId,
        },
        dispatcher,
        mode,
      ),
  };
};

const createApiContext = (
  definition,
  mode,
  state,
  setState,
  dispatcher,
  routerContext,
) => ({
  api: createApi(definition, mode, state, setState, dispatcher),
  echoRouter: routerContext,
  state,
  mode,
  definition,
});

export const useApi = (
  definition,
  routerContext,
  dispatcher,
  state,
  setState,
) => {
  return [
    createApiContext(
      definition,
      state.mode,
      state,
      setState,
      dispatcher,
      routerContext,
    ),
  ];
};
