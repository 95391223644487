import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { StateDebugger } from "../state-debugger";

// TODO use React context with plugin package pattern
const ManagerBody = ({ context }) => {
  const handleStateChange = useCallback(
    (name, value) => {
      if (typeof context?.functions?.setStateProp === "function") {
        context.functions.setStateProp(`formSource.${name}`, value);
      }
    },
    [context.functions],
  );

  return (
    <Tabs colorScheme="red">
      <TabList>
        <Tab>Debugger</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <StateDebugger
            state={context?.state?.formSource}
            onPropChange={handleStateChange}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

ManagerBody.propTypes = {
  context: PropTypes.any,
};

ManagerBody.defaultProps = {};

export default ManagerBody;
