/* eslint-disable react/prop-types */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { echoPortal } from "../../components/echo-portal/echo-portal";
import { Button } from "@chakra-ui/react";

const MessageModal = (props) => {
  const { header, message, onClose, closeOnOverlayClick } = props;
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick ?? false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent width="700px" maxWidth="700px">
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const messageBlock = (block) => {
  return {
    definition: block,
    execute: (staticParams) => {
      // eslint-disable-next-line no-unused-vars
      const { queryParams, __model, __processBlock, ...modalProps } =
        staticParams;

      echoPortal.render((container) => (
        <MessageModal {...modalProps} onClose={() => container.unmount()} />
      ));
    },
  };
};
