import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import PanPinchZoomDragContext from "../context/pan-pinch-zoom-drag-context";
import Draggable from "react-draggable";

const PPZArea = ({ children, ...props }) => {
  const areaRef = useRef(null);

  const {
    toolboxState,
    transformState,
    containerState,
    updateAreaState,
    areaState,
  } = useContext(PanPinchZoomDragContext);

  const handleOnMouseMove = (e) => {
    const fixedMousePosition = {
      x: e.clientX - containerState.x - e.target.offsetLeft,
      y: e.clientY - containerState.y - e.target.offsetTop,
    };

    const areaMousePosition = {
      x:
        (fixedMousePosition.x - transformState.positionX) /
        transformState.scale,
      y:
        (fixedMousePosition.y - transformState.positionY) /
        transformState.scale,
    };
    updateAreaState({ mousePosition: areaMousePosition });
  };

  const updateMouseState = (isMouseOver) => {
    updateAreaState({ isMouseOver });
  };

  const handleMouseOver = () => updateMouseState(true);

  const handleMouseLeave = () => updateMouseState(false);

  return (
    <Box
      ref={areaRef}
      {...props}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleOnMouseMove}
      // onTouchMove={handleOnMouseMove}
      // onTouchEnd={handleMouseLeave}
      // onTouchStart={handleMouseOver}
    >
      {children}
      {toolboxState?.dragComponent && areaState?.isMouseOver ? (
        <Box position="absolute">
          <Draggable position={areaState?.mousePosition}>
            {toolboxState.dragComponent}
          </Draggable>
        </Box>
      ) : null}
    </Box>
  );
};

PPZArea.propTypes = {
  children: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PPZArea.defaultProps = {};

export default PPZArea;
