// @flow

import * as React from "react";
import type { Column } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { format } from "date-fns";
import { Checkbox } from "@chakra-ui/react";
import { getNumberFormat } from "../../utils/get-number-format";
import { isDate } from "date-fns";

type DisplayCellProps = {
  column: Column,
  value: string,
  onClick: (column: Column) => void,
};

export const DisplayCell = (props: DisplayCellProps) => {
  const { column, value, onClick } = props;

  return (
    <Cell
      onClick={() => onClick(column)}
      column={column}
      width={column.width}
      key={`${column.name}`}
      style={{
        textAlign: column?.align,
      }}
    >
      {column.type === "boolean" ? (
        <Checkbox isChecked={getDisplayValue(column, value)} isReadOnly />
      ) : (
        getDisplayValue(column, value)
      )}
    </Cell>
  );
};

const getConditions = (column, value) => {
  const { type, editType } = column;
  const isDate =
    type?.toLowerCase().includes("date") ||
    editType?.toLowerCase().includes("date");
  const isString = type === "string";
  const isNumber = type === "number";
  const isBool = type === "boolean" || editType === "checkbox";
  const isCombo = editType === "combo";

  return { isString, isNumber, isDate, isBool, isCombo };
};

const getNumberValue = (value, format) => {
  const { precision, separator } = getNumberFormat(format);
  if (!value && value !== 0) return "";

  return parseFloat(value)
    .toFixed(precision)
    .replace(".", separator)
    .replace(",", separator);
};

const getDateValue = (value, formatStr) => {
  if (!value) return "";
  try {
    const date = new Date(value);
    const formatted = format(date, formatStr || "dd-MM-yyyy");
    return formatted;
  } catch (e) {
    console.error(e);
    return "";
  }
};

const getBoolValue = (value) => {
  if (!value) return false;
  if (value === 0 || value === false || value === "false") return false;
  if (value === 1 || value === true || value === "true") return true;

  return true;
};

const getDisplayValue = (column, value) => {
  const { format } = column;
  const { isNumber, isDate, isBool } = getConditions(column, value);

  if (isDate) return getDateValue(value, format);
  if (isNumber) return getNumberValue(value, format);
  if (isBool) return getBoolValue(value);

  return value;
};
