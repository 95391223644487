const mapParamName = (inputName, resultName) => {
  const lowerCaseName = inputName.toLowerCase();

  const inputCharsArr = lowerCaseName.split("");
  const resCharsArr = resultName.split("");

  const convertedInputChars = inputCharsArr.map((char, idx) => {
    if (char === resCharsArr[idx]) return char;
    else return char.toUpperCase();
  });

  return convertedInputChars.join("");
};

const findParamInModel = (param, model) => {
  const found = Object.keys(model).find(
    (key) => key.toLowerCase() === param.toLowerCase(),
  );

  if (found) {
    const convertedName = mapParamName(param, found);
    return { name: param, value: model[convertedName] };
  }

  return null;
};

const mapModelProperties = (modelObject, modelName) => {
  const keys = Object.keys(modelObject);

  const newModel = keys.reduce((acc, curr) => {
    const newKey = modelName + "_" + curr;
    return { ...acc, [newKey]: modelObject[curr] };
  }, {});

  return newModel;
};

const getModels = (modelsCollection) => {
  const keys = Object.keys(modelsCollection);

  const mappedModels = keys.map((key) =>
    mapModelProperties(modelsCollection[key], key),
  );

  return mappedModels;
};

const getParamValues = (params, currentModel, forwardedModels) => {
  const values = [];

  params.forEach((param) => {
    const found = findParamInModel(param, currentModel);
    if (found) values.push(found);

    if (forwardedModels && forwardedModels.length > 0) {
      forwardedModels.forEach((model) => {
        const found = findParamInModel(param, model);
        if (found) values.push(found);
      });
    }
  });

  return values;
};

const getFixedUrl = (url, params) => {
  let fixedUrl = url;

  params.forEach((param) => {
    const { name, value } = param;
    const fixedValue = value.toString().toLowerCase().replaceAll(" ", "-");
    fixedUrl = fixedUrl.replace(`{${name}}`, fixedValue);
  });

  return fixedUrl;
};

const getPath = (url, currentModel, forwardedModels) => {
  const params = extractParamsFromURL(url);

  if (params) {
    const paramValues = getParamValues(params, currentModel, forwardedModels);
    const fixedUrl = getFixedUrl(url, paramValues);
    return fixedUrl;
  }

  return url;
};

const extractParamsFromURL = (url) => {
  const params = url.match(/\{([^{}]+)\}/g);

  if (params)
    return params.map((param) => param.substring(1, param.length - 1));

  return [];
};

export const openExternalUrlBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params) => {
      const { url, __model, ...rest } = staticParams;
      const {
        page: {
          state: { modelsCollection },
        },
      } = componentContext;

      const forwardedModels = [...getModels(modelsCollection), rest];
      const currentModel = params.length > 0 && params[0] ? params[0] : __model;
      const path = getPath(url, currentModel, forwardedModels);

      window.open(path, "_blank");

      return staticParams;
    },
  };
};
