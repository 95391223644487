import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Box, Button, Flex } from '@chakra-ui/react';

const DetailsModal = ({
  isOpen,
  onClose,
  reasonTitle,
  location,
  message,
  shortMessage,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width='700px' maxWidth='700px'>
        <ModalHeader>{reasonTitle || 'Error'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            flexFlow='column'
            gap='8px'
            border='1px solid var(--chakra-colors-lightGrayCa)'
          >
            {location && (
              <p style={{ fontSize: '12px', marginTop: 0, padding: '0px 8px' }}>
                <i>{location}</i>
              </p>
            )}
            {shortMessage && (
              <Box width='100%' padding='8px' backgroundColor='#f2f2f2'>
                <p
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {shortMessage}
                </p>
              </Box>
            )}
            {message && (
              <Box width='100%' padding='8px' backgroundColor='#f2f2f2'>
                <p
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {message}
                </p>
              </Box>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

DetailsModal.propTypes = {
  reasonTitle: PropTypes.string,
  location: PropTypes.string,
  message: PropTypes.string,
  shortMessage: PropTypes.string,

  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

DetailsModal.defaultProps = {};

export default DetailsModal;
