import { Box, Input } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";

const InputComponent = ({
  nodeRef,
  style,
  onClick,
  onMouseOver,
  onMouseLeave,
  onMouseDown,
  onMouseUp,
  onTouchEnd,
}) => {
  /*useEffect(() => {
    if (!value || !value.isNumeric) {
      onChange({ value: value });
    }
  }, [value]);*/

  return (
    <Box>
      <Input
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onTouchEnd={onTouchEnd}
        ref={nodeRef}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        style={style}
      />
    </Box>
  );
};

InputComponent.propTypes = {
  onMouseDown: PropTypes.func, // TODO funcOf
  onMouseUp: PropTypes.func, // TODO funcOf
  onTouchEnd: PropTypes.func, // TODO funcOf
  onTouchStart: PropTypes.func, // TODO funcOf
  type: PropTypes.oneOf(["text"]),
  style: PropTypes.object,
  nodeRef: PropTypes.object,
  onClick: PropTypes.funcOf(PropTypes.object),
  onMouseOver: PropTypes.funcOf(PropTypes.object),
  onMouseLeave: PropTypes.funcOf(PropTypes.object),
};

InputComponent.defaultProps = {
  onClick: shimFunctions.shimFunction1,
  onMouseOver: shimFunctions.shimFunction1,
  onMouseLeave: shimFunctions.shimFunction1,
  onChange: shimFunctions.shimFunction1,
  onMouseUp: () => {},
  onMouseDown: () => {},
  onTouchEnd: () => {},
};

export default InputComponent;
