import { showErrorToast } from '../../../../../../shared/echo-error-toast';
import { useEffect, useState } from 'react';
import { getTreeSchema } from '../effects/get-tree-schema';

export const useSourceSchema = ({ dataSource, componentContext }) => {
  const [schema, setSchema] = useState(null);

  useEffect(() => {
    getTreeSchema(dataSource, componentContext)
      .then(setSchema)
      .catch(showErrorToast);
  }, [componentContext, dataSource]);

  return [schema, setSchema];
};
