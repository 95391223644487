import { Box, Button, Divider, HStack } from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useAsInternalState } from "../../../hooks/shared/use-as-internal-state";
import BlockEditTabs from "./block-edit/block-edit-tabs";
import GeneralSettings from "./block-edit/general-settings";

const BlockEdit = ({ isOpen, onClose, block, onEdit, modalProps }) => {
  const [blockModel, setBlockModel] = useAsInternalState(block, true);

  const handleOnSave = () => {
    if (onEdit) {
      Promise.all([onClose(), onEdit(blockModel)]);
    }
  };

  return (
    blockModel && (
      <Modal
        minHeight={0}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        {...modalProps}
      >
        <ModalOverlay />
        <ModalContent
          margin={0}
          minHeight={0}
          width="90%"
          maxW="90%"
          height="90%"
          maxH="90%"
        >
          <ModalHeader>Edit block properties</ModalHeader>
          <ModalCloseButton />
          <ModalBody minHeight={0}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="stretch"
              width="100%"
              flex="1"
              gap="8px"
              height="100%"
              minHeight={0}
              overflowX="auto"
            >
              <GeneralSettings model={blockModel} updateModel={setBlockModel} />
              <Divider orientation="vertical" />
              <BlockEditTabs model={blockModel} updateModel={setBlockModel} />
            </Box>
            <Divider />
          </ModalBody>

          <ModalFooter>
            <HStack>
              <Button onClick={onClose}>Close</Button>
              <Button onClick={handleOnSave} variant="ghost">
                Save
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  );
};

BlockEdit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onEdit: PropTypes.func,
  block: PropTypes.shape({}).isRequired,
  modalProps: PropTypes.any,
};

BlockEdit.defaultProps = {};

export default BlockEdit;
