import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useDraggable } from "./hooks/use-draggable";
import { useFreezableValue } from "./hooks/use-freezable-value";

const Draggable = forwardRef(
  ({ children, onDragStart, onDragEnd, onDrag, disabled }, ref) => {
    const childrenComponent = React.Children.only(children);

    const [draggableProps, isDrag] = useDraggable(
      childrenComponent,
      { onDragStart, onDragEnd, onDrag },
      ref,
    );

    const Component = useFreezableValue(
      () =>
        React.cloneElement(
          childrenComponent,
          disabled
            ? childrenComponent.props
            : {
                ...childrenComponent.props,
                ...draggableProps,
              },
        ),
      isDrag,
    );

    return Component;
  },
);

Draggable.displayName = "Draggable";

Draggable.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
};

Draggable.defaultProps = {
  disabled: false,
};

export default Draggable;
