import { toast } from "react-toastify";
import { showErrorToast } from "../../components/shared/echo-error-toast";
import { caseInsensitiveIdCheckExists } from "../../utils/data/id-check";
import { executeBlock, executeBlockByGroup } from "../process-executor";

const hasProperties = (obj) => Object.keys(obj).length > 0;

const getModel = (staticParams, componentContext) => {
  const { __model } = staticParams;
  const modelIsNotEmpty = hasProperties(__model);

  if (modelIsNotEmpty) return __model;
  return componentContext.functions.getModel()?.formSource;
};

const getUpdatedModel = (model, changes) => {
  const id = model?.id || model?.Id || model?.ID || model?.iD || null;
  const newModel = changes.reduce(
    (acc, curr) => ({ ...acc, [curr]: model[curr] }),
    { Id: id },
  );

  return newModel;
};

const getChanges = (initialData, newData) => {
  let changes = [];
  const initialDataKeys = Object.keys(initialData);

  initialDataKeys.forEach((key) => {
    if (initialData[key] !== newData[key]) {
      changes = [...changes, key];
    }
  });

  return changes;
};

const updateComponentData = (dataObject, componentContext) => {
  if (dataObject && caseInsensitiveIdCheckExists(dataObject)) {
    componentContext.functions.updateSource(dataObject);
  }
};

const getModelFromResult = (result) => {
  if (typeof result === "undefined" || result === null) {
    return result;
  }

  if (typeof result === "object" && Array.isArray(result)) {
    if (result.length > 0) {
      return typeof result[0] === "object" ? result[0] : null;
    }
  }

  return typeof result === "object" && !Array.isArray(result) ? result : null;
};

const executeProcess = (
  componentContext,
  staticParams,
  params,
  action,
  id,
  model,
  resolve,
  reject,
  systemParams,
) => {
  const disableToast = staticParams.disableToast;

  Promise.resolve(
    executeBlock(
      componentContext,
      action.id,
      { ...staticParams, __model: model },
      [
        ...(params?.filter((c) => c !== null) ?? []),
        { action: id ? "update" : "create" },
        { componentId: componentContext?.component?.id },
        { isOpen: false },
      ],
      systemParams,
    ),
  )
    .then((res) => {
      const resModel = getModelFromResult(res);
      if (res) {
        updateComponentData(resModel, componentContext);
      }
      resolve({
        __model: { ...model, ...resModel },
        useContextModel: true,
      });
      if (!disableToast) {
        toast.success("Saved successfully.");
      }
    })
    .catch((err) => {
      showErrorToast(err);
      reject(err);
    });
};

const executeProcessGroup = (
  componentContext,
  staticParams,
  params,
  action,
  id,
  model,
  resolve,
  reject,
  systemParams,
) => {
  const disableToast = staticParams.disableToast;

  Promise.resolve(
    executeBlockByGroup(
      componentContext,
      { group: action.name, tag: id ? "UPDATE" : "CREATE" },
      { ...staticParams, ...action.staticParams, __model: model },
      [
        ...(params?.filter((c) => c !== null) ?? []),
        { action: id ? "update" : "create" },
        { componentId: componentContext?.component?.id },
        { isOpen: false },
      ],
      systemParams,
    ),
  )
    .then((res) => {
      const resModel = getModelFromResult(res);
      if (res) {
        updateComponentData(resModel, componentContext);
      }
      resolve({
        __model: { ...model, ...resModel },
        useContextModel: true,
      });
      if (!disableToast) {
        toast.success("Saved successfully.");
      }
    })
    .catch((err) => {
      showErrorToast(err);
      reject(err);
    });
};

export const saveFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const model = getModel(staticParams, componentContext);
        const initialModel =
          componentContext.functions.getModel()?.initialFormSource;

        const changes = getChanges(initialModel, model);

        const id = model?.id ?? model?.Id ?? model?.ID ?? model?.iD;
        const action = id
          ? componentContext.functions.getUpdateAction()
          : componentContext.functions.getCreateAction();

        if (id && changes.length < 1) {
          resolve({
            __model: { ...model },
            useContextModel: true,
          });
          return;
        }

        // const updatedModel = getUpdatedModel(model, changes);
        if (action.type === "PROCESS") {
          executeProcess(
            componentContext,
            staticParams,
            params,
            action,
            id,
            model,
            resolve,
            reject,
            systemParams,
          );
        } else if (action.type === "BLOCK_GROUP") {
          executeProcessGroup(
            componentContext,
            staticParams,
            params,
            action,
            id,
            model,
            resolve,
            reject,
            systemParams,
          );
        } else {
          throw new Error("Unexpected action type");
        }
      }),
  };
};
