/* eslint-disable react/forbid-foreign-prop-types */
import PropTypes from "prop-types";
import { createElement } from "react";
import { getComponent } from "../../../../utils/echo-component/echo-component-utils";

const EchoStandardComponent = ({
  source,
  componentProps,
  styleOptions,
  systemProps,
}) => {
  return createElement(getComponent(source), {
    ...componentProps,
    style: styleOptions,
    ...systemProps,
  });
};

EchoStandardComponent.isWrapper = true;

EchoStandardComponent.propTypes = {
  source: PropTypes.string.isRequired,
  componentProps: PropTypes.object,
  styleOptions: PropTypes.object,
  systemProps: PropTypes.object,
};

EchoStandardComponent.defaultProps = {
  componentProps: {},
  systemProps: {},
};

export default EchoStandardComponent;
