export const getJsBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) => {
      const context = staticParams.useSourceContext
        ? systemParams?.source?.context || componentContext
        : componentContext;
      const body = staticParams?.__processBlock?.body || block.body;
      const func = JSON.parse(body, Function.deserialize);

      try {
        return func(context, block.id, params, staticParams);
      } catch (e) {
        throw new Error(e.message, { cause: block.name });
      }
    },
  };
};
