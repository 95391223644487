// @flow

import type { Context } from "react";

import * as React from "react";
import { createContext } from "react";
import type { DatagridProps } from "../datagrid-v2";

export const DatagridContext: Context<DatagridProps | null> =
  createContext(null);

export const DatagridContextProvider = ({
  children,
  value,
}: {
  children: React.Node,
  value: TDatagridContext,
}): React.Node => {
  return (
    <DatagridContext.Provider value={value}>
      {children}
    </DatagridContext.Provider>
  );
};
