import { baseDelete, baseGet, basePost } from "./base-fetch/base-fetch";

export const getPermissions = (companyId, userId, roleId, modelId, itemId) =>
  baseGet("Permission/getPermissions", {
    companyId,
    userId,
    roleId,
    modelId,
    itemId,
  });

export const getRoles = (companyId) =>
  baseGet("Permission/getRoles", { companyId });

export const savePermission = (permission) =>
  basePost("Permission/savePermission", permission);

export const deletePermission = (id) =>
  baseDelete("Permission/deletePermission", { id });
