import { toast } from "react-toastify";
import { GET } from "../../base-fetch";
import { handleAuthResult } from "./handle-result";
import { setAuthStatus, storeToken } from "./store-google-token";

export const googleAuthStatus = {
  progress: "in_progress",
  success: "success",
  error: "error",
};

const getAuthUrl = async (userId) => {
  // eslint-disable-next-line no-undef
  const res = await GET(process.env.EMAIL_SERVICE_URL + "auth/get-auth-url", {
    userId,
  });
  const data = await res.json();
  const { authUrl } = data;

  return { authUrl };
};

const getAuthInfo = async (userId) => {
  const res = await GET(
    // eslint-disable-next-line no-undef
    process.env.EMAIL_SERVICE_URL + "auth/get-auth-info",
    { userId },
  );

  const data = await res.json();
  return data;
};

export const authenticateToGoogle = async (userId, disableInfoToast) => {
  const { progress, error } = googleAuthStatus;
  setAuthStatus(progress);

  const { authUrl } = await getAuthUrl(userId);

  if (!disableInfoToast) {
    await new Promise((resolve) => {
      toast.info("Zostaniesz przekierowany na stronę Google.", {
        onClose: resolve,
        onClick: resolve,
        autoClose: 3000,
      });
    });
  }

  const authWindow = window.open(authUrl, "_black", "popup=true,active=false");

  if (!authWindow) {
    setAuthStatus(error);
    toast.error(
      "Zablokowano przekierowanie, proszę odblokować wyskakujące okienka w ustawieniach przeglądarki.",
    );
    return {};
  }

  const authInfo = await new Promise((resolve) => {
    const interval = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(interval);
        const data = getAuthInfo(userId);
        resolve(data);
      }
    }, 1000);
  });

  const { data, authenticated } = await handleAuthResult(userId, authInfo);
  if (authenticated) storeToken(data);

  return data;
};
