import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  Input,
  Stack,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import ProcessBlockEditContext from "./process-block-edit-context";
import PropTypes from "prop-types";

const GeneralSettings = ({ onDelete }) => {
  const { model, updateModel } = useContext(ProcessBlockEditContext);
  const [isDelete, setIsDelete] = useState(false);

  return (
    model && (
      <Box
        display="flex"
        flexDirection="row"
        height="100%"
        minHeight={0}
        flex="1 2 0"
        boxSizing="border-box"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack>
          <Box height="42px" display="flex" alignItems="center">
            General settings
          </Box>
          <Box display="flex" alignItems="center" width="100%" gap="10px">
            <Input
              width="40%"
              value={model.displayName || ""}
              onChange={(e) =>
                updateModel({ ...model, displayName: e.target.value })
              }
            />
            <FormLabel>Block display name</FormLabel>
          </Box>
          <Checkbox
            disabled
            checked={model.isAsynchronous}
            onChange={() =>
              updateModel({ ...model, isAsynchronous: !model.isAsynchronous })
            }
          >
            Is asynchronous (not implemented yet)
          </Checkbox>
          {!isDelete ? (
            <Button onClick={() => setIsDelete(true)}>
              Delete block and related links
            </Button>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              Are you sure?
              <HStack display="flex" justifyContent="center">
                <Button
                  color="red.500"
                  onClick={(...args) =>
                    Promise.all([setIsDelete(false), onDelete(...args)])
                  }
                >
                  Yes
                </Button>
                <Button onClick={() => setIsDelete(false)}>No</Button>
              </HStack>
            </Box>
          )}
        </Stack>
      </Box>
    )
  );
};

GeneralSettings.propTypes = {
  onDelete: PropTypes.func,
};

export default GeneralSettings;
