/* eslint-disable consistent-return */
import { parseDataValue } from "./data-value-parser";

const getIdFromDataValue = (dataValue) => {
  if (
    dataValue &&
    parseDataValue(dataValue) !== false &&
    parseDataValue(dataValue).id
  ) {
    const id = parseDataValue(dataValue).id;
    return id;
  } else return null;
};

export const getBelowContainerId = (e, skipId) => {
  const x = e.clientX,
    y = e.clientY;

  const elements = document.elementsFromPoint(x, y);

  for (let elemIndex in elements) {
    const elem = elements[elemIndex];
    if (elem) {
      const dataValue = elem.getAttribute("data-value");
      if (
        dataValue &&
        dataValue.startsWith("{") &&
        getIdFromDataValue(dataValue) !== skipId
      ) {
        return getIdFromDataValue(dataValue);
      }
    }
  }
};
