import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Box, Button, HStack } from "@chakra-ui/react";
import EchoComponent from "../../../shared/echo-components/echo-component";
import { useComponentDefinition } from "../../../shared/echo-components/base-components/components/graph-designer-component/hooks/use-component-definition";

const ComponentModal = ({
  isOpen,
  onClose,
  context,
  modalProps,
  componentData,
}) => {
  const component = useComponentDefinition(componentData?.componentId);
  const { componentsRegistry } = context;
  const { getComponent } = componentsRegistry;

  const findRegisteredComponents = (component, registry) => {
    if (!component) return null;
    let result = registry;

    const c = getComponent(component.name);
    if (c) result.push(c);

    const { childrenComponents } = component;
    if (childrenComponents && childrenComponents.length > 0) {
      for (let child of childrenComponents) {
        findRegisteredComponents(
          {
            name: child.componentProps.name,
            childrenComponents: child.childrenComponents,
          },
          result,
        );
      }
    }

    return result;
  };

  const handleClose = useCallback(() => {
    if (typeof onClose === "function") {
      onClose("cancel");
    }
  }, [onClose]);

  const handleSave = useCallback(
    async (c) => {
      if (typeof onClose === "function") {
        const registeredComponents = findRegisteredComponents(c, []);
        if (registeredComponents.length > 0) {
          const data = registeredComponents.reduce((acc, curr) => {
            return [...acc, ...curr.getData()];
          }, []);

          await onClose("save", data);
          return;
        }

        await onClose("save");
      }
    },
    [onClose],
  );

  return (
    <Modal
      isCentered
      className="disable-ppz-drag"
      {...modalProps}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent
        margin={0}
        width={componentData?.width || "90%"}
        maxW="90%"
        height={componentData?.height || "90%"}
        maxH="90%"
      >
        <ModalHeader className="disable-ppz-drag">
          {componentData?.modalTitle || "Properties"}
        </ModalHeader>
        <ModalCloseButton className="disable-ppz-drag" />
        <ModalBody className="disable-ppz-drag" minHeight={0}>
          <HStack minHeight={0} height="100%">
            {component ? (
              <EchoComponent
                source="!?echo-defined"
                styleOptions={component.styleOptions}
                context={context}
                componentProps={{
                  ...component.componentProps,
                  useRootContext: true,
                  context: context,
                }}
                systemProps={{
                  id: component?.id,
                  guid: component?.guid,
                  nodeId: component?.nodeId,
                  designerMode: false,
                  context: context,
                  childs: [],
                  component,
                  path: "",
                }}
              />
            ) : (
              <Box>Loading...</Box>
            )}
          </HStack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button colorScheme="red" onClick={handleClose}>
              {componentData?.buttons?.cancel || "Cancel"}
            </Button>
            <Button colorScheme="blue" onClick={() => handleSave(component)}>
              {componentData?.buttons?.save || "Save"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ComponentModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  context: PropTypes.any,
  modalProps: PropTypes.any,
  componentData: PropTypes.shape({
    componentId: PropTypes.number,
    modalTitle: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    buttons: PropTypes.shape({
      cancel: PropTypes.string,
      save: PropTypes.string,
    }),
  }),
};

ComponentModal.defaultProps = {};

export default ComponentModal;
