import { useCallback, useEffect, useMemo, useState } from "react";

export const usePageSize = (props) => {
  const defaultPageSize = 20;
  const { ownerId, key, saveSetting, getSetting, isActive } = props;
  const [settingId, setSettingId] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(defaultPageSize);

  const settingKey = useMemo(() => {
    return `DGC_${ownerId}_${key || "%"}_itemsPerPage`;
  }, [key, ownerId]);

  const getPageSize = async () => {
    try {
      const data = await getSetting(settingKey);
      const value = parseFloat(data.value) ?? defaultPageSize;
      setItemsPerPage(value);
      setSettingId(data.id);
      return value;
    } catch (e) {
      setItemsPerPage(defaultPageSize);
      setSettingId(null);
      return 20;
    }
  };

  const savePageSize = useCallback(
    (itemsPerPage) => {
      saveSetting(settingId || null, settingKey, itemsPerPage);
    },
    [settingKey, settingId, itemsPerPage],
  );

  useEffect(() => {
    if (isActive && settingKey) getPageSize();
  }, [settingKey]);

  return { itemsPerPage, savePageSize };
};
