/* eslint-disable consistent-return */

import { useEffect } from "react";

export const useComponentRegister = (context, component) => {
  useEffect(() => {
    if (context && component.name) {
      const { componentsRegistry } = context;
      if (componentsRegistry) {
        const { register, unregister } = componentsRegistry;

        register(component);

        return () => unregister(component);
      }
    }
  }, []);

  useEffect(() => {
    if (context && component.name) {
      const { componentsRegistry } = context;
      if (componentsRegistry) {
        const { getComponent, updateComponent } = componentsRegistry;
        const f = getComponent(component.name);
        let timeout;

        if (f) {
          timeout = setTimeout(() => updateComponent(component), 500);
        }
        return () => clearTimeout(timeout);
      }
    }
  }, [component]);
};
