import { getParent } from "../../../components/echo-components/utils/definition-tree-utils/get-parent";

export const getCallerComponentPropDefinition = (
  systemParams,
  componentContext,
  name
) => {
  const { source } = systemParams;
  let { callerId, callerGuid, callerNodeId } = systemParams;
  let context = componentContext;

  if (source?.context && source?.callerId) {
    callerId = source.callerId;
    context = source.context;
    callerGuid = source.callerGuid;
    callerNodeId = source.callerNodeId;
  }

  const parent = getParent(
    context.component,
    callerId,
    callerGuid,
    callerNodeId
  );

  if (parent === null) {
    return null;
  }

  const componentChild =
    parent.childrenComponents && Array.isArray(parent.childrenComponents)
      ? parent.childrenComponents.find((cmp) => cmp.id === callerId)
      : null;

  return {
    prop: componentChild.componentProps[name],
    callerId,
    callerGuid,
    callerNodeId,
    parent,
  };
};
