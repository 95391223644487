export const getFormComponentProps = (def) => ({
  label: def.name,
  name: def.name,
  onChange: {
    id: 25,
    name: "changeProp",
    type: "PROCESS",
    staticParams: {
      path: `formSource.${def.name}`,
    },
  },
  value: {
    name: `formSource.${def.name}`,
    id: `formSource.${def.name}`,
    type: "STATE_PROP",
  },
});
