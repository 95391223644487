const updatePropertyInElement = (element, property) => {
  if ("componentProps" in element) {
    element["componentProps"][property.name] = property.value;
  }
  if ("childrenComponents" in element) {
    updateChildrenArray(element.childrenComponents, property);
  }
};

const updateChildrenArray = (childrenArray, property) => {
  if (Array.isArray(childrenArray)) {
    for (let children of childrenArray) {
      updatePropertyInElement(children, property);
    }
  }
};

export default (structure, property) => {
  const structureCopy = { ...structure };
  updatePropertyInElement(structureCopy, property);
  return structureCopy;
};
