import { processDataLoader } from "./process-data-loader";
import { statePropDataLoader } from "./state-prop-data-loader";
import { valueDataLoader } from "./value-data-loader";

export const loadDatagridData = (props) =>
  new Promise((resolve, reject) => {
    const { value, dataSource } = props;

    if (value) {
      valueDataLoader(props).then(resolve).catch(reject);
    } else if (dataSource) {
      if (!dataSource.id) {
        reject("'dataSource' must have an id property");
      }
      switch (dataSource?.type) {
        case "PROCESS":
          processDataLoader(props).then(resolve).catch(reject);
          break;
        case "STATE_PROP":
          statePropDataLoader(props).then(resolve).catch(reject);
          break;
      }
    } else {
      resolve(null);
    }
  });
