import { Box } from "@chakra-ui/react";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import ChildrenComponent from "../children-component/children.component";
import DesignerShim from "../../../../shim-components/designer-shim/designer-shim";
import { useDataSource } from "../../hooks/use-datasource";

const SwitchComponent = (props) => {
  const { id, guid, nodeId, rootProps, dataSource, context } = props;

  const { nodeRef, ...restRootProps } = rootProps;
  const [apiContext, component, mode] = useApiContext(id, guid, nodeId);

  const [switchElements] = useDataSource(
    dataSource,
    context,
    {
      callerId: component?.id,
      callerGuid: component?.guid,
      callerNodeId: component?.nodeId,
    },
    {}, //staticParams,
    [], //params,
    true,
  );

  const handleOnMouseOver = (e) => {
    if (mode === "designer") {
      e.preventDefault();
      e.stopPropagation();
      apiContext.api.ChangeDropAreaId(id);
    }
  };

  const DesignerView =
    component &&
    component?.childrenComponents &&
    Array.isArray(component?.childrenComponents) &&
    component?.childrenComponents.length > 0 ? (
      <>
        {component?.childrenComponents.map((children, index) => (
          <ChildrenComponent
            key={`children-${component?.componentProps?.name}-${index}`}
            {...props}
            mode="designer"
            onMouseOver={handleOnMouseOver}
            definition={children}
          />
        ))}
      </>
    ) : (
      <DesignerShim />
    );

  const availableIndexes =
    mode === "designer"
      ? []
      : switchElements &&
          Array.isArray(switchElements) &&
          switchElements.length > 0
        ? switchElements.map((elem) => Object.values(elem)[0].toString())
        : [];

  const RenderView = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {component &&
        component?.childrenComponents &&
        Array.isArray(component?.childrenComponents) &&
        component?.childrenComponents.length > 0 &&
        component?.childrenComponents
          .filter((_, index) => {
            return availableIndexes.includes(index.toString());
          })
          .map((children, index) => (
            <ChildrenComponent
              {...props}
              key={`children-${component?.componentProps?.name}-${index}`}
              forceRendererMode
              onMouseOver={handleOnMouseOver}
              definition={children}
            />
          ))}
    </>
  );

  return (
    <Box
      data-value={
        mode === "designer"
          ? JSON.stringify({ depth: component.depth, id })
          : undefined
      }
      ref={nodeRef}
      {...restRootProps}
    >
      {mode === "designer" ? DesignerView : RenderView}
    </Box>
  );
};

SwitchComponent.propTypes = {
  id: PropTypes.number,
  dataSource: PropTypes.any,
  rootProps: PropTypes.any,
  guid: PropTypes.string,
  nodeId: PropTypes.string,
  context: PropTypes.any,
};

SwitchComponent.defaultProps = {};

export default withBaseComponent(SwitchComponent);
