import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, useDisclosure, CircularProgress } from "@chakra-ui/react";
import {
  PanPinchZoomDrag,
  PPZArea,
  PPZDraggable,
} from "@echo/pan-pinch-zoom-drag";
import Toolbox from "./toolbox/toolbox";
import { FiSave } from "react-icons/fi";
import GraphDesignerModal from "./modal/graph-designer-modal";
import { getConnectionComponent } from "./util/connection";
import TileGraphComponent from "./tile-graph/tile-graph-component";
import { createConnection } from "./util/create-connection";
import { getRandomIntInclusive } from "@echo/tools";
import { tileIconSet } from "./tile/tile-icons";

const GraphDesigner = ({
  tiles,
  graphState,
  onSave,
  onDelete,
  tilePropertiesComponentFunc,
  connectionPropertiesComponentFunc,
  graphPropertiesComponentFunc,
}) => {
  const containerRef = useRef();

  const {
    isOpen: isSettingsOpen,
    // onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  const [state, setState] = useState({ connections: [], elements: [] });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (
      graphState &&
      graphState.connections &&
      graphState.elements &&
      Array.isArray(graphState.connections) &&
      Array.isArray(graphState.elements)
    ) {
      setState(graphState);
    }
  }, [graphState]);

  const setElements = useCallback(
    (elements) => setState({ ...state, elements }),
    [state],
  );

  const setConnections = useCallback(
    (connections) => setState({ ...state, connections }),
    [state],
  );

  const [newConnectionState, setNewConnectionState] = useState({
    sourceId: null,
  });

  const save = useCallback(
    (exit) =>
      new Promise((resolve, reject) => {
        if (typeof onSave === "function") {
          Promise.resolve(setLoader(true))
            .then(() =>
              Promise.resolve(onSave(state, exit))
                .then(() => {
                  resolve();
                  setLoader(false);
                })
                .catch(() => {
                  reject();
                  setLoader(false);
                }),
            )
            .catch(() => {
              reject();
              setLoader(false);
            });
        }
      }),
    [onSave, state],
  );

  const deleteTile = useCallback(
    async (tile) => {
      setLoader(true);
      try {
        await onDelete(tile);
      } catch (e) {
        throw new Error(e);
      } finally {
        setLoader(false);
      }
    },
    [onDelete],
  );

  const handleDelete = useCallback((tile) => deleteTile(tile), [deleteTile]);
  // const handleOnSettingsClick = () => {};
  const handleSave = useCallback(() => save(false), [save]);

  const handleSaveAndExit = useCallback(() => save(true), [save]);

  const handleConnectionUpdate = useCallback(
    (newConnection) => {
      const newConnections = [...state.connections];
      const elementIndex = newConnections.findIndex(
        (e) => e.id === newConnection.id,
      );
      newConnections[elementIndex] = newConnection;
      setState({
        ...state,
        connections: newConnections,
      });
    },
    [state],
  );

  const handleElementUpdate = useCallback(
    (newElement) => {
      const newElements = [...state.elements];
      const elementIndex = newElements.findIndex((e) => e.id === newElement.id);
      newElements[elementIndex] = newElement;
      setState({
        ...state,
        elements: newElements,
      });
    },
    [state],
  );

  const handleElementDelete = useCallback(
    (element) => {
      setState({
        connections: state.connections.filter(
          (connection) =>
            connection.sourceId !== element.id &&
            connection.destinationId !== element.id,
        ),
        elements: state.elements.filter((e) => e.id !== element.id),
      });
      handleDelete(element);
    },
    [state.connections, state.elements, handleDelete],
  );

  const handleConnectionDelete = useCallback(
    (toDelete) => {
      const newConnections = [...state.connections];
      setConnections(
        newConnections.filter(
          (connection) =>
            connection.sourceId !== toDelete.sourceId ||
            connection.destinationId !== toDelete.destinationId,
        ),
      );
    },
    [setConnections, state.connections],
  );

  const handleOnConnectorClick = useCallback(
    (element) => {
      createConnection(element, {
        newConnectionState,
        setNewConnectionState,
        connections: state.connections,
        setConnections,
      });
    },
    [newConnectionState, setConnections, state.connections],
  );

  const handleUpdatePosition = useCallback(
    (dragData, element) => {
      const newElements = [...state.elements];
      const elemIndex = newElements.findIndex((b) => b.id === element.id);
      newElements[elemIndex].positionX = dragData.x;
      newElements[elemIndex].positionY = dragData.y;
      setElements(newElements);
    },
    [setElements, state.elements],
  );

  return (
    <Box
      display="flex"
      height="90%"
      width="100%"
      flex={0.95}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Box
        ref={containerRef}
        position="absolute"
        top={0}
        left={0}
        height="100%"
        width="100%"
        className="diagram-designer-modal container-modal"
      />
      {isSettingsOpen && (
        <GraphDesignerModal
          isOpen={isSettingsOpen}
          onClose={onSettingsClose}
          propertiesComponent={graphPropertiesComponentFunc || (() => <Box />)}
          modalProps={{ portalProps: { containerRef } }}
        />
      )}
      <PanPinchZoomDrag
        border="1px solid var(--chakra-colors-lightGrayCa)"
        borderRadius={5}
        flex={0.98}
        flexDirection="row"
        width="100%"
        height="98%"
        customNavigation={[
          // {
          //   key: "graph-settings-action",
          //   onClick: () => onSettingsOpen(),
          //   icon: <FiSettings />,
          // },
          {
            key: "graph-save-action",
            onClick: handleSaveAndExit,
            icon: <FiSave />,
          },
        ]}
        toolboxComponent={
          <Toolbox
            tiles={tiles}
            onDrop={(position, tile) =>
              setElements([
                ...state.elements,
                {
                  id: -getRandomIntInclusive(1, 999999999),
                  positionX: position.x,
                  positionY: position.y,
                  tileId: tile.id,
                },
              ])
            }
          />
        }
      >
        <PPZArea
          display="flex"
          width="10000px"
          height="10000px"
          backgroundColor="red.50"
        >
          {state.connections.map((connection) => (
            <React.Fragment
              key={`${connection.sourceId}-${connection.destinationId}`}
            >
              {getConnectionComponent(
                connection,
                state.elements,
                handleConnectionUpdate,
                handleConnectionDelete,
                connectionPropertiesComponentFunc,
              )}
            </React.Fragment>
          ))}
          {state.elements.map((element, index) => (
            <PPZDraggable
              key={`${element.tileId}-${index}`}
              position={{ x: element.positionX, y: element.positionY }}
              onStop={(_, dragData) => handleUpdatePosition(dragData, element)}
            >
              {({ scale }) => {
                const tile = tiles.find((tile) => tile.id === element.tileId);
                const labelKey =
                  typeof element === "object" &&
                  Object.keys(element).find(
                    (elementKey) => elementKey.toLowerCase() === "name",
                  );
                return (
                  <TileGraphComponent
                    highlightConnector={
                      element.id === newConnectionState.sourceId
                    }
                    editModalComponent={(steeringModalProps) => (
                      <GraphDesignerModal
                        key={element.id}
                        {...steeringModalProps}
                        modalProps={{ portalProps: { containerRef } }}
                        propertiesComponent={
                          tilePropertiesComponentFunc || (() => <Box />)
                        }
                        state={element}
                        onEdit={handleElementUpdate}
                      />
                    )}
                    scale={scale}
                    saveDiagram={handleSave}
                    onDelete={() => handleElementDelete(element)}
                    onConnectorClick={() => handleOnConnectorClick(element)}
                    tile={tile}
                    label={labelKey ? element[labelKey] : null}
                    isNew={element.id < 0}
                  />
                );
              }}
            </PPZDraggable>
          ))}
        </PPZArea>
      </PanPinchZoomDrag>
      {loader && (
        <Box
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
          flex={0.98}
          flexDirection="row"
          background="var(--chakra-colors-blackAlpha-600)"
        >
          <CircularProgress isIndeterminate color="green.300" />
        </Box>
      )}
    </Box>
  );
};

GraphDesigner.propTypes = {
  graphState: PropTypes.shape({
    connections: PropTypes.array,
    elements: PropTypes.array,
  }),
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      type: PropTypes.oneOf([1, 2, 3, "circle", "square", "diamond"]),
      label: PropTypes.string,
      color: PropTypes.string,
      icon: PropTypes.oneOf(tileIconSet),
    }),
  ),
  tilePropertiesComponentFunc: PropTypes.func,
  connectionPropertiesComponentFunc: PropTypes.func,
  graphPropertiesComponentFunc: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  rootProps: PropTypes.any,
};

GraphDesigner.defaultProps = {};

export default GraphDesigner;
