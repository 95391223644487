export const getMetadata = (cData) =>
  Array.isArray(cData) && cData.length > 0
    ? Object.keys(cData[0])
        .filter((key) => key !== 'RecCount')
        .map((key) => ({
          name: key,
          isVisible:
            key.toLowerCase() !== 'id' &&
            key.toLowerCase() !== 'guid' &&
            key.toLowerCase() !== 'nodeId',
          isNull: true,
          type: typeof (
            (cData.find((elem) => elem[key] !== null) || {})[key] ||
            cData[0][key]
          ),
        }))
    : null;
