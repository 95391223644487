export const downloadText = (text, filename, fileType) => {
  const element = document.createElement('a');
  const file = new Blob([text], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${filename}.${fileType}`;
  document.body.appendChild(element);
  element.click();
};
