import { useEffect, useState } from "react";
import { resolveProp } from "../../../utils/property/resolve-prop";

export const useResolveProp = (
  prop,
  extractValueTypeFromObject,
  deps = undefined,
) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (!state) {
      resolveProp(prop, extractValueTypeFromObject)
        .then((result) => setState({ current: result }))
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractValueTypeFromObject, prop, state]);

  useEffect(() => {
    if (state) {
      resolveProp(prop, extractValueTypeFromObject)
        .then((result) => {
          if (result !== state.current) {
            setState({ current: result });
          }
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return state?.current;
};
