import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useEffect, useReducer } from "react";
import { resetComponentId } from "../utils/reset-children-ids";
import DesigneeProperties from "../side-panel/content-components/designee-properties/designee-properties";
import {
  componentDesignerReducer,
  componentReducerInitialState,
} from "../component-designer-reducer";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { saveComponent } from "../../../../services/components-service";
import { getBlock } from "../../../../services/diagram-service";
import { getRandomIntInclusive } from "@echo/tools";
import { saveDiagram } from "../../../diagram-designer/utils/save-diagram";
import { uuidv4 } from "@echo/tools/src/lib/uuidv4/uuidv4";

const getRandomNegativeId = () => -getRandomIntInclusive(1, 999999999);

const makeCopy = (obj) => {
  const json = JSON.stringify(obj);
  const newObj = JSON.parse(json);
  return newObj;
};

const checkProperty = async (property) => {
  let res;
  if (typeof property === "object") {
    if (property && property.type === "PROCESS") {
      res = await getBlock(property.id);
    }
  }

  return res;
};

const replaceBlockIdInLink = (link, blocks) => {
  const linkProperties = Object.keys(link);
  let newLink = {};

  linkProperties.forEach((property) => {
    const found = blocks.find((block) => block.oldId === link[property]);
    if (found) {
      newLink = { ...newLink, [property]: found.id };
    }
  });

  return newLink;
};

const deleteBlockOldId = (block) => {
  // eslint-disable-next-line no-unused-vars
  const { oldId, ...restBlock } = block;
  return restBlock;
};

const replaceLinksIds = (links, blocks) =>
  links.map((link) => {
    const newLink = replaceBlockIdInLink(link, blocks);
    return { ...newLink, id: null };
  });

const replaceBlocksIds = (blocks) =>
  blocks.map((block) => ({
    ...block,
    id: getRandomNegativeId(),
    oldId: block.id,
  }));

const getNewProcess = (oldProcess) => {
  const replacedBlocks = replaceBlocksIds(oldProcess.blocks);
  const newLinks = replaceLinksIds(oldProcess.links, replacedBlocks);
  const newBlocks = replacedBlocks.map(deleteBlockOldId);
  const newProcess = {
    ...oldProcess,
    id: null,
    name: `${oldProcess.name.split("-")[0]}-${uuidv4()}`,
    blocks: newBlocks,
    links: newLinks,
  };

  return newProcess;
};

const saveNewProcess = async (key, componentProps) => {
  const res = await checkProperty(componentProps[key]);
  if (res && res.blocks.length > 0) {
    const newProcess = getNewProcess(res);

    const savedProcess = await saveDiagram(newProcess);

    if (savedProcess) {
      componentProps[key] = {
        ...componentProps[key],
        id: savedProcess.id,
        name: savedProcess.name,
      };
    } else {
      throw new Error("something went wrong");
    }
  }
};

const replaceComponentProcess = async (component) => {
  const { childrenComponents, componentProps } = component;
  const keys = Object.keys(componentProps);

  const asyncOperations = keys.map((key) =>
    saveNewProcess(key, componentProps),
  );

  await Promise.all(asyncOperations);

  if (childrenComponents && childrenComponents.length > 0) {
    await Promise.all(childrenComponents.map(replaceComponentProcess));
  }
};

const resetIdsInComponent = async (component) => {
  const componentCopy = makeCopy(component);
  const { componentData } = componentCopy;
  await replaceComponentProcess(componentData);
  return componentCopy;
};

export const SaveComponentModal = (props) => {
  const [designerState, dispatchState] = useReducer(
    componentDesignerReducer,
    componentReducerInitialState,
  );

  const {
    isOpen,
    onClose,
    selectedComponent,
    setActivePropertiesComponentId,
    componentType,
  } = props;

  const resetActiveComponent = () => {
    setActivePropertiesComponentId(null);
    dispatchState({
      type: "INIT_COMPONENT",
      ...componentReducerInitialState,
    });
  };

  const handleUpdate = (model) =>
    dispatchState({
      type: "UPDATE_COMPONENT_INFO",
      model,
    });

  const handleSave = async () => {
    const { componentInfo } = designerState;

    if (!componentInfo.name || componentInfo.name === "") {
      toast.error("Name is required");
      return;
    }

    const copy = await resetIdsInComponent(designerState);

    await saveComponent({
      ...copy.componentInfo,
      childrenComponents: [copy.componentData],
    })
      .then(() => {
        toast.success("Successfully saved");

        onClose();
        resetActiveComponent();
      })
      .catch(() => toast.error("Error while saving component"));
  };

  useEffect(() => {
    if (selectedComponent) {
      const hasProperties = Object.keys(selectedComponent).length > 0;
      if (hasProperties) {
        const component = resetComponentId(selectedComponent);
        dispatchState({
          type: "INIT_COMPONENT",
          componentInfo: {
            ...componentReducerInitialState.componentInfo,
            type: componentType || "Component",
          },
          componentData: component,
        });
      }
    }
  }, [selectedComponent, componentType]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Set {designerState.componentInfo.type.toLowerCase()} properties
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <DesigneeProperties
            style={{}}
            title=""
            model={designerState.componentInfo}
            onModelChange={handleUpdate}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button colorScheme="red" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

SaveComponentModal.propTypes = {
  selectedComponent: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setActivePropertiesComponentId: PropTypes.func,
  componentType: PropTypes.string,
};
