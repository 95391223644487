import { useEffect, useMemo, useReducer } from "react";
// import { showErrorToast } from "../../../../../echo-error-toast";

const defaultSelectionState = {
  buffer: [],
  mode: "SELECTION",
  lastUsedElement: null,
};

// const changeMode = (model) => {
//   if (model.mode === "SELECTION" && model.buffer.length === 0) {
//     return { ...model, buffer: [], mode: "DESELECTION" };
//   } else {
//     return { ...model, buffer: [], mode: "SELECTION" };
//   }
// };

const getObjId = (obj) => obj._id;

const getSelectedRecords = (state, data) => {
  const { buffer } = state;
  const dataIds = data.map(getObjId);

  let existingRecords = [];
  dataIds.forEach((id) => {
    const found = buffer.find((el) => el === id);
    if (found) existingRecords.push(found);
  });

  const dataToAdd = dataIds.filter(
    (id) => !existingRecords.some((r) => r === id),
  );

  const newBuffer = [...buffer, ...dataToAdd];

  if (existingRecords.length === data.length) return [];

  return newBuffer;
};

const selectionStateReducer = (prevState, action) => {
  switch (action.type) {
    case "SET_SINGLE_SELECTION":
      return {
        ...prevState,
        buffer: [action.elementId],
        mode: "SELECTION",
        lastUsedElement: action.elementId,
      };
    case "REMOVE_SELECTIONS":
      return {
        ...prevState,
        mode: "SELECTION",
        buffer: [],
      };
    case "ADD_SELECTION":
    case "ADD_DESELECTION": {
      return {
        ...prevState,
        buffer: [
          ...prevState.buffer.filter(
            (elementId) => elementId !== action.elementId,
          ),
          action.elementId,
        ],
        lastUsedElement:
          action.type === "ADD_SELECTION"
            ? action.elementId
            : prevState.lastUsedElement,
      };
    }
    case "SET_RANGE_OF_SELECTIONS": {
      return {
        ...prevState,
        buffer: action.range || [],
        mode: "SELECTION",
        lastUsedElement:
          action.range.length === 1
            ? action.elementId
            : prevState.lastUsedElement,
      };
    }
    case "ADD_RANGE_OF_SELECTIONS": {
      return {
        ...prevState,
        buffer: action.range.reduce((prevVal, id) => {
          return prevVal.includes(id) ? prevVal : [...prevVal, id];
        }, prevState.buffer),
        lastUsedElement:
          action.range.length === 1
            ? action.elementId
            : prevState.lastUsedElement,
      };
    }
    case "REMOVE_SELECTION":
    case "REMOVE_DESELECTION": {
      return {
        ...prevState,
        buffer: prevState.buffer.filter(
          (elementId) => elementId !== action.elementId,
        ),
        lastUsedElement:
          action.type === "REMOVE_DESELECTION"
            ? action.elementId
            : prevState.lastUsedElement,
      };
    }
    case "CHANGE_MODE":
      return {
        ...prevState,
        buffer: getSelectedRecords(prevState, action.data),
        lastUsedElement: null,
      };
    case "SELECT_ALL":
      return {
        ...prevState,
      };
    default:
      return prevState;
  }
};

const getSwitchMode = (source, value, groupSelectionMode, options) => {
  const toBin = (val) => (val ? 1 : 0);

  return `${source};v=${toBin(value)};m=${
    groupSelectionMode === "SELECTION" ? "s" : "d"
  };sh=${toBin(options.shifted)};ctrl=${toBin(options.ctrl)}`;
};

const getSelectionActionType = (source, value, groupSelectionMode, options) => {
  const switchMode = getSwitchMode(source, value, groupSelectionMode, options);

  switch (switchMode) {
    case "multiple;v=1;m=s;sh=0;ctrl=0":
    case "multiple;v=1;m=s;sh=0;ctrl=1":
      return "ADD_SELECTION";
    case "multiple;v=0;m=s;sh=0;ctrl=0":
    case "multiple;v=0;m=s;sh=0;ctrl=1":
      return "REMOVE_SELECTION";
    case "multiple;v=1;m=s;sh=1;ctrl=0":
    case "multiple;v=0;m=s;sh=1;ctrl=0":
      return "SET_RANGE_OF_SELECTIONS";
    case "multiple;v=1;m=s;sh=1;ctrl=1":
    case "multiple;v=0;m=s;sh=1;ctrl=1":
      return "ADD_RANGE_OF_SELECTIONS";
    case "single;v=1;m=s;sh=0;ctrl=0":
      return "SET_SINGLE_SELECTION";
    case "single;v=0;m=s;sh=0;ctrl=0":
      return "REMOVE_SELECTION";
    default:
      return null;
  }
};

const resolveRange = (data, state, elementId) => {
  if (!state.lastUsedElement) {
    return [elementId];
  } else {
    const indexOfElementId = data.indexOf(elementId);
    const indexOfLastUsedElement = data.indexOf(state.lastUsedElement);
    if (
      indexOfElementId < 0 ||
      indexOfLastUsedElement < 0 ||
      indexOfElementId === indexOfLastUsedElement
    ) {
      return [elementId];
    }
    if (indexOfLastUsedElement < indexOfElementId) {
      return data.slice(indexOfLastUsedElement, indexOfElementId + 1);
    } else if (indexOfLastUsedElement > indexOfElementId) {
      return data.slice(indexOfElementId, indexOfLastUsedElement + 1);
    }

    return [elementId];
  }
};

export const useSelection = (
  initialState,
  selectionMode,
  onSelectChange,
  data,
) => {
  const [selectionState, dispatch] = useReducer(
    selectionStateReducer,
    initialState || defaultSelectionState,
  );

  useEffect(() => {
    if (onSelectChange) {
      Promise.resolve(
        onSelectChange(selectionState.buffer, getGroupSelectionMode()),
      ).catch(() => {}); // temporary fix - ).catch(showErrorToast);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionState.mode, selectionState.buffer]);

  useEffect(() => {
    if (onSelectChange) {
      onSelectChange([]);
    }
  }, []);

  const changeMode = () => {
    dispatch({ type: "CHANGE_MODE", data });
  };

  const removeSelected = () => dispatch({ type: "REMOVE_SELECTIONS" });

  const allElementsInData = useMemo(
    () => selectionState.buffer.every((el) => data.map(getObjId).includes(el)),
    [data, selectionState.buffer],
  );

  useEffect(() => {
    if (!allElementsInData) removeSelected();
  }, [allElementsInData]);

  const changeSelection = (elementId, value, source, options) => {
    const type = getSelectionActionType(
      source,
      value,
      selectionState.mode,
      options,
    );
    let range = undefined;

    if (
      type === "SET_RANGE_OF_SELECTIONS" ||
      type === "ADD_RANGE_OF_SELECTIONS"
    ) {
      range = resolveRange(
        data.map((e) => e._id),
        selectionState,
        elementId,
      );
    }

    dispatch({
      type,
      elementId,
      range,
    });
  };

  const getSelectionStateForElement = (elementId) =>
    selectionState.buffer.find((el) => el == elementId) ? true : false;
  // if (
  //   (selectionState,
  //   selectionState.mode === "SELECTION" &&
  //     Array.isArray(selectionState.buffer))
  // ) {
  //   const f = selectionState.buffer.find((el) => el == elementId);
  //   return f ? true : false;
  // } else if (
  //   (selectionState,
  //   selectionState.mode === "DESELECTION" &&
  //     Array.isArray(selectionState.buffer))
  // ) {
  //   return !selectionState.buffer.includes(elementId);
  // } else {
  //   return false;
  // }

  const getGroupSelectionMode = () => {
    if (selectionState.mode === "SELECTION") {
      return Array.isArray(selectionState.buffer) &&
        selectionState.buffer.length > 0
        ? "SELECTION_FILLED"
        : "SELECTION_EMPTY";
    } else if (selectionState.mode === "DESELECTION") {
      return Array.isArray(selectionState.buffer) &&
        selectionState.buffer.length > 0
        ? "DESELECTION_FILLED"
        : "DESELECTION_EMPTY";
    } else {
      return "SELECTION_EMPTY";
    }
  };

  const rowProp = (id) => ({
    selectionMode,
    changeSelection,
    selected: getSelectionStateForElement(id),
  });
  const headerProps = {
    selectionMode,
    groupSelectionMode: getGroupSelectionMode(),
    changeMode,
  };

  return [rowProp, headerProps];
};
