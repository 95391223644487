import React, { useContext } from "react";
import LoginPage from "../login-page/login-page";
import UserContext from "../../user-context";
import AppHeader from "./app-header";
import HomePage from "./home-page";
import EchoRouter from "../echo-router/echo-router";
import { Box } from "@chakra-ui/react";
import Dashboard from "../dashboard/dashboard";

const MainPage = () => {
  const { isLoggedIn } = useContext(UserContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      flex={1}
      minHeight={0}
    >
      {isLoggedIn ? (
        <EchoRouter defaultComponent={<Dashboard />}>
          <React.Fragment>
            <AppHeader />
            <HomePage />
          </React.Fragment>
        </EchoRouter>
      ) : (
        <LoginPage />
      )}
    </Box>
  );
};

export default MainPage;
