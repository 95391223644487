import { splitMulti } from "./split-multi";

const isCharValid = (c) => c === "d" || c === "m" || c === "y";

export const getCurrentPart = (pos, parts) =>
  parts.find((p) => pos >= p.start && pos <= p.end);

export const getNextPart = (pos, parts) => parts.find((p) => p.start > pos);

export const getPrevPart = (pos, parts) => parts.findLast((p) => p.end < pos);

export const getPartsFormats = (format) => {
  const chars = format.split("").map((c, idx) => ({ char: c, idx }));
  const withoutSeparators = chars.filter(({ char }) =>
    isCharValid(char.toLowerCase()),
  );
  let parts = [];

  withoutSeparators.forEach((cObj, idx, arr) => {
    if (arr[idx - 1] && arr[idx - 1].char === cObj.char) {
      return;
    }
    const filtered = arr.filter((c) => c.char === cObj.char);
    parts.push(filtered.map(({ char }) => char));
  });

  return parts
    .map((arr) => arr.join(""))
    .map((f, idx) => ({ id: idx, format: f }));
};

export const getPartsRanges = (value, separators) => {
  const splitted = splitMulti(value, separators);
  const mapped = splitted.map((p, idx, arr) => {
    const prevElements = arr.slice(0, idx);
    const start = prevElements.reduce((acc, curr) => acc + curr.length + 1, 0);
    const end = start + p.length;
    return { id: idx, start, end };
  });
  return mapped;
};
