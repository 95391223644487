import { showErrorToast } from "../../../components/shared/echo-error-toast";
import {
  authenticate,
  deleteMails,
} from "../../../services/email-service/email-service";

export const deleteMailsBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { ids } = staticParams;
      const userId = componentContext.userContext.userId;
      const { email: user, refreshToken } = await authenticate(userId);

      if (!user) return {};

      const idsArr = ids.split(",");

      try {
        const res = await deleteMails({ ids: idsArr, refreshToken });

        if (!res.ok) {
          const err = await res.json();
          showErrorToast(err);
          return {
            __resultCondition: "false",
          };
        }

        const { result } = await res.json();
        return {
          __resultCondition:
            result.toLowerCase() === "success" ? "true" : "false",
        };
      } catch (e) {
        throw new Error(e);
      }
    },
  };
};
