import PropTypes from "prop-types";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  IconButton,
} from "@chakra-ui/react";
import { FiFilter } from "react-icons/fi";
import LabeledCheckboxFormControl from "../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";

const options = [
  { id: 0, label: "Contains", value: "contains" },
  { id: 1, label: "Not contains", value: "notContains" },
  { id: 2, label: "Equal", value: "equal" },
  { id: 3, label: "Not equal", value: "notEqual" },
  { id: 4, label: "Greater than", value: "greaterThan" },
  { id: 5, label: "Greater or equal", value: "greaterOrEqual" },
  { id: 6, label: "Lower than", value: "lowerThan" },
  { id: 7, label: "Lower or equal", value: "lowerOrEqual" },
  { id: 8, label: "Empty", value: "empty" },
  { id: 9, label: "Not Empty", value: "notEmpty" },
  //{ label: 'Starts with', value: 'startsWith' },
  //{ label: 'Ends with', value: 'endsWith' },
];

const DatagridFilter = ({ value, onChange }) => {
  const handleOnChange = (selected, propName) => {
    if (onChange && typeof onChange === "function" && propName) {
      onChange({ [propName]: selected });
    }
  };

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          margin="0"
          padding="0"
          minWidth="16px"
          width="16px"
        >
          <FiFilter size="16px" />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Filter options</PopoverHeader>
        <PopoverBody>
          <Box display="flex" flexFlow="column" width="100%">
            {options.map((option) => (
              <LabeledCheckboxFormControl
                id={`filter-checkbox-${option.value}`}
                key={option.value}
                value={
                  (Object.keys(value).length > 0 ? value : { contains: true })[
                    option.value
                  ] === true
                }
                label={option.label}
                onChange={(e) => handleOnChange(e, option.value)}
              />
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

DatagridFilter.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
};

DatagridFilter.defaultProps = { value: {} };

export default DatagridFilter;
