import { useEffect, useRef, useState } from "react";

export const useChildDnd = (id, freeze) => {
  const ref = useRef();
  const [isHover, setIsHover] = useState(null);

  const existInDOM = !!ref?.current;

  useEffect(() => {
    if (id && ref && ref.current) {
      if (ref?.current?.children?.length && freeze) {
        for (let i = 0; i < ref.current.children.length; i++) {
          const element = ref.current.children[i];
          if (typeof element.style === "object") {
            element.style.pointerEvents = "none";
          }
        }
      }

      const unsetHover = () => {
        setIsHover(false);
      };

      const onMouseOver = (event) => {
        if (event.srcElement === ref.current) {
          ref.current.dispatchEvent(
            new Event("topcomponentchange", {
              cancelable: true,
              bubbles: true,
              detail: { srcElement: ref.current },
            })
          );
          setIsHover(true);
        }
      };

      const onMouseMove = (event) => {
        if (isHover === null && event.srcElement === ref.current) {
          onMouseOver(event);
        }
      };

      const onMouseLeave = (event) => {
        if (event.srcElement === ref.current) {
          unsetHover();
        }
      };

      const onTopComponentChange = (event) => {
        if (event.srcElement !== ref.current) {
          unsetHover(event);
        }
      };

      ref.current.addEventListener("mousemove", onMouseMove);
      ref.current.addEventListener("mouseover", onMouseOver);
      ref.current.addEventListener("mouseleave", onMouseLeave);
      ref.current.addEventListener("topcomponentchange", onTopComponentChange);

      return () => {
        ref.current?.removeEventListener("mousemove", onMouseMove);
        ref.current?.removeEventListener("mouseover", onMouseOver);
        ref.current?.removeEventListener("mouseleave", onMouseLeave);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current?.removeEventListener(
          "topcomponentchange",
          onTopComponentChange
        );
      };
    }
    return () => {};
  }, [freeze, id, isHover, ref, existInDOM]);

  return [isHover, ref];
};
