import React, { useEffect, useState } from "react";
import { Tr } from "@chakra-ui/react";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Cell } from "../cell/cell";
import {
  DATAGRID_FILTER_ROW_CELL_TESTID,
  DATAGRID_FILTER_ROW_TESTID,
} from "../../utils/test-ids";
import { FilterCell } from "../cell/filter-cell";

export const FilterRow = () => {
  const {
    visibleColumns,
    filters,
    actions: { onFilterChange },
  } = useDatagridContext();

  const [filtersState, setFiltersState] = useState({});

  useEffect(() => {
    setFiltersState(filters);
  }, [filters]);

  const handleFilterValue = (value, name, dataType) => {
    setFiltersState((prev) => {
      if (prev[name] && value === "") {
        // eslint-disable-next-line no-unused-vars
        const { [name]: key, ...rest } = prev;

        return rest;
      }

      if (prev[name] && prev[name].type) {
        return { ...prev, [name]: { ...prev[name], value, dataType } };
      }

      return { ...prev, [name]: { value, type: "contains", dataType } };
    });
  };

  const handleFilterType = (type, name, dataType) => {
    setFiltersState((prev) => {
      if (prev[name] && prev[name].type === type) {
        if (!prev[name].value || prev[name].value === "") {
          // eslint-disable-next-line no-unused-vars
          const { [name]: key, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          [name]: { ...prev[name], type: "contains", dataType },
        };
      }

      return { ...prev, [name]: { ...prev[name], type, dataType } };
    });
  };

  useEffect(() => {
    let timeout;

    if (filtersState) {
      timeout = setTimeout(() => {
        onFilterChange(filtersState);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [filtersState]);

  return (
    <Tr borderBottom="1px solid black" data-testid={DATAGRID_FILTER_ROW_TESTID}>
      {visibleColumns.map((col) =>
        col.name === "checkbox" ? (
          <Cell
            column={col}
            width={col.width}
            key={`empty-${col.name}`}
            data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
          />
        ) : (
          <FilterCell
            filter={filtersState[col.name]}
            onFilterValueChange={handleFilterValue}
            onFilterTypeChange={handleFilterType}
            column={col}
            key={col.name}
            data-testid={DATAGRID_FILTER_ROW_CELL_TESTID}
          />
        ),
      )}
    </Tr>
  );
};
