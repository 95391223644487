import { columnFilter, dataFilter } from '../data-filter';
import { getOrderBy } from '../data-view-utils';
import { getMetadata } from './metadata/metadata';

export const valueDataLoader = ({ value, state, columns }) =>
  new Promise((resolve, reject) => {
    try {
      const cData = [...(value ?? [])]
        .orderBy(getOrderBy(state.sort))
        .filter((item) => dataFilter(state.globalFilter, item))
        .filter((item) => columnFilter(item, state.columnFilters));

      const metadata = getMetadata(cData);

      const pageData = cData.slice(
        state.page * state.itemsPerPage,
        state.page * state.itemsPerPage + state.itemsPerPage
      );
      resolve({
        type: 'SET_DATA',
        data: pageData,
        source: value,
        count: cData.length,
        columnDefinitions: state.columnDefinitions
          ? state.columnDefinitions
          : columns || metadata,
      });
    } catch (err) {
      reject(err);
    }
  });
