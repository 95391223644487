import { orderColumns } from "./column-order";
import { addColumnPxSizes } from "./columns-size";

export const selectColumnWidth = "40px";

export const getColumns = (
  visibleColumns,
  selectionMode,
  selectColumnWidth,
  tableDimensions,
) => {
  const withPixelSizes = addColumnPxSizes(
    visibleColumns,
    selectionMode,
    selectColumnWidth,
    tableDimensions,
  );

  return orderColumns(withPixelSizes);
};
