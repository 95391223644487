import { forwardRef, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { shimFunctions } from "../../../../../../utils/shims/shims";
import { withBaseComponent } from "../../../with-base-component";
import { useDebouncedValueState } from "../../../../../hooks/shared/use-debounced-value-state";
import { executeBlock } from "../../../../../../process-executor/process-executor";
import LabeledSelectFormControl from "../../../../labeled-select-form-control/labeled-select-form-control";
import { showErrorToast } from "../../../../echo-error-toast";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";

const ComboComponent = forwardRef(
  (
    {
      value,
      isDisabled,
      label,
      onChange,
      variant,
      availableValues,
      rootProps,
      dataSource,
      name,
      context,
      isReadOnly,
      isRequired,
      // eslint-disable-next-line react/prop-types
      isInvalid,
    },
    ref,
  ) => {
    const [availableValuesState, setAvailableValuesState] = useState([]);

    const isReadOnlyState = useResolveProp(isReadOnly, true);

    const values =
      availableValuesState && availableValuesState.length
        ? availableValuesState
        : availableValues;

    const handleOnChange = (newValue) => {
      const option = (values || []).find((option) => {
        const [optionValue] = Object.values(option);
        return optionValue?.toString() === newValue?.toString();
      });
      if (option && typeof onChange === "function") {
        const [, label] = Object.values(option);
        onChange(newValue, label);
      } else if (typeof onChange === "function") {
        onChange(newValue);
      }
    };

    const [inputValue, setInputValue] = useDebouncedValueState(
      value,
      handleOnChange,
      name,
    );

    const handleOnInputChange = (e) => {
      const type = typeof Object.values(availableValuesState[0])[0];
      if (e?.target?.value === "!?placeholder_value") {
        setInputValue(undefined);
      } else if (e?.target?.value?.toString() !== inputValue?.toString()) {
        if (type === "string") {
          setInputValue(e.target.value.toString());
        } else if (type === "number") {
          setInputValue(Number(e.target.value));
        } else if (type === "boolean") {
          setInputValue(Boolean(e.target.value));
        }
      }
    };

    useEffect(() => {
      let isMounted = true;
      if (context && dataSource && dataSource.type === "PROCESS") {
        executeBlock(
          context,
          dataSource.id,
          dataSource.staticParams ?? {},
          [name],
          { name },
        )
          .then((result) => {
            if (isMounted) {
              setAvailableValuesState(result);
            }
          })
          .catch((err) => {
            showErrorToast(err);
          });
      }

      return () => {
        isMounted = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource, context?.state]);

    // test --  w momencie gdy w value i dataSource w combo jest Execute MSSQL, to niezbyt chciało działać i Rafałowi na WorkflowStage się wywalało

    useEffect(() => {
      let isMounted = true;
      const handler = setTimeout(() => {
        if (isMounted && inputValue) {
          handleOnChange(inputValue);
        }
      }, 400);

      return () => {
        isMounted = false;
        clearTimeout(handler);
      };
    }, [inputValue]);

    const { nodeRef, style, ...restRootProps } = rootProps;

    const getInputValue = useCallback(() => {
      const valueType = typeof inputValue;
      switch (valueType) {
        case "number":
          return inputValue;
        case "string":
          return inputValue.toString();
        case "boolean":
          return inputValue ? 1 : 0;
        default:
          return inputValue;
      }
    }, [inputValue]);

    const deserializedValue = getInputValue();

    return (
      <LabeledSelectFormControl
        ref={ref}
        nodeRef={nodeRef}
        isDisabled={isDisabled}
        value={deserializedValue ?? ""}
        onChange={handleOnInputChange}
        isReadOnly={isReadOnlyState}
        rootProps={{ ...restRootProps, ...style, variant }}
        label={label}
        isInvalid={isInvalid}
        isRequired={isRequired}
        fontSize={style?.fontSize}
        data-testid="combo-test"
      >
        <option value="!?placeholder_value" disabled={isReadOnlyState}>
          {" "}
        </option>
        {values.map((option, index) => {
          const [value, label] = Object.values(option);
          return (
            <option
              disabled={
                isReadOnlyState && value?.toString() !== deserializedValue
              }
              key={`option-${value}-${index}`}
              value={value}
              data-testid={`combo-test-option`}
            >
              {label || value}
            </option>
          );
        })}
      </LabeledSelectFormControl>
    );
  },
);

ComboComponent.displayName = "ComboComponent";

ComboComponent.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  dataSource: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  rootProps: PropTypes.any,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  context: PropTypes.any,
};

ComboComponent.defaultProps = {
  onChange: shimFunctions.shimFunction0,
  availableValues: [],
  variant: "floating",
};

export default withBaseComponent(ComboComponent);
