import { Box, Button, Checkbox, Input } from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import DatagridFilter from "../../filter/filter";
import { SingleDatepicker } from "../../../../../../datepicker/single";
import { format } from "date-fns";
import { useState } from "react";
import { PropTypes } from "prop-types";

const CheckboxFilter = ({ onChange, isChecked, column }) => {
  const { align } = column;
  return (
    <Box display="flex" alignItems="center" justifyContent={align}>
      {isChecked === null ? (
        <Checkbox isChecked icon={<></>} onChange={onChange} />
      ) : (
        <Checkbox isChecked={isChecked} onChange={onChange} />
      )}
    </Box>
  );
};

CheckboxFilter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    editType: PropTypes.string,
    formatType: PropTypes.string,
    format: PropTypes.string,
    align: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isChecked: PropTypes.oneOf([null, 1, 0]),
};

const DateFilter = ({
  column,
  handleChangeColumnFilterValue,
  handleColumnFiltersOptionChange,
  hideFilter,
  columnFiltersState,
}) => {
  const handleFilterDate = (date, column) => {
    try {
      const resultView = new Date(date);
      const resultSend = format(resultView, "yyyy.MM.dd");
      handleChangeColumnFilterValue(resultSend, column.name);
    } catch (e) {
      handleChangeColumnFilterValue("", column.name);
      console.error(e);
    }
  };

  const resetFilterDate = (column) => {
    handleChangeColumnFilterValue("", column.name);
  };

  return (
    !hideFilter && (
      <Box display="flex" alignItems="center" width="100%" gap="2px">
        <SingleDatepicker
          name="filter-date-input"
          date={columnFiltersState[column.name]?.value || ""}
          onDateChange={(date) => handleFilterDate(date, column)}
          dateTimeFormat={column.format}
          style={{ margin: "0", width: "100%" }}
        />

        <DatagridFilter
          value={(columnFiltersState || {})[column.name]}
          onChange={(e) => {
            handleColumnFiltersOptionChange(e, column.name);
          }}
        />
        {columnFiltersState[column.name]?.value && (
          <Button
            type="button"
            onClick={() => resetFilterDate(column)}
            background="none"
            border="none"
            padding="0"
          >
            <BsTrash fontSize="18px" />
          </Button>
        )}
      </Box>
    )
  );
};

DateFilter.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    editType: PropTypes.string,
    formatType: PropTypes.string,
    format: PropTypes.string,
  }),
  handleChangeColumnFilterValue: PropTypes.func,
  handleColumnFiltersOptionChange: PropTypes.func,
  hideFilter: PropTypes.bool,
  columnFiltersState: PropTypes.object,
};

export const FilterCellInput = (props) => {
  const {
    type,
    column,
    columnFiltersState,
    handleChangeColumnFilterValue,
    handleColumnFiltersOptionChange,
    hideFilter,
  } = props;
  const [filterCheckboxState, setFilterCheckboxState] = useState(null);

  const handleFilterValue = (e, column) => {
    const value =
      column.formatType === "number"
        ? e.target.value.replaceAll(",", ".")
        : e.target.value;

    handleChangeColumnFilterValue(value, column.name);
  };

  const handleCheckboxFilter = (column) => {
    setFilterCheckboxState((prev) => {
      switch (prev) {
        case null:
          handleChangeColumnFilterValue(1, column.name);
          return 1;
        case 1:
          handleChangeColumnFilterValue(0, column.name);
          return 0;
        case 0:
        default:
          handleChangeColumnFilterValue(null, column.name);
          return null;
      }
    });
  };

  switch (type) {
    case "boolean":
      return (
        <CheckboxFilter
          column={column}
          onChange={() => handleCheckboxFilter(column)}
          isChecked={filterCheckboxState}
        />
      );
    case "datetime":
      return (
        <DateFilter
          hideFilter={hideFilter}
          column={column}
          columnFiltersState={columnFiltersState}
          handleChangeColumnFilterValue={handleChangeColumnFilterValue}
          handleColumnFiltersOptionChange={handleColumnFiltersOptionChange}
        />
      );
    default:
      return (
        !hideFilter && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="2px"
            width="100%"
          >
            <Input
              size="xs"
              value={(columnFiltersState || {})[column.name]?.value || ""}
              onChange={(e) => handleFilterValue(e, column)}
            />
            <DatagridFilter
              value={(columnFiltersState || {})[column.name]}
              onChange={(e) => {
                handleColumnFiltersOptionChange(e, column.name);
              }}
            />
          </Box>
        )
      );
  }
};

FilterCellInput.propTypes = {
  type: PropTypes.string,
  column: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    editType: PropTypes.string,
    formatType: PropTypes.string,
    format: PropTypes.string,
  }),
  columnFiltersState: PropTypes.object,
  handleChangeColumnFilterValue: PropTypes.func,
  handleColumnFiltersOptionChange: PropTypes.func,
  hideFilter: PropTypes.bool,
};
