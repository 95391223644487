import { showErrorToast } from "../../../../echo-error-toast";

const extractIdentifier = (element) => {
  switch (typeof element) {
    case "bigint":
    case "number":
    case "string":
      return element;
    case "object":
      if (Array.isArray(element) && element.length > 0) {
        return extractIdentifier(element[0]);
      } else {
        const key = Object.keys(element).find(
          (key) => key.toLowerCase() === "id"
        );
        return element[key];
      }
    default:
      return undefined;
  }
};

const storeTile = (tile, tileStorage) =>
  new Promise((resolve, reject) => {
    if (typeof tileStorage === "function") {
      Promise.resolve(tileStorage(tile)).then(resolve).catch(reject);
    } else {
      reject(new Error("Error. Tile storage process is not attached"));
    }
  });

const storeConnection = (connection, connectionStorage) =>
  new Promise((resolve, reject) => {
    if (typeof connectionStorage === "function") {
      Promise.resolve(connectionStorage(connection))
        .then(resolve)
        .catch(reject);
    } else {
      reject(new Error("Error. Connection storage process is not attached"));
    }
  });

export default (graphData, storages) =>
  new Promise((resolve, reject) => {
    Promise.all(
      graphData.elements.map((element) =>
        storeTile(element, storages.tileStorage)
      )
    )
      .then((elementResults) => {
        const identifierMappings = elementResults.map((result, index) => ({
          storageId: extractIdentifier(result),
          stateId: extractIdentifier(graphData.elements[index]),
        }));

        const mappedConnections = graphData.connections.map((connection) => ({
          ...connection,
          sourceId: identifierMappings.find(
            (m) => m.stateId === connection.sourceId
          )?.storageId,
          destinationId: identifierMappings.find(
            (m) => m.stateId === connection.destinationId
          )?.storageId,
        }));

        Promise.all(
          mappedConnections.map((connection) =>
            storeConnection(connection, storages.connectionStorage).catch(
              (err) => err
            )
          )
        )
          .then((connectionResults) => {
            const error = connectionResults.find((r) => r instanceof Error);
            if (error) {
              showErrorToast({
                reasonTitle: "Cannot save diagram data",
                location: "GraphDesigner - Save connection process",
                shortMessage: error?.message,
                message: error?.message,
              });
            } else {
              resolve({
                ...graphData,
                elements: elementResults,
                connections: connectionResults.filter(
                  (conn) => typeof conn === "object" && conn?.id
                ),
              });
            }
          })
          .catch((err) => {
            showErrorToast({
              reasonTitle: "Cannot save diagram data",
              location: "GraphDesigner - Save connection process",
              shortMessage: err?.message,
              message: err?.message,
            });
            reject(err);
            return;
          });
      })
      .catch((err) => {
        showErrorToast({
          reasonTitle: "Cannot save diagram data",
          location: "GraphDesigner - Save connection process",
          shortMessage: err?.message,
          message: err?.message,
        });
      });
  });
