import { useContext, useState } from "react";
import PropTypes from "prop-types";
import PropertiesValueInput from "../properties-table/cell-components/properties-table-input";
import { uuidv4 } from "@echo/tools";
import EchoRouterContext from "../../../echo-router/echo-router-context";
import { FiTrash2 } from "react-icons/fi";
import QuestionAlert from "../../echo-components/base-components/components/question-alert-component/question-alert.component";
import { echoPortal } from "../../../echo-portal/echo-portal";

const EchoInputStateValueCell = ({
  propName,
  propValue,
  onChange,
  diagramParams,
}) => {
  const { openPage, focusPage } = useContext(EchoRouterContext);

  const [openedPageKey, setOpenedPageKey] = useState(null);

  const handleOnInputChange = (newValue) => {
    if (
      propValue &&
      typeof propValue === "object" &&
      newValue === propValue.name
    ) {
      return;
    }
    onChange(newValue);
  };

  const getValue = () => {
    if (propValue && typeof propValue === "object") {
      return propValue.name && `Process: ${propValue.name}`;
    } else {
      return propValue;
    }
  };

  const handleOnSave = (process) => {
    onChange({ type: "PROCESS", name: process?.name, id: process?.id });
  };

  const handleOnClick = () => {
    if ((openedPageKey && !focusPage(openedPageKey)) || !openedPageKey) {
      setOpenedPageKey(
        openPage(
          propValue && propValue.id && propValue.type === "PROCESS"
            ? `diagramDesigner/${propValue.id}`
            : "diagramDesigner",
          {
            diagramParams,
            afterCreateEvent: {
              afterCreateEventName: "SAVE_CALLBACK",
              callbackEvent: handleOnSave,
              customParams: {
                name: `${propName}-${uuidv4()}`,
                description: `${propName} process.`,
                private: true,
              },
            },
          },
          `${propName}${propName ? " - " : ""}Diagram designer`,
          true,
        ),
      );
    }
  };

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlert
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: propValue ? "space-between" : "flex-end",
        flexDirection: "row",
        marginLeft: "8px",
      }}
    >
      <PropertiesValueInput
        value={getValue()}
        clearOnEdit={typeof propValue === "object"}
        onChange={handleOnInputChange}
      />
      <button onClick={handleOnClick} tabIndex={-1}>
        ...
      </button>
      <button onClick={handleOnDelete} tabIndex={-1}>
        <FiTrash2 size="16px" />{" "}
      </button>
    </div>
  );
};

EchoInputStateValueCell.propTypes = {
  propName: PropTypes.string,
  propValue: PropTypes.any,
  onChange: PropTypes.func,
  diagramParams: PropTypes.object,
};

EchoInputStateValueCell.defaultProps = {
  propValue: "",
};

export default EchoInputStateValueCell;
