import { executeBlock, getMetadata } from "../../services/process-service";
import { mapForwardedModels } from "../../utils/forwarded-models/forwarded-models";

const checkPropertyExists = (obj, property) => {
  const objProperties = Object.keys(obj);
  const propertyExists = objProperties.includes(property);
  return propertyExists;
};

const mapModelWithNewValue = (model, property, newValue) => {
  if (!property || !newValue || newValue === "" || !model) return model;

  if (
    typeof newValue === "object" &&
    checkPropertyExists(newValue, property) &&
    checkPropertyExists(model, property)
  ) {
    const propertyValue = newValue[property];
    return { ...model, [property]: propertyValue };
  }

  if (checkPropertyExists(model, property)) {
    return { ...model, [property]: newValue };
  }

  return model;
};

const getPageQueryParams = (uriQueryParams) => {
  const params = [];
  if (uriQueryParams) {
    for (let paramKey of Object.keys(uriQueryParams)) {
      params.push({ name: paramKey, value: uriQueryParams[paramKey] });
    }
  }
  return params;
};

export const getBackendBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const queryParamsArray = getPageQueryParams(
          componentContext?.page?.queryParams,
        );
        const activePage = componentContext?.routerContext?.getActivePage();
        const forwardedModelsParams = mapForwardedModels(activePage);
        const model =
          staticParams.__model || componentContext.state?.formSource;

        const mappedModel =
          systemParams && systemParams.name
            ? mapModelWithNewValue(model, systemParams.name, params[0])
            : model;

        executeBlock(block.id, {
          ...staticParams,
          __model: mappedModel,
          __props: componentContext.component?.componentProps,
          __relatedObjectId:
            staticParams.___relatedObjectId ||
            componentContext.component?.relatedObjectId,
          sessionData: {
            userId: parseFloat(
              componentContext.userContext.contextId ||
                componentContext.userContext.userId,
            ),
            userName: componentContext.userContext.userName,
            companyId: isNaN(componentContext.userContext.companyId)
              ? componentContext.userContext.companyId
              : Number(componentContext.userContext.companyId),
            dataObjectId:
              staticParams.___relatedObjectId ||
              componentContext.component?.relatedObjectId ||
              (isNaN(
                (queryParamsArray || []).find(
                  (p) => p.name.toLowerCase() === "objectid",
                )?.value,
              )
                ? null
                : Number(
                    (queryParamsArray || []).find(
                      (p) => p.name.toLowerCase() === "objectid",
                    )?.value,
                  )),
          },
          params: [
            ...(Array.isArray(params) && params ? params : []),
            ...queryParamsArray,
            ...(forwardedModelsParams || []),
            {
              name: "_ObjectId",
              value: componentContext.component?.relatedObjectId,
            },
            {
              name: "ComponentName",
              value: systemParams?.name,
            },
          ].map((p, index) => {
            return typeof p === "object" && !Array.isArray(p)
              ? p
              : {
                  name: `param${index}`,
                  value: p,
                };
          }),
        })
          .then(resolve)
          .catch(reject);
      }),
    getMetadata: (staticParams, params) =>
      getMetadata(block.id, { ...staticParams, params }),
  };
};
