import { Box, IconButton, useDisclosure } from "@chakra-ui/react";
import { LineGraph } from "@echo/graph-designer";
import PropTypes from "prop-types";
import { FiEdit } from "react-icons/fi";

const getLineProps = (from, to, height, width, offsetY) => {
  const correction = to.x === from.x ? 10 : 0;

  return {
    name: "simple",
    width,
    height,
    padding: [10, 10, 10, 10],
    lines: [
      {
        key: "line-series",
        data: [
          [0, -offsetY],
          [0 + correction, -35 - offsetY],
          [to.x - from.x - correction, from.y - to.y + 35 - offsetY],
          [to.x - from.x, from.y - to.y - offsetY],
        ],
        color: "#c03",
        smooth: true,
      },
    ],
  };
};

const calculateDimensions = (from, to) => {
  const correction = to.x === from.x ? 10 : 0;
  const width =
    Math.max(to.x, from.x) - Math.min(to.x, from.x) + 20 + correction;
  const heightDiff = Math.max(to.y, from.y) - Math.min(to.y, from.y);
  const offsetY = to.y - 35 <= from.y ? Math.abs(to.y - from.y - 35) : 0;
  const offsetX = to.x >= from.x ? 0 + correction / 2 : width - 20;

  const height = offsetY > 0 ? offsetY + 55 : heightDiff + 20;

  return {
    width,
    height,
    offsetY,
    offsetX,
  };
};

const LinkComponent = ({ from, to, editModalComponent, label }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dimensions = calculateDimensions(from, to);

  const lineProps = getLineProps(
    from,
    to,
    dimensions.height,
    dimensions.width,
    dimensions.offsetY
  );

  return (
    <>
      <Box
        position="absolute"
        top={`${from.y - dimensions.offsetY - 10}px`}
        left={`${from.x - dimensions.offsetX - 10}px`}
      >
        <LineGraph {...lineProps} />
        <Box
          position="absolute"
          width={`${dimensions.width}px`}
          height={`${dimensions.height}px`}
          top={0}
          left={0}
          display="flex"
          justifyContent="center"
        >
          <IconButton
            position="absolute"
            top={`${dimensions.height / 2 + 15}px`}
            className="disable-ppz-pan disable-ppz-drag"
            variant="ghost"
            size="16px"
            onClick={onOpen}
          >
            <FiEdit size="16px" />
          </IconButton>
          {label && (
            <Box
              position="absolute"
              top={`${dimensions.height / 2 - 35}px`}
              className="disable-ppz-drag"
            >
              ({label})
            </Box>
          )}
        </Box>
      </Box>
      {editModalComponent({ isOpen, onOpen, onClose })}
    </>
  );
};

LinkComponent.propTypes = {
  from: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  editModalComponent: PropTypes.func.isRequired,
  label: PropTypes.string,
};

LinkComponent.defaultProps = {};

export default LinkComponent;
