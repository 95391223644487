import React from "react";
import { DatePicker } from "../../datepicker/datepicker";

export const InputDateEditCell = (props) => {
  const { value, onChange, autoFocus, dateFormat } = props;
  return (
    <DatePicker
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      dateFormat={dateFormat || "dd-MM-yyyy"}
    />
  );
};
