import { Box, Button } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import LabeledSelectFormControl from "../labeled-select-form-control/labeled-select-form-control";
import UserContext from "../../../user-context";
import { getUserList } from "../../../services/user-services";
import { showErrorToast } from "../echo-error-toast";
import {
  deletePermission,
  getRoles,
} from "../../../services/permission-service";
import { toast } from "react-toastify";

const PermissionEditForm = ({
  properties,
  value,
  onChange,
  componentId,
  mapLocation,
  onDelete,
}) => {
  const [state, setState] = useState(value);
  const { companyId } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    getUserList("")
      .then(setUsers)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error. Cannot load available objects",
          location: "Backend. Available objects",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        })
      );
  }, []);

  useEffect(() => {
    getRoles(companyId)
      .then(setRoles)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error. Cannot load available objects",
          location: "Backend. Available objects",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        })
      );
  }, [companyId]);

  const handlePermissionChange = useCallback(
    (key, value) => {
      const newState = {
        ...(state || {}),
        [key]: key === "location" ? mapLocation(value) : value,
        companyId,
        itemId: componentId,
        modelId: 2070,
      };

      if (typeof onChange === "function") {
        onChange(newState);
      }
      setState(newState);
    },
    [companyId, componentId, mapLocation, onChange, state]
  );

  const handlePropertyMapChange = useCallback(
    (e) => handlePermissionChange("location", e.target.value),
    [handlePermissionChange]
  );

  const handlePermissionTypeChange = useCallback(
    (e) => handlePermissionChange("type", e.target.value),
    [handlePermissionChange]
  );

  const handleRoleIdChange = useCallback(
    (e) =>
      handlePermissionChange(
        "roleId",
        e.target.value === "" || isNaN(e.target.value)
          ? null
          : Number(e.target.value)
      ),
    [handlePermissionChange]
  );

  const handleUserIdChange = useCallback(
    (e) =>
      handlePermissionChange(
        "userId",
        e.target.value === "" || isNaN(e.target.value)
          ? null
          : Number(e.target.value)
      ),
    [handlePermissionChange]
  );

  const handleOnDelete = useCallback(() => {
    deletePermission(state._id)
      .then(() => {
        toast.success("Permission successfully deleted");
        if (typeof onDelete === "function") {
          onDelete();
        }
      })
      .catch(showErrorToast);
  }, [onDelete, state?._id]);

  return (
    <Box height="480px" boxSizing="border-box">
      <LabeledSelectFormControl
        value={
          state?.location && state?.location.includes("_")
            ? state?.location.split("_")[1]
            : state?.location
        }
        onChange={handlePropertyMapChange}
        label="Select property"
      >
        {" "}
        <option value={null} />
        {properties.map((prop) => (
          <option key={prop.name} value={prop.name}>
            {prop.name}
          </option>
        ))}
      </LabeledSelectFormControl>
      <LabeledSelectFormControl
        value={state?.type}
        onChange={handlePermissionTypeChange}
        label="Select type"
      >
        <option value={null} />
        <option value="Permitted">Permitted</option>
        <option value="Unpermitted">Unpermitted</option>
      </LabeledSelectFormControl>
      <LabeledSelectFormControl
        value={state?.roleId}
        onChange={handleRoleIdChange}
        label="Select role"
      >
        <option value={null} />
        {roles &&
          roles.map((role) => (
            <option key={`role-${role.id}`} value={role.id}>
              {role.name}
            </option>
          ))}
      </LabeledSelectFormControl>
      <LabeledSelectFormControl
        value={state?.userId}
        onChange={handleUserIdChange}
        label="Select user"
      >
        <option value={null} />
        {users &&
          users.map((user) => (
            <option key={`user-${user.id}`} value={user.id}>
              {user.login}
            </option>
          ))}
      </LabeledSelectFormControl>
      {state?._id && <Button onClick={handleOnDelete}>Delete</Button>}
    </Box>
  );
};

PermissionEditForm.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string.isRequired })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.shape({}),
  componentId: PropTypes.number,
  mapLocation: PropTypes.func,
  onDelete: PropTypes.func,
};

PermissionEditForm.defaultProps = { mapLocation: (loc) => loc };

export default PermissionEditForm;
