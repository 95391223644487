import { useContext, useEffect, useState } from "react";
import EchoRouterContext from "../echo-router-context";
import EchoRouterRouteContext from "../echo-router-route-context";

export const usePage = () => {
  const [state, setState] = useState();
  const { active } = useContext(EchoRouterRouteContext);
  const { getActivePage } = useContext(EchoRouterContext);

  useEffect(() => {
    if (active) {
      const page = getActivePage();
      setState(page);
    }
  }, [active, getActivePage]);

  return state || getActivePage();
};
