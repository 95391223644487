import { toast } from "react-toastify";

const getElements = (arr1, arr2) => {
  const diff = [];
  arr1.forEach((e1) => {
    const f = arr2.find((e2) => e1.name === e2.name);
    if (f) return;
    diff.push(e1);
  });
  return diff;
};

const getDifference = (arr1, arr2) => {
  const deleted = getElements(arr1, arr2);
  const added = getElements(arr2, arr1);
  return { added, deleted };
};

const mergeAdded = (arr, added) => {
  const mapped = added.map((el) => ({ ...el, isVisible: false }));
  return [...arr, ...mapped];
};
const mergeDeleted = (arr, deleted) => {
  const merged = [
    ...arr.filter((el1) => !deleted.find((el2) => el1.name === el2.name)),
  ];
  return merged;
};

export const mergeColumnArrays = (arr1, arr2) => {
  const { added, deleted } = getDifference(arr1, arr2);
  if (added.length === 0 && deleted.length === 0) return arr1;
  let res = arr1;

  if (deleted.length > 0) {
    toast.info(`Deleted columns: ${deleted.map((el) => el.name).join(", ")}`);
    const merged = mergeDeleted(res, deleted);
    res = merged;
  }

  if (added.length > 0) {
    toast.info(`Columns added: ${added.map((el) => el.name).join(", ")}`);
    const merged = mergeAdded(res, added);
    res = merged;
  }

  return res;
};

export const areArraysSame = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  return [...new Set([...arr1, ...arr2])].every(
    (v) =>
      arr1.filter((e) => e.name === v.name).length ===
      arr2.filter((e) => e.name === v.name).length,
  );
};
