import { useCallback, useState } from "react";

export const useSearch = () => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (val) => setSearchValue(val);

  const getFilteredArr = useCallback(
    (arr, property) => {
      if (!arr || arr.length === 0) return [];

      return arr.filter((i) =>
        i[property].toLowerCase().includes(searchValue.toLowerCase()),
      );
    },
    [searchValue],
  );

  return { searchValue, handleSearchValue, getFilteredArr };
};
