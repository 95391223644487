const getValueFromResult = (result, extractValueTypeFromObject) => {
  switch (typeof result) {
    case "object":
      if (Array.isArray(result) && result.length > 1) {
        return getValueFromResult(result[0]);
      } else {
        const resultKeys = Object.keys(result);
        if (extractValueTypeFromObject) {
          if (resultKeys.length > 1) {
            return result[resultKeys[0]];
          } else {
            const res = Object.values(result[0])[0];
            if (res) return res;
            else return null;
          }
        } else return result;
      }
    default:
      return result;
  }
};

export default (prop, extractValueTypeFromObject) =>
  new Promise((resolve, reject) => {
    switch (typeof prop) {
      case "function":
        Promise.resolve(prop())
          .then((result) =>
            resolve(getValueFromResult(result, extractValueTypeFromObject)),
          )
          .catch(reject);
        break;
      default:
        resolve(prop);
        break;
    }
  });
