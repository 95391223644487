import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import { Box } from "@chakra-ui/react";
import { useResolveProp } from "../../../../../hooks/resolve-prop/use-resolve-prop";
import { InputNumeric } from "@echo/ui";

const InputNumericComponent = (props) => {
  const {
    id,
    name,
    value,
    onChange,
    format = "# ##0,00",
    step = 1,
    min,
    max,
    placeholder,
    label,
    variant,
    isRequired,
    isInvalid,
    isDisabled,
    isReadOnly,
    style,
    nodeRef,
    rootProps,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const { style: unused, ...restRootProps } = rootProps;

  const inputValue = useResolveProp(value, true);
  const isRequiredState = useResolveProp(isRequired, true);
  const isInvalidState = useResolveProp(isInvalid, true);
  const isDisabledState = useResolveProp(isDisabled, true);
  const isReadonlyState = useResolveProp(isReadOnly, true);

  const handleChange = (value) => {
    if (onChange) onChange(value);
  };

  return (
    <Box ref={nodeRef} {...restRootProps} {...style}>
      <InputNumeric
        ref={nodeRef}
        id={id}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        format={format}
        min={min}
        max={max}
        step={step}
        label={label}
        variant={variant}
        isRequired={isRequiredState}
        isInvalid={isInvalidState}
        isDisabled={isDisabledState}
        isReadOnly={isReadonlyState}
        style={style}
      />
    </Box>
  );
};

InputNumericComponent.displayName = "InputNumericComponent";

InputNumericComponent.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  format: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  variant: PropTypes.oneOf(["filled", "outlined", "floating"]),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  nodeRef: PropTypes.any,
  colorScheme: PropTypes.string,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  size: PropTypes.string,
  rootProps: PropTypes.any,
  disabled: PropTypes.bool,
  parse: PropTypes.func,
  style: PropTypes.object,
};

InputNumericComponent.defaultProps = {
  defaultValue: 0.0,
  variant: "floating",
  placeholder: " ",
  label: "",
  mask: "^[0-9]+$",
  required: false,
  disabled: false,
};

export default withBaseComponent(InputNumericComponent);
