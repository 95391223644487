import { toast } from "react-toastify";

const setFieldsInvalid = (component, fields, ctx) => {
  const { childrenComponents } = component;

  fields.forEach((field) => {
    if (childrenComponents && childrenComponents.length > 0) {
      childrenComponents.forEach((child) =>
        setFieldsInvalid(child, fields, ctx),
      );
    }

    if (component.componentProps.name === field.componentProps.name) {
      component.componentProps.isInvalid = true;
    }
  });
};

const updateComponent = (component, fields, ctx) => {
  const {
    functions: { updateComponentDefinition, reload },
  } = ctx;
  setFieldsInvalid(component, fields);
  updateComponentDefinition(component);
  reload();
};

const hasValue = (value) => {
  switch (value) {
    case false:
    case "":
    case null:
    case undefined:
      return false;
    default:
      return true;
  }
};

const getInvalidFields = (fields, state) =>
  fields
    ?.map((field) => {
      const {
        componentProps: { name },
      } = field;
      if (!hasValue(state[name])) return field;
      return null;
    })
    ?.filter((el) => el);

const getRequiredFields = (component) => {
  const { childrenComponents, componentProps } = component;

  if (childrenComponents && childrenComponents.length > 0) {
    return childrenComponents
      .map(getRequiredFields)
      .flat(Infinity)
      .filter((el) => el);
  }

  if (componentProps.isRequired) {
    return component;
  }

  return null;
};

const showError = (field) => {
  const { componentProps } = field;
  const { label, name } = componentProps;
  toast.error(`Pole ${label ?? name} jest wymagane`);
};

export const validateFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) => {
      const { component } = componentContext;
      const { __model: state } = staticParams;
      const requiredFields = getRequiredFields(component);
      const invalidFields = getInvalidFields(requiredFields, state);

      if (invalidFields.length > 0) {
        invalidFields.forEach(showError);
        updateComponent(component, invalidFields, componentContext);
        return { __resultCondition: "false" };
      }

      return { ...staticParams, __resultCondition: "true" };
    },
  };
};
