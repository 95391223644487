import {
  Box,
  Button,
  Input,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Textarea,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { MdOutlineMoreVert } from "react-icons/md";

export const InputEditComponent = ({ value, inputType, onChange }) => {
  // const [inputType, setInputType] = useState("input");
  // const switchInput = () => setInputType("input");
  // const switchTextarea = () => setInputType("textarea");

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  if (inputType === "input")
    return <Input value={value} onChange={handleChange} />;

  if (inputType === "textarea")
    return <Textarea value={value} onChange={handleChange} />;
};
InputEditComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
