import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  IconButton,
} from "@chakra-ui/react";

const KvpTable = ({ caption, data }) => {
  const getValue = useCallback((value) => {
    if (typeof value === "undefined") {
      return <i>undefined</i>;
    } else if (value === null) {
      return <i>null</i>;
    } else {
      return value;
    }
  }, []);

  return (
    <TableContainer>
      <Table size="sm" variant="simple">
        <TableCaption>{caption}</TableCaption>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Value</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((kvp) => (
            <Tr key={kvp.name}>
              <Td>{kvp.name}</Td>
              <Td width="100%">{getValue(kvp.value)}</Td>
              {kvp.actions && (
                <Td textAlign="right">
                  {kvp.actions.map((action) => (
                    <IconButton
                      key={action.name}
                      colorScheme="red"
                      icon={action.icon}
                      onClick={action.action}
                    />
                  ))}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

KvpTable.propTypes = {
  caption: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          icon: PropTypes.node.isRequired,
          action: PropTypes.func.isRequired,
        }),
      ),
    }),
  ),
  onEdit: PropTypes.func,
};

KvpTable.defaultProps = {
  caption: "kvp-table",
  data: [],
};

export default KvpTable;
