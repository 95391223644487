const { getFormComponentProps } = require("./get-form-component-props");
const { getItemByDefinition } = require("./get-item-by-definition");

const equalOrIncludes = (str1, str2) => str1 === str2 || str1.includes(str2);

const getComponentTypeBySqlType = (sqlType) => {
  if (equalOrIncludes(sqlType, "varchar")) return "input";

  if (equalOrIncludes(sqlType, "decimal")) return "numeric";
  if (sqlType === "tinyint") return "numeric";
  if (sqlType === "int") return "numeric";

  if (equalOrIncludes(sqlType, "date")) return "date";
  if (sqlType === "bit") return "checkbox";
};

const filter = (items, condition) =>
  items.filter((i) => i.type?.toLowerCase() === condition);

export const getComponentsByType = (items) => {
  const components = filter(items, "component");
  const containers = filter(items, "container");
  const templates = filter(items, "template");
  return { components, containers, templates };
};

const duplicate = (array, types) => {
  const newArr = array?.reduce((acc, curr) => {
    if (curr?.name?.toLowerCase() === "id") return [...acc, curr];

    const keys = Object.keys(types);
    if (keys.length > 0 && keys.includes(curr.type)) {
      const k = keys.find((k) => k === curr.type);

      if (curr.componentType === types[k]) return [...acc, curr];

      return [
        ...acc,
        {
          ...curr,
          displayName: `${curr.name} (${curr.componentType || getComponentTypeBySqlType(curr.type)})`,
        },
        {
          ...curr,
          componentType: types[k],
          displayName: `${curr.name} (${types[k]})`,
        },
      ];
    }

    return [...acc, curr];
  }, []);

  return newArr;
};

export const getDataComponents = (components, items) => {
  // types object contains key: value pairs where keys are current dataTypes for specific components,
  // and values for these keys are types of component that it should have duplicated
  // example: element with dataType int will now have one default input numeric component and one combo component available
  const types = { int: "combo", tinyint: "combo" };

  const withDuplicates = duplicate(components, types);
  return withDuplicates
    ?.filter((c) => c.name)
    ?.map((c) => ({
      ...getItemByDefinition(items, c),
      displayName: c.displayName || c.name,
      defaultProps: getFormComponentProps(c),
    }));
};
