import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useAsInternalState } from "../../../hooks/shared/use-as-internal-state";
import ComponentPropertiesDesigner from "./component-properties-designer";

const ComponentPropertiesDesignerModal = ({
  open,
  onClose,
  onSubmit,
  initialSchemaObject,
}) => {
  const [schemaObject, setSchemaObject] = useAsInternalState(
    initialSchemaObject,
    true,
    []
  );

  const handleClose = (...args) => {
    if (onClose) {
      Promise.all([onClose(...args), setSchemaObject(initialSchemaObject)]);
    }
  };

  const handleSubmit = (...args) => {
    if (onSubmit && onClose) {
      Promise.all([onSubmit(schemaObject, ...args), onClose()]);
    }
  };

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent width="80vw" maxW="80vw" height="80vh" maxH="80vh">
        <ModalHeader>Edit component properties</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ComponentPropertiesDesigner
            schema={schemaObject}
            onSchemaChange={setSchemaObject}
          />
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ComponentPropertiesDesignerModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initialSchemaObject: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      propType: PropTypes.string.isRequired,
      propName: PropTypes.string.isRequired,
    })
  ),
};

ComponentPropertiesDesignerModal.defaultProps = {};

export default ComponentPropertiesDesignerModal;
