import { uuidv4 } from "@echo/tools";
import { saveLog } from "../services/log-service";

export const ERROR_SEVERITY = {
  ERROR: 1,
  INFO: 2,
  DEBUG: 3,
};

export const LOG_LOCATION = {
  BACKEND: 1,
  FRONTEND: 2,
};

/**
 * @callback LogFunc
 * @param {string} source Source
 * @param {string} message Log message
 * @param {Object} metadata Metadata. Eg. userAgent
 * @param {number} identity Identity of related entity
 * @param {string} startDate Start date
 * @param {string} endDate End date
 */

/**
 * @callback GeneralLogFunc
 * @param {string} source Source
 * @param {string} message Log message
 * @param {Object} metadata Metadata. Eg. userAgent
 * @param {keyof ERROR_SEVERITY} Severity
 * @param {keyof LOG_LOCATION} Location
 * @param {number} identity Identity of related entity
 * @param {string} startDate Start date
 * @param {string} endDate End date
 */

/**
 * @typedef {Object} Logger
 * @property {GeneralLogFunc} log
 * @property {LogFunc} logError
 * @property {LogFunc} logInfo
 * @property {LogFunc} logDebug
 */

const log = async (
  source,
  message,
  metadata,
  severity,
  identity = null,
  startDate = null,
  endDate = null,
) => {
  const correlationId = uuidv4();
  const logMsg = {
    source,
    message,
    metadata: JSON.stringify(metadata),
    severity,
    correlationId,
    location: LOG_LOCATION.FRONTEND,
    identity,
    startDate,
    endDate,
  };

  try {
    await saveLog(logMsg);
  } catch (e) {
    console.error("Cannot save log message. Please check error below");
    console.error(e);
  }

  return correlationId;
};

const logError = async (
  source,
  message,
  metadata,
  identity,
  startDate = null,
  endDate = null,
) =>
  await log(
    source,
    message,
    metadata,
    ERROR_SEVERITY.ERROR,
    identity,
    startDate,
    endDate,
  );

const logInfo = async (
  source,
  message,
  metadata,
  identity,
  startDate = null,
  endDate = null,
) =>
  await log(
    source,
    message,
    metadata,
    ERROR_SEVERITY.INFO,
    identity,
    startDate,
    endDate,
  );

const logDebug = async (
  source,
  message,
  metadata,
  identity,
  startDate = null,
  endDate = null,
) =>
  await log(
    source,
    message,
    metadata,
    ERROR_SEVERITY.DEBUG,
    identity,
    startDate,
    endDate,
  );

/**
 * Create logger
 * @returns {Logger} Returns logger object
 */
export const createLogger = () => ({
  log,
  logError,
  logInfo,
  logDebug,
});
