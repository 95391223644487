import { getGroupSize } from "./split-format";
import { splitBySize } from "./split-value";

const getResultValue = (valueArr, formatArr, loopLength, separator) => {
  let result = [];
  for (let i = 0; i < loopLength; i++) {
    const current = valueArr[i];
    const next = valueArr[i + 1];
    const isFormatPlace = formatArr[i] === "0" || formatArr[i] === "#";

    if (separator && current === separator && !isFormatPlace) {
      result.unshift(current);
    }

    if (formatArr[i] === "0") {
      if (current) result.unshift(current);
      if (!current) result.unshift("0");
    }

    if (formatArr[i] === "#") {
      if ((current == "0" && next) || parseInt(current) > 0) {
        result.unshift(current);
      }
    }

    if (!formatArr[i]) {
      if (formatArr[formatArr.length - 1] === "#") {
        if ((current == "0" && next) || parseInt(current) > 0) {
          result.unshift(current);
        }
      }
    }
  }

  return result.join("");
};

const toReversedArray = (toReverse) => {
  let result = [...toReverse];
  result.reverse();
  return result;
};

const getValueWithoutSeparator = (value, format) => {
  const reversed = toReversedArray(value.split(""));
  const reversedFormat = toReversedArray(format.split(""));

  const loopLength =
    reversed.length > reversedFormat.length
      ? reversed.length
      : reversedFormat.length;

  const result = getResultValue(reversed, reversedFormat, loopLength);
  return result;
};

const getValueWithSeparator = (value, format, separator) => {
  const groupSize = getGroupSize(format, separator);
  const splitted = splitBySize(value, groupSize, separator);

  const reversed = toReversedArray(splitted.split(""));
  const reversedFormat = toReversedArray(format.split(""));

  const loopLength =
    reversed.length > reversedFormat.length
      ? reversed.length
      : reversedFormat.length;

  const result = getResultValue(
    reversed,
    reversedFormat,
    loopLength,
    separator,
  );
  return result;
};

export const formatIntegers = (value, format, separator) => {
  if (!separator) {
    const result = getValueWithoutSeparator(value, format);
    return result;
  }

  const result = getValueWithSeparator(value, format, separator);
  return result;
};
