import { showErrorToast } from "../../components/shared/echo-error-toast";
import { validateParamExists } from "./utils/validate-param";

const validateParams = (staticParams) => {
  const errorMessages = [];
  errorMessages.push(
    validateParamExists(staticParams.componentName, "componentName", true),
  );

  return errorMessages.filter((m) => m);
};

const replaceProperty = (componentDefinition, componentName, propertyName) => {
  const componentDefinitionClone = { ...componentDefinition };

  if (componentDefinitionClone?.componentProps?.name === componentName) {
    componentDefinitionClone.componentProps[propertyName] = {
      ...componentDefinitionClone.componentProps[propertyName],
    };
    return componentDefinitionClone;
  }
  if (
    componentDefinitionClone.childrenComponents &&
    Array.isArray(componentDefinitionClone.childrenComponents) &&
    componentDefinitionClone.childrenComponents.length > 0
  ) {
    for (let component of componentDefinitionClone.childrenComponents) {
      // check if there is child in echo-defined component used on another component that needs refresh
      if (component.component.childrenComponents) {
        for (let childrenComponent of component.component.childrenComponents) {
          const result = replaceProperty(
            childrenComponent,
            componentName,
            propertyName,
          );
          if (result !== null) {
            return componentDefinitionClone;
          }
        }
      }
      const result = replaceProperty(component, componentName, propertyName);
      if (result !== null) {
        return componentDefinitionClone;
      }
    }
  }

  // check if there is component in the parent which called openUrl
  if (componentDefinitionClone.callbackEvents) {
    const key = Object.keys(componentDefinitionClone.callbackEvents)[0];
    const parentComponent = {
      ...componentDefinitionClone.callbackEvents[key].source.context.component,
    };

    const children = parentComponent.childrenComponents;
    if (children && Array.isArray(children) && children.length > 0) {
      for (let component of children) {
        const result = replaceProperty(component, componentName, propertyName);
        if (result !== null) {
          return componentDefinitionClone;
        }
      }
    }
  }

  return null;
};

export const getRefreshDatagridBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve, reject) => {
        const { componentName } = staticParams;
        const errors = validateParams(staticParams);
        if (errors && Array.isArray(errors) && errors.length > 0) {
          const errorMessage = errors.join(", ");
          showErrorToast({
            reasonTitle: "Cannot change component prop.",
            location: "changeComponentProp",
            shortMessage: " Please fill all required block parameters.",
            message: errorMessage,
          });
          reject(new Error(errorMessage));
        }

        const resultComponentDefinition = replaceProperty(
          componentContext.component,
          componentName,
          "dataSource",
        );

        if (!resultComponentDefinition) {
          showErrorToast({
            reasonTitle: "Refresh datagrid error.",
            location: "Location: refreshDatagrid block",
            message: `Couldn't find component ${componentName}.`,
          });
          return;
        }

        componentContext.functions.updateComponentDefinition(
          resultComponentDefinition,
        );

        resolve(true);
      }),
  };
};
