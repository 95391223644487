import { executeBlock } from "../../../../../../process-executor/process-executor";

export const getDefinition = ({ dataSource, numeratorDefinitionId, context }) =>
  executeBlock(
    context,
    dataSource.id,
    {
      ...dataSource.staticParams,
      queryParams: [{ name: "DefinitionId", value: numeratorDefinitionId }],
    },
    [],
    {
      callerId: context?.component?.id,
      callerGuid: context?.component?.guid,
      callerNodeId: context?.component?.nodeId,
    },
  );
