import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Box, Button, Input, List, ListItem, Text } from "@chakra-ui/react";
import PropertiesTable from "../properties-table/properties-table";
import EchoInputStateValueCell from "../component-properties-table/echo-input-state-value-cell";
import EchoBoolValueCell from "../properties-table/value-cell/echo-bool-value-cell";
import EchoStateValueCell from "../component-properties-table/echo-state-value-cell";
import PermissionEditorEditCell from "../../permission-editor/permission-editor-edit-cell";
import EchoComboValueCell from "../properties-table/value-cell/echo-combo-value-cell";
import { toast } from "react-toastify";

/**
 * Component for edit arrayOf property type
 */
const ArrayOfModalEditor = ({
  isLoading,
  open,
  onClose,
  value,
  onSave,
  title,
  actions,
  containerRef,
  propTypes,
  componentId,
  propertiesSearch,
}) => {
  const [state, setState] = useState([]);
  const [activeElement, setActiveElement] = useState();

  useEffect(() => {
    if (Array.isArray(value) && value !== null) {
      Promise.all([setState(value), setActiveElement(null)]);
    } else {
      Promise.all([setState([]), setActiveElement(null)]);
    }
  }, [value]);

  const customTypes = [
    {
      name: "processSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "inputSelect",
      component: ({ propValue, onChange, propName }) => (
        <EchoInputStateValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "boolSelect",
      component: ({ propName, propValue, onChange }) => (
        <EchoBoolValueCell
          propName={propName}
          propValue={propValue}
          onChange={onChange}
        />
      ),
    },
    {
      name: "comboSelect",
      component: ({ propValue, onChange, options, propType }) => {
        return (
          <EchoComboValueCell
            propValue={propValue}
            propType={propType}
            onChange={(selection) => onChange(selection || null)}
            options={options}
            enableProcessSelect
          />
        );
      },
    },
    {
      name: "permissionEditorPropSelect",
      component: ({ propValue, onChange, model }) => (
        <PermissionEditorEditCell
          propValue={propValue}
          propTypes={[{ propName: "isVisible" }]}
          onChange={onChange}
          designerPortalRef={containerRef}
          componentId={componentId}
          model={model}
        />
      ),
    },
  ];

  const handleOnClose = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  const handleOnClickSave = (state, e) => {
    let newState = [...state];
    if (activeElement) {
      newState = newState.map((el) =>
        el.name === activeElement.element.name ? activeElement.element : el,
      );
      // const stateWithUpdatedElement =
      // newState[activeElement.index] = activeElement.element;
    }
    Promise.all([
      onSave(newState),
      handleOnClose(e),
      setState([]),
      setActiveElement(null),
      toast.success("Successfully saved"),
    ]);
  };

  const handleOnClick = (element, index) => {
    if (!activeElement) {
      setActiveElement({ index, element });
    } else {
      const newState = [...state];

      const stateWithUpdatedElement = newState.map((el) =>
        el.name === activeElement.element.name ? activeElement.element : el,
      );

      Promise.all([
        setState(stateWithUpdatedElement),
        setActiveElement({ index, element }),
      ]);
    }
  };

  const handleModalClose = (e) => {
    handleOnClose(e);
    setActiveElement(null);
  };

  const [searchValue, setSearchValue] = useState("");

  const filteredState = useMemo(() => {
    if (!propertiesSearch) return state;
    if (!searchValue || searchValue === "") return state;
    return state.filter((el) =>
      el.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [propertiesSearch, searchValue, state]);

  return (
    <Modal
      isOpen={open}
      isCentered
      portalProps={{ containerRef }}
      onClose={handleModalClose}
    >
      <ModalOverlay />
      <ModalContent margin={0} width="90%" maxW="90%" height="90%" maxH="90%">
        <ModalHeader display={"flex"} flexDirection={"row"} gap={"50px"}>
          <Text>{title}</Text>
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            minHeight: 0,
          }}
        >
          {propertiesSearch && (
            <Input
              placeholder="Search table..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              width="fit-content"
              padding="10px 10px"
            />
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: 0,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="10px"
              width="30%"
            >
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <List
                  border="1px solid black"
                  height="100%"
                  width="100%"
                  variant={"standard"}
                  overflowY={"auto"}
                >
                  {filteredState.map((element, index) => (
                    <ListItem
                      key={`row-cell-${index}`}
                      onClick={() => handleOnClick(element, index)}
                      style={{
                        padding: "1px 8px",
                        cursor: "pointer",
                        borderBottom: "1px solid black",
                        fontWeight:
                          activeElement?.element?.name === element?.name
                            ? "bolder"
                            : undefined,
                      }}
                    >
                      {element.name}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
            <Box style={{ width: "70%" }}>
              {activeElement?.element && (
                <PropertiesTable
                  options={propTypes}
                  model={activeElement.element}
                  onModelChange={(newColumnDef) =>
                    setActiveElement({
                      ...activeElement,
                      element: newColumnDef,
                    })
                  }
                  customTypes={customTypes}
                />
              )}
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          {actions?.map((action) => (
            <Button
              key={action.name}
              mr={3}
              {...action.buttonProps}
              onClick={(e) => {
                const newState = [...state];
                if (activeElement) {
                  newState[activeElement.index] = activeElement.element;
                }
                action.action ? action.action(newState, e) : undefined;
              }}
            >
              {action.name}
            </Button>
          )) || null}
          <Button
            colorScheme="blue"
            mr={3}
            onClick={(e) => handleOnClickSave(state, e)}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ArrayOfModalEditor.defaultProps = {
  title: "Configurator",
};

ArrayOfModalEditor.propTypes = {
  isLoading: PropTypes.bool,
  /**
   * Array value
   */
  value: PropTypes.array.isRequired,
  /**
   * Function to handle array changes
   */
  onSave: PropTypes.func,
  /**
   * Modal visibility state
   */
  open: PropTypes.any,
  /**
   * On modal close callback
   */
  onClose: PropTypes.func,

  /**
   * Title
   */
  title: PropTypes.string,

  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
      buttonProps: PropTypes.object,
    }),
  ),
  containerRef: PropTypes.object,
  componentId: PropTypes.number,

  propTypes: PropTypes.any,
  updateModel: PropTypes.node,
  propertiesSearch: PropTypes.bool,
};

export default ArrayOfModalEditor;
