import { List } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { ColumnsListItem } from "./columns-list-item";

export const ColumnsList = (props) => {
  const { columns, deleteColumn, onColumnChange } = props;

  return (
    <List
      width="100%"
      display="flex"
      overflowX="scroll"
      padding="10px 0"
      gap="10px"
    >
      {columns.map((c, idx) => (
        <ColumnsListItem
          {...c}
          onChange={(col) => onColumnChange(col, idx)}
          deleteColumn={(col) => deleteColumn(col, idx)}
          key={idx}
        />
      ))}
    </List>
  );
};

ColumnsList.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
  deleteColumn: PropTypes.func,
};
