import { useEffect } from 'react';
import { showErrorToast } from '../../../../../echo-error-toast';

export const useDataGridEvents = (props, state) => {
  const component = props?.context?.component;

  useEffect(() => {
    const saveData = (event) => {
      const { root } = event.detail || {};
      if (
        (root?.id,
        root?.guid,
        root?.nodeId,
        root?.id === component?.id &&
          root?.guid === component?.guid &&
          root?.nodeId === component?.nodeId &&
          props.onSave &&
          typeof props.onSave === 'function')
      ) {
        props.onSave(state.data).catch(showErrorToast);
      }
    };

    window.addEventListener('save-form', saveData);

    return () => {
      window.removeEventListener('save-form', saveData);
    };
  }, [props, state]);
};
