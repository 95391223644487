import { arrayEqual } from "@echo/tools";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getColumnMeta } from "../../lib/columns/column-meta";
import UserContext from "../../../../../../../../user-context";

const checkDependenciesChange = (actualDeps, incomingDeps) => {
  const equalityCheck = arrayEqual(actualDeps, incomingDeps || []);

  if (typeof equalityCheck !== "boolean") {
    window.console.warn(
      "Equality function passed to 'useCustomEffect' hook should always return value of type 'boolean'",
    );
  }

  return typeof equalityCheck === "boolean" && !equalityCheck;
};

/**
 * Use datagrid columns
 * @param {array} sourceObject Raw data
 * @param {string} ownerId Owner identifier
 * @param {string} key Variant key for owner
 * @param {function} settingSource Hook options
 * @param {object} options Hook options
 * @param {array} deps Dependencies array
 * @return {array}
 */
export const useColumns = (
  sourceObject,
  ownerId,
  queryId,
  key,
  settingSource,
  settingStore,
  options,
  isActive,
  deps,
  handleSortObject,
) => {
  const { userId, contextId } = useContext(UserContext);

  const settingKey = useMemo(() => {
    return `DGC_${ownerId}_${key || "%"}_QUERYID_${queryId || "%"}`;
  }, [queryId, ownerId, key]);

  const hookRef = useRef({ snapshotHash: 0, deps });
  const [columns, setColumns] = useState([]);
  const [settingId, setSettingId] = useState();

  const depsChanged = checkDependenciesChange(
    deps,
    hookRef?.current?.deps || [],
  );

  if (depsChanged) {
    hookRef.current = {
      snapshotHash: (hookRef.current.snapshotHash || 0) + 1,
      deps,
    };
  } else {
    hookRef.current = { snapshotHash: hookRef.current.snapshotHash || 0, deps };
  }

  const handleColumns = useCallback(
    async (columnData, settingKey, user, role, type) => {
      const res = await settingStore(
        settingId || null,
        settingKey,
        columnData,
        user,
        role,
        type,
      );
      if (res.userId == contextId || res.userId == userId)
        setColumns(columnData);
    },
    [contextId, settingId, settingStore, userId],
  );

  const storeColumns = useCallback(
    (columnData, user, role, type) => {
      Promise.resolve(handleColumns(columnData, settingKey, user, role, type));
    },
    [handleColumns, settingKey],
  );

  const getSortObject = (columns) => {
    let sortObj = {};
    const visibleColumns = columns?.filter(
      (col) => col.isVisible !== false && col.name !== "__rowColor",
    );
    const sortedColumns = visibleColumns
      ?.filter((c) => c.sort)
      .sort((a, b) => a.sortOrder - b.sortOrder);

    sortedColumns.forEach((col) => {
      sortObj = { ...sortObj, [col.name]: col.sort };
    });

    return sortObj;
  };

  const getColumns = async () => {
    try {
      const userColumns = await settingSource(settingKey);
      const storedColumns = userColumns.value
        ? JSON.parse(userColumns.value)
        : null;
      if (storedColumns && Array.isArray(storedColumns)) {
        const sortObject = getSortObject(storedColumns);
        if (sortObject) handleSortObject(sortObject);
        setColumns(storedColumns);
        setSettingId(userColumns.id);
      }
    } catch {
      const defaultColumns = getColumnMeta(sourceObject);
      if (defaultColumns) {
        const { specification } = defaultColumns;
        if (specification && specification.length > 0) {
          handleSortObject({});
          setColumns(defaultColumns.specification);
          setSettingId(null);
        }
      }
    }
  };

  useEffect(
    () => {
      if (isActive) {
        getColumns();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hookRef.current.snapshotHash, sourceObject?.data?.length, isActive],
  );

  return [columns, storeColumns, settingKey];
};
