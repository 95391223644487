import { executeBlock } from "../../process-executor/process-executor";
import { mapForwardedModels } from "../../utils/forwarded-models/forwarded-models";

const resolvePath = (name, prop, basePath, useRootContext) => {
  let path = prop.name.replace("<name>", name);
  // TODO temporary solution - fix it

  path = path.replace("formSource.", "");

  const result = !useRootContext && basePath ? `${basePath}.${path}` : path;

  return !basePath && prop.name.includes("formSource.")
    ? `formSource.${result}`
    : result;
};

export const calculateProp = (
  prop,
  componentContext,
  props,
  callerId,
  callerGuid,
  callerNodeId,
  basePath,
  localContext,
) => {
  if (prop && prop.type) {
    switch (prop.type) {
      case "STATE_PROP": {
        const name = props["name"];
        if (
          prop.name.startsWith("@") ||
          (prop.name.includes("<name>") && name.startsWith("@"))
        ) {
          const activePage = componentContext?.routerContext?.getActivePage();
          const forwardedModelsParams = mapForwardedModels(activePage);
          if (
            activePage?.queryParams &&
            typeof activePage.queryParams === "object" &&
            !Array.isArray(activePage.queryParams)
          ) {
            const key = Object.keys(activePage.queryParams).find(
              (p) => p.toLowerCase() === prop.name.substring(1).toLowerCase(),
            );
            if (key) {
              return activePage.queryParams[key];
            }
          }
          return forwardedModelsParams
            ?.filter((p) => p?.name)
            ?.find((p) =>
              p.name.toLowerCase() === prop.name.startsWith("@")
                ? prop.name.substring(1).toLowerCase()
                : name.substring(1).toLowerCase(),
            )?.value;
        } else {
          const path = resolvePath(
            name,
            prop,
            basePath,
            !!props?.useRootContext,
          );
          return componentContext.functions.getStateProp(path);
        }
      }
      case "PROCESS": {
        const processFunc = (...params) =>
          executeBlock(
            prop.context || componentContext,
            prop.id,
            prop.staticParams ?? {
              queryParams:
                typeof localContext === "object" && !Array.isArray(localContext)
                  ? Object.keys(localContext).map((key) => ({
                      name: `local_${key}`,
                      value: localContext[key],
                    }))
                  : localContext,
            },
            params,
            {
              name: props.name,
              source: prop.source,
              callerId,
              callerGuid,
              callerNodeId,
            },
          );
        processFunc.processId = prop.id;
        return processFunc;
      }
      default:
        return prop;
    }
  }
  return prop;
};

export const calculateProps = (
  componentProps,
  componentContext,
  callerId,
  callerGuid,
  callerNodeId,
  path,
  localContext,
) => {
  if (!componentContext) {
    return componentProps;
  }
  const result = { ...componentProps };
  for (let key in componentProps) {
    if (key === "dataSource") {
      continue;
    }
    const prop = componentProps[key];
    result[key] = calculateProp(
      prop,
      componentContext,
      componentProps,
      callerId,
      callerGuid,
      callerNodeId,
      path,
      localContext,
    );
  }
  return { ...result, ...localContext };
};
