import { executeBlock } from "../../../../../../../process-executor/process-executor";
import { format } from "date-fns";

const getDateValue = (val) => {
  try {
    const date = new Date(val);
    const formatted = format(date, "yyyy.MM.dd");
    return formatted;
  } catch {
    return val;
  }
};

const getValue = (value, type) => {
  if (!value || value === "") {
    if (type === "number") return "0";
    return "";
  }

  if (type && type.includes("date")) {
    return getDateValue(value);
  }

  return value;
};

const getFilters = (filtersObj) => {
  const keys = Object.keys(filtersObj);

  if (keys.length < 1) return {};

  return keys.reduce((acc, curr) => {
    const { type, value: filterValue, dataType } = filtersObj[curr];
    const value = getValue(filterValue, dataType);
    return { ...acc, [curr]: { value, [type]: true } };
  }, {});
};

const getSort = (sortArr) => {
  if (sortArr.length < 1) return {};

  const sortObj = sortArr.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.value,
    }),
    {},
  );

  return sortObj;
};

const convertDatabaseType = (type) => {
  switch (type?.toLowerCase()) {
    case "bit":
      return "boolean";
    case "date":
    case "datetime":
      return "datetime";
    case "tinyint":
    case "int":
    case "numeric":
    case "decimal":
      return "number";
    default:
      return "string";
  }
};

const getColumnsFromObj = (obj) =>
  Object.keys(obj)
    .filter((col) => col !== "RecCount")
    .map((c, idx) => ({
      name: c,
      type: "string",
      order: idx,
      width: "150px",
      isVisible: c.toLowerCase() === "id" ? false : true,
    }));

const getColumnsFromData = (data) =>
  data[0] ? getColumnsFromObj(data[0]) : [];

const mapColumn = (c, idx) => ({
  name: c.columnName,
  type: convertDatabaseType(c.dataTypeName),
  isNull: c.allowDBNull,
  order: idx,
  width: "150px",
  isVisible: c.columnName.toLowerCase() === "id" ? false : true,
});

const resolveDataResult = (res) => {
  if (!res) return { source: "", data: [], itemsCount: 0, columns: [] };

  if (res.data) {
    const {
      data,
      metadata: { itemsCount, columns, page },
    } = res;

    const defaultColumns = columns
      ?.map(mapColumn)
      ?.filter((c) => c.name !== "RecCount");

    const mappedData = data?.map((r) => {
      // eslint-disable-next-line no-unused-vars
      const { RecCount, ...rest } = r;
      return rest;
    });

    return {
      source: res,
      data: mappedData,
      itemsCount: itemsCount,
      columns: defaultColumns,
      page,
    };
  }

  const isArray = Array.isArray(res);

  if (isArray) {
    const columns = getColumnsFromData(res);
    return { source: res, data: res, itemsCount: res.length, columns };
  }

  return {
    source: res,
    data: [res],
    itemsCount: 1,
    columns: getColumnsFromObj(res),
  };
};

export const fetchData = async (context, dataSource, settings, name) => {
  const res = await executeBlock(
    context,
    dataSource.id,
    {
      ...dataSource.staticParams,
      queryParams: [
        { name: "Page", value: settings?.page },
        { name: "ItemsPerPage", value: settings?.itemsPerPage },
        { name: "ItemsCount", value: settings?.itemsCount },
        {
          name: "ResultMode",
          value: dataSource?.staticParams?.resultMode || "DetailedData",
        },
      ],
      columns: [{ name: "id" }],
      filters: { columnFilters: getFilters(settings?.filters) },
      filterOptions: {},
      sort: getSort(settings?.sorting),
      componentName: name,
    },
    [],
    {
      callerId: context?.component?.id,
      callerGuid: context?.component?.guid,
      callerNodeId: context?.component?.nodeId,
    },
  );

  return resolveDataResult(res);
};
