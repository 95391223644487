import UserContext from "../../../../../../../user-context";
import { useContext, useEffect, useState } from "react";
import { Box, Select, Button } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { getRoles } from "../../../../../../../services/permission-service";
import { getUserList } from "../../../../../../../services/user-services";
import { getCompanies } from "../../../../../../../services/company-service";
import { showErrorToast } from "../../../../../echo-error-toast";

export const UserRoleSelect = ({ changeSettings, saveSettings }) => {
  const { userId, userName, companyId } = useContext(UserContext);
  const [type, setType] = useState();
  const [user, setUser] = useState();
  const [role, setRole] = useState();
  const [company, setCompany] = useState(companyId);
  const [companiesList, setCompaniesList] = useState([]);

  const typeSelectOptions = [
    { value: "me", label: userName },
    { value: "user" },
    { value: "role" },
    { value: "all" },
  ];

  const [userSelectOptions, setUserSelectOptions] = useState();
  const [roleSelectOptions, setRoleSelectOptions] = useState();

  const getUsers = async () => {
    try {
      const res = await getUserList("");
      const mapped = res
        .map(({ id, login }) => ({ id: parseInt(id), login }))
        .filter((el) => parseInt(el.id) !== parseInt(userId));
      setUserSelectOptions(mapped);
    } catch (e) {
      console.error(e);
    }
  };

  const getRolesForCompany = async (id) => {
    try {
      const res = await getRoles(id);
      const mapped = res.map(({ id, name }) => ({ id: parseInt(id), name }));
      setRoleSelectOptions(mapped);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getCompanies()
      .then((res) => {
        const mapped = [
          ...res.map((el) => ({
            value: el.id,
            label: el.databaseName,
          })),
          { value: "", label: "all" },
        ];

        setCompaniesList(mapped);
      })
      .catch(() => {
        showErrorToast({
          reasonTitle: "Błąd zapytania",
          message: "Błąd w pobieraniu dostępnych spółek.",
          location: "Combo wybór spółki",
        });
      });
  }, []);

  useEffect(() => {
    if (type === "user") getUsers();
    if (type === "role") getRolesForCompany(parseInt(companyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleType = (type) => {
    setUser();
    setRole();

    if (type === "me") {
      setUser(userId);
    } else if (type === "all") {
      setUser(null);
    }

    setType(type);
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Box display="flex" alignItems="center" gap="10px">
        <SettingsSelect
          name="type"
          value={type}
          options={typeSelectOptions}
          onChange={handleType}
        />
        {type !== "all" && type === "role" && (
          <SettingsSelect
            name="role"
            value={role}
            onChange={setRole}
            options={roleSelectOptions}
          />
        )}
        {type !== "all" && type === "user" && (
          <SettingsSelect
            name="user"
            value={user}
            onChange={setUser}
            options={userSelectOptions}
          />
        )}
        <SettingsSelect
          name="company"
          value={company}
          onChange={(val) => {
            setCompany(val === "" ? null : val);
          }}
          options={companiesList}
        />
      </Box>
      <Box display="flex" gap="10px">
        {type !== "all" && (
          <Button
            variant="outline"
            onClick={() => changeSettings(user, role)}
            isDisabled={!type || type === "role" || (type === "user" && !user)}
          >
            Show
            {/* {getShowBtnText()} */}
          </Button>
        )}
        <Button
          colorScheme="blue"
          isDisabled={
            !type || (type === "role" && !role) || (type === "user" && !user)
          }
          onClick={() =>
            saveSettings(type === "me" ? userId : user, role, type, company)
          }
        >
          Save
          {/* {getSaveBtnText()} */}
        </Button>
      </Box>
    </Box>
  );
};

UserRoleSelect.propTypes = {
  changeSettings: PropTypes.func,
  saveSettings: PropTypes.func,
};

const SettingsSelect = ({ name, options, onChange, value }) => {
  const handleChange = (e) => onChange(e.target.value);

  return (
    <Select
      display="flex"
      alignItems="center"
      value={value}
      onChange={handleChange}
    >
      <option disabled selected>
        Select {name}...
      </option>
      {options?.map((opt, idx) => {
        const [value, label] = Object.values(opt);
        return (
          <option value={value} key={idx}>
            {label || value}
          </option>
        );
      })}
    </Select>
  );
};

SettingsSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
