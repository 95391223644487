const serializeProcessBlock = (processBlock) => ({
  designerData: JSON.stringify({
    position: processBlock.position,
  }),
  id: processBlock.id,
  displayName: processBlock.displayName,
  body: processBlock.body,
  flowSettings: JSON.stringify(processBlock.flowSettings),
  isAsynchronous: processBlock.isAsynchronous,
  blockId: processBlock.relatedBlock.id,
  staticParams: JSON.stringify(processBlock.staticParams),
});

const deserializeProcessBlock = (processBlock) => ({
  position: JSON.parse(processBlock.designerData).position,
  id: processBlock.id,
  displayName: processBlock.displayName ?? "",
  body: processBlock.body,
  flowSettings: JSON.parse(processBlock.flowSettings) ?? {},
  isAsynchronous: processBlock.isAsynchronous,
  relatedBlock: { id: processBlock.blockId },
  staticParams: JSON.parse(processBlock.staticParams) ?? {},
});

export const serializeDiagram = (
  block,
  processBlocks,
  processLinks,
  mappings,
) => {
  return {
    ...block,
    inParameters: JSON.stringify(block.inParameters),
    returnType: JSON.stringify(block.returnType),
    type: processBlocks.some((p) => p.relatedBlock.type === 1) ? 1 : 0,
    executeType: 0,
    blocks: processBlocks.map(serializeProcessBlock),
    links: processLinks,
    staticParams: JSON.stringify(block.staticParams ?? {}),
    mappings,
  };
};

export const deserializeDiagram = (block) => {
  return {
    ...block,
    inParameters: JSON.parse(block.inParameters || "{}"),
    returnType: JSON.parse(block.returnType || "{}"),
    executeType: 0,
    blocks: block.blocks.map(deserializeProcessBlock),
    staticParams: JSON.parse(block.staticParams),
  };
};
