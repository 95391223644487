import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "../../theme/themeCaldo";
import { uuidv4 } from "@echo/tools";

const createContainer = () => {
  const [body] = document.getElementsByTagName("body");
  const id = uuidv4();
  const container = document.createElement("div");
  container.setAttribute("id", id);
  body.appendChild(container);
  return {
    id,
    node: container,
    unmount: () => {
      ReactDOM.unmountComponentAtNode(container);
      body.removeChild(container);
    },
  };
};

export const echoPortal = {
  render: (componentFunc) => {
    const container = createContainer();

    const chakraProvider = (
      <ChakraProvider theme={theme}>{componentFunc(container)}</ChakraProvider>
    );
    ReactDOM.render(chakraProvider, container.node);
    return container;
  },
};
