export const splitValue = (value, separator) => {
  if (!separator || (separator && !value.includes(separator)))
    return { integers: value };

  const splitted = value.split(separator);
  const integers = splitted[0];
  const fraction = splitted[1];

  return { integers, fraction };
};

export const splitBySize = (string, size, separator) => {
  const reversed = string.split("");
  reversed.reverse();
  let result = [];

  for (let i = 0; i < reversed.length; i++) {
    if (i > 0 && i % size === 0) {
      result.unshift(separator);
    }

    result.unshift(reversed[i]);
  }

  return result.join("");
};
