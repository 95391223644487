/* eslint-disable no-unused-vars */
import bcrypt from "bcryptjs-react";

const checkIsHash = (value) => {
  const regex = /^\$2[ayb]\$.{56}$/;
  const match = regex.test(value);

  return match;
};

export const hashValueBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams, params, systemParams) => {
      const { value } = staticParams;

      const isHash = checkIsHash(value);

      if (isHash) return value;

      // generate salt based on rounds used in GenerateSalt() in .Net
      const salt = bcrypt.genSaltSync(11);
      const hash = bcrypt.hashSync(value, salt);

      return hash;
    },
  };
};
