export const getItemByDefinition = (items, def) =>
  items.find((i) => {
    if (def.componentType === "combo") {
      return i.displayName === "Combo";
    } else if (
      def.name.toLowerCase().includes("date") ||
      def.type.includes("date")
    ) {
      return i.displayName === "Date";
    } else if (
      def.type === "int" ||
      def.type === "integer" ||
      def.type === "smallint" ||
      def.type.includes("decimal")
    ) {
      return i.displayName === "Numeric";
    } else if (def.type === "boolean" || def.type === "bit") {
      return i.displayName === "Checkbox";
    } else {
      return i.displayName === "Input";
    }
  });
