export const getOptions = (formatStr) => {
  const [min, max, precision, step, defaultValue, placeholder] = (
    formatStr || ""
  ).split(";");

  return {
    min: min !== "" && !isNaN(min) ? Number.parseFloat(min) : undefined,
    max: max !== "" && !isNaN(max) ? Number.parseFloat(max) : undefined,
    precision:
      precision !== "" && !isNaN(precision) ? Number.parseFloat(precision) : 0,
    step: step !== "" && !isNaN(step) ? Number.parseFloat(step) : 1,
    defaultValue:
      defaultValue !== "" && !isNaN(defaultValue)
        ? Number.parseFloat(defaultValue)
        : 0,
    placeholder,
  };
};
