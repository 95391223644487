import { uuidv4 } from "@echo/tools";

export const getSaveButtonProps = () => ({
  text: "Save",
  name: `$save_button_${uuidv4().toString().substring(0, 6)}`,
  onClick: {
    id: 35,
    name: "saveFormDataAndExit",
    type: "PROCESS",
  },
});

export const getDeleteButtonProps = () => ({
  text: "Delete",
  name: `$delete_button_${uuidv4().toString().substring(0, 6)}`,
  onClick: {
    id: 13,
    name: "deleteFormData",
    type: "PROCESS",
  },
});
