import { showErrorToast } from "../../../components/shared/echo-error-toast";
import {
  authenticate,
  readMessage,
} from "../../../services/email-service/email-service";

const extractIdFromObj = (obj) => {
  const keys = Object.keys(obj);
  const id = keys.find((k) => k.toLowerCase() === "id");
  return obj[id];
};

const getId = (staticParams, params) => {
  const staticParamsId = extractIdFromObj(staticParams);
  if (staticParamsId) return staticParamsId;

  const queryParamsId = staticParams.queryParams.find(
    (el) => el.name.toLowerCase() === "id",
  );
  if (queryParamsId) return queryParamsId.value;

  const paramsId = extractIdFromObj(params[0]);
  return paramsId;
};

const parseAttachments = (attachments) =>
  attachments.map((att) => {
    const byteArray = new Uint8Array(att.content.data);
    const blob = new Blob([byteArray], { type: att.contentType });
    const file = new File([blob], att.filename, { type: blob.type });
    return { id: att.cid, file };
  });

export const readMailBlock = (block, componentContext) => {
  return {
    defitinition: block,
    execute: async (staticParams, params) => {
      const userId = componentContext.userContext.userId;
      const id = getId(staticParams, params);

      try {
        const { refreshToken } = await authenticate(userId);

        if (!refreshToken || refreshToken === "") return {};

        const res = await readMessage({ id, refreshToken });

        if (!res.ok) {
          const err = await res.json();
          showErrorToast(err);
          return {};
        }

        const { message } = await res.json();
        const attachments = message.attachments;
        const parsedAttachments = parseAttachments(attachments);

        return { ...message, attachments: parsedAttachments };
      } catch (e) {
        throw new Error(e);
      }
    },
  };
};
