export const DATAGRID_CONTAINER_TESTID = "datagrid_container_testid";

export const DATAGRID_HEADER_TESTID = "datagrid_header_testid";
export const DATAGRID_HEADER_ROW_TESTID = "datagrid_header_row_testid";
export const DATAGRID_HEADER_ROW_CELL_TESTID =
  "datagrid_header_row_cell_testid";

export const DATAGRID_FILTER_ROW_TESTID = "datagrid_filter_row_testid";
export const DATAGRID_FILTER_ROW_CELL_TESTID =
  "datagrid_filter_row_cell_testid";

export const DATAGRID_BODY_TESTID = "datagrid_body_testid";
export const DATAGRID_BODY_ROW_TESTID = "datagrid_body_row_testid";
export const DATAGRID_BODY_ROW_CELL_TESTID = "datagrid_body_row_cell_testid";

export const DATAGRID_FOOTER_TESTID = "datagrid_footer_testid";
export const DATAGRID_FOOTER_CELL_TESTID = "datagrid_footer_cell_testid";

export const DATAGRID_NAVIGATION_TESTID = "datagrid_navigation_testid";

export const DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_TESTID =
  "datagrid_navigation_page_select_testid";

export const DATAGRID_NAVIGATION_PAGE_SIZE_SELECT_OPT_TESTID =
  "datagrid_navigation_page_select_opt_testid";

export const DATAGRID_NAVIGATION_PAGE_SELECT_TESTID =
  "datagrid_navigation_page_select_testid";

export const DATAGRID_NAVIGATION_PAGE_SELECT_PREV_TESTID =
  "datagrid_navigation_page_select_prev_testid";
export const DATAGRID_NAVIGATION_PAGE_SELECT_NEXT_TESTID =
  "datagrid_navigation_page_select_next_testid";

export const DATAGRID_NAVIGATION_PAGE_SELECT_PAGE_TESTID =
  "datagrid_navigation_page_select_page_testid";

export const DATAGRID_NAVIGATION_PAGE_SELECT_PAGES_COUNT_TESTID =
  "datagrid_navigation_page_select_pages_count_testid";

export const DATAGRID_NAVIGATION_TOTAL_COUNT_TESTID =
  "datagrid_navigation_total_count_testid";
