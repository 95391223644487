import {
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useContext, useMemo, useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { echoPortal } from "../../../echo-portal/echo-portal";
import EchoRouterContext from "../../../echo-router/echo-router-context";
import QuestionAlertComponent from "../../../shared/echo-components/base-components/components/question-alert-component/question-alert.component";
import { uuidv4 } from "../../../../../../tools/src/lib/uuidv4/uuidv4";

const ProcessPicker = ({ sourceName, process, onChange, isDisabled }) => {
  const { openPage, focusPage } = useContext(EchoRouterContext);

  const [openedPageKey, setOpenedPageKey] = useState(null);

  const inputValue = useMemo(() => {
    if (process && typeof process === "object") {
      return process.name && `Process: ${process.name}`;
    } else {
      return "";
    }
  }, [process]);

  const handleOnSave = (process) => {
    onChange({ type: "PROCESS", name: process?.name, id: process?.id });
  };

  const handleOnClick = () => {
    if ((openedPageKey && !focusPage(openedPageKey)) || !openedPageKey) {
      setOpenedPageKey(
        openPage(
          process && process.id && process.type === "PROCESS"
            ? `diagramDesigner/${process.id}`
            : "diagramDesigner",
          {
            afterCreateEvent: {
              afterCreateEventName: "SAVE_CALLBACK",
              callbackEvent: handleOnSave,
              customParams: {
                name: `${sourceName}-${uuidv4()}`,
                description: `${sourceName} process.`,
                private: true,
              },
            },
          },
          `${sourceName}${sourceName ? " - " : ""}Diagram designer`,
          true,
        ),
      );
    }
  };

  const handleOnDelete = () => {
    echoPortal.render((container) => (
      <QuestionAlertComponent
        open={true}
        header={"Erase property value"}
        question={"Do you really want to erase this property?"}
        alertMode={"Yes/No"}
        onAction={(type) => {
          if (type === "Yes" && onChange) {
            onChange(null);
          }
          container.unmount();
        }}
      />
    ));
  };

  return (
    <FormControl variant="floating" isDisabled={isDisabled}>
      <InputGroup>
        <Input width="100%" placeholder=" " isReadOnly value={inputValue} />
        <FormLabel>Process</FormLabel>
        <InputRightElement width="fit-content">
          <Box
            display="flex"
            flexFlow="row"
            m="0.5rem"
            marginRight="0.5rem"
            gap="0.5rem"
          >
            <Button
              isDisabled={isDisabled}
              onClick={handleOnClick}
              height="1rem"
              size="sm"
            >
              ...
            </Button>
            <IconButton
              isDisabled={isDisabled}
              onClick={handleOnDelete}
              height="1rem"
              size="sm"
            >
              <FiTrash2 color="red" />
            </IconButton>
          </Box>
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

ProcessPicker.propTypes = {
  sourceName: PropTypes.string,
  process: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ProcessPicker.defaultProps = {
  sourceName: "undefined",
};

export default ProcessPicker;
