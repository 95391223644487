import PropTypes from "prop-types";
import LabeledCheckboxFormControl from "../../../../../../../labeled-checkbox-form-control/labeled-checkbox-from-control";

const BooleanEditCell = ({ value, onChange }) => {
  return <LabeledCheckboxFormControl value={value} onChange={onChange} />;
};

BooleanEditCell.propTypes = { value: PropTypes.any, onChange: PropTypes.func };

BooleanEditCell.defaultProps = {};

export default BooleanEditCell;
