export const openModalBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve, reject) => {
        const openModal = componentContext?.functions?.openModal;
        const componentId = isNaN(staticParams?.component)
          ? null
          : Number(staticParams?.component);

        if (typeof openModal !== "function") {
          reject(new Error("'openModal' is not a function"));
          return;
        }

        if (!componentId) {
          reject(new Error("'componentId' property is required"));
          return;
        }

        let parameters;

        try {
          parameters = JSON.parse(staticParams.parameters);
        } catch {
          parameters = {};
        }

        const component = {
          modalProps: {
            onClose: (type, data) => {
              const __resultCondition = type;
              resolve({
                queryParams: data ? [...data] : [],
                __resultCondition,
              });
            },
          },
          componentId: staticParams?.component,
          ...parameters,
        };

        try {
          openModal(component);
          return;
        } catch (err) {
          reject(err);
          return;
        }
      }),
  };
};
