import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { withBaseComponent } from "../../../with-base-component";

const CameraView = ({ rootProps }) => {
  const videoRef = useRef();
  const { nodeRef, ...restRootProps } = rootProps;

  useEffect(() => {
    console.log(videoRef);

    if (
      videoRef.current &&
      navigator.mediaDevices &&
      navigator.mediaDevices.getUserMedia
    ) {
      const video = videoRef.current;
      console.log(video);
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          console.log(stream);
          video.srcObject = stream;
          video.play();
        });
    }
  }, [videoRef]);

  return (
    <div ref={nodeRef} {...restRootProps}>
      <video ref={videoRef} id="video" width="640" height="480" autoPlay />
    </div>
  );
};

CameraView.propTypes = { rootProps: PropTypes.any };

CameraView.defaultProps = {};

export default withBaseComponent(CameraView);
