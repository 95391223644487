import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getBlockMetadata,
  getBlockMetadataByGroup,
} from "../../../../process-executor/process-executor";
import { baseGet } from "../../../../services/base-fetch";

export const getObjectDefinition = async (objectId) =>
  await baseGet("EchoObject/getDefinition", { definitionId: objectId });

export const useToolbox = (
  dropAction,
  data,
  onDropAreaChange,
  properties,
  dragMode,
  setDragMode,
) => {
  const [formComponents, setFormComponents] = useState(null);

  const getComponentsByObjectId = async (objectId) => {
    try {
      const res = await getObjectDefinition(objectId);
      const { properties } = res;
      const formControls = properties.map((p) => ({
        name: p.name,
        type: p.type.toLowerCase(),
        componentType: p.componentType.toLowerCase(),
      }));
      setFormComponents(formControls);
    } catch (e) {
      console.error(e);
    }
  };

  const getComponentsByReadQuery = async (crudRead, crudActions) => {
    if (crudRead) {
      try {
        const res = await getBlockMetadata(
          crudRead.id,
          crudRead.staticParams,
          [],
        );
        setFormComponents(res);
      } catch (e) {
        window.console.error(e);
        toast.error("Błąd. Nie udało się pobrać metadanych źródła");
      }
    } else if (crudActions && crudActions.name) {
      try {
        const res = getBlockMetadataByGroup(
          {
            group: crudActions.name,
            tag: "READ",
          },
          crudActions.staticParams,
          [],
        );
        setFormComponents(res);
      } catch (e) {
        window.console.error(e);
        toast.error("Błąd. Nie udało się pobrać metadanych źródła");
      }
    }
  };

  useEffect(() => {
    if (properties.relatedObjectId) {
      getComponentsByObjectId(properties.relatedObjectId);
      return;
    } else {
      getComponentsByReadQuery(properties.crudRead, properties.crudActions);
      return;
    }
  }, [properties]);

  return [
    dragMode,
    {
      dragMode,
      items: data,
      onStartDrag: () => setDragMode(true),
      onStopDrag: () => setDragMode(false),
      onDropItem: (definition, defaultProps) => {
        Promise.all([dropAction(definition, defaultProps), setDragMode(false)]);
      },
      onAreaChange: onDropAreaChange,
      formComponents,
    },
  ];
};
