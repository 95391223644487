// eslint-disable-next-line no-undef
let PropTypes = require("prop-types");

const createWrappedPropTypes = () => {
  // function to add key value pair to proptype
  const enhancePropType = (propType, values) => {
    propType.info = Object.assign(propType.info ? propType.info : {}, values);

    if (propType.isRequired) {
      propType.isRequired.info = Object.assign(
        propType.isRequired && propType.isRequired.info
          ? propType.isRequired.info
          : {},
        values,
      );

      propType.isRequired.info.isRequired = true;
      propType.info.isRequired = false;
    }

    return propType;
  };

  // copy original PropTypes to PropTypes
  Object.keys(PropTypes).forEach(function (propTypeName) {
    if (propTypeName === "PropTypes") return;
    PropTypes[propTypeName] = enhancePropType(PropTypes[propTypeName], {
      propTypeName: propTypeName,
    });
  });

  // override "instanceOf"
  PropTypes.instanceOf = (jsClass) =>
    enhancePropType(PropTypes.instanceOfOri(jsClass), {
      className: jsClass && jsClass.name,
      propTypeName: "instanceOf",
    });

  // override "oneOf"
  PropTypes.oneOf = (allowedValues) =>
    enhancePropType(PropTypes.oneOfOri(allowedValues), {
      allowedValues: allowedValues,
      propTypeName: "oneOf",
    });

  // override "oneOfType"
  PropTypes.oneOfType = (allowedPropTypes) =>
    enhancePropType(PropTypes.oneOfTypeOri(allowedPropTypes), {
      allowedPropTypes: allowedPropTypes,
      propTypeName: "oneOfType",
    });

  // override "arrayOf"
  PropTypes.arrayOf = (allowedChildrenPropType) =>
    enhancePropType(PropTypes.arrayOfOri(allowedChildrenPropType), {
      allowedChildrenPropType: allowedChildrenPropType,
      propTypeName: "arrayOf",
    });

  // override "objectOf"
  PropTypes.objectOf = (allowedChildrenPropType) =>
    enhancePropType(PropTypes.objectOfOri(allowedChildrenPropType), {
      allowedChildrenPropType: allowedChildrenPropType,
      propTypeName: "objectOf",
    });

  // override "shape"
  PropTypes.shape = (objectShape) =>
    enhancePropType(PropTypes.shapeOri(objectShape), {
      objectShape: objectShape,
      propTypeName: "shape",
    });

  // override func
  PropTypes.funcOf = (...allowedParameters) => {
    const getValidateFunc = (required) => (props, propName) => {
      if (!required || !props || !props[propName]) {
        return;
        // eslint-disable-next-line no-dupe-else-if
      } else if (required && (!props || !props[propName])) {
        // eslint-disable-next-line consistent-return
        return new Error(`${propName} function is required`);
      }
      const fn = props[propName];
      const prototype = Object.getPrototypeOf(fn);
      if (
        !prototype ||
        (typeof prototype.constructor !== "function" &&
          fn.prototype.constructor.length !== allowedParameters.length)
      ) {
        // eslint-disable-next-line consistent-return
        return new Error(
          `'${propName}' must be a function with ${allowedParameters.length} args`,
        );
      }
    };
    const validate = getValidateFunc(false);
    validate.isRequired = getValidateFunc(true);
    return enhancePropType(validate, {
      allowedParameters,
      propTypeName: "funcOf",
    });
  };
};

const propTypesProductionShim = (propTypeName) => {
  var err = new Error(
    `Calling PropTypes.${propTypeName} validator directly is not supported. ` +
      "Use PropTypes.checkPropTypes() to call them. " +
      "Read more at http://fb.me/use-check-prop-types",
  );
  err.name = "Invariant Violation";
  throw err;
};

const storeOriginalPropTypes = () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== "production") {
    PropTypes["shapeOri"] = PropTypes.shape;
    PropTypes["objectOfOri"] = PropTypes.objectOf;
    PropTypes["arrayOfOri"] = PropTypes.arrayOf;
    PropTypes["oneOfTypeOri"] = PropTypes.oneOfType;
    PropTypes["oneOfOri"] = PropTypes.oneOf;
    PropTypes["instanceOfOri"] = PropTypes.instanceOf;
  } else {
    PropTypes["array"] = () => propTypesProductionShim("array");
    PropTypes["array"].isRequired = () => propTypesProductionShim("array");
    PropTypes["bool"] = () => propTypesProductionShim("bool");
    PropTypes["bool"].isRequired = () => propTypesProductionShim("bool");
    PropTypes["func"] = () => propTypesProductionShim("func");
    PropTypes["func"].isRequired = () => propTypesProductionShim("func");
    PropTypes["number"] = () => propTypesProductionShim("number");
    PropTypes["number"].isRequired = () => propTypesProductionShim("number");
    PropTypes["object"] = () => propTypesProductionShim("object");
    PropTypes["object"].isRequired = () => propTypesProductionShim("object");
    PropTypes["string"] = () => propTypesProductionShim("string");
    PropTypes["string"].isRequired = () => propTypesProductionShim("string");
    PropTypes["symbol"] = () => propTypesProductionShim("symbol");
    PropTypes["symbol"].isRequired = () => propTypesProductionShim("symbol");

    PropTypes["any"] = () => propTypesProductionShim("any");
    PropTypes["any"].isRequired = () => propTypesProductionShim("any");
    PropTypes["element"] = () => propTypesProductionShim("element");
    PropTypes["element"].isRequired = () => propTypesProductionShim("element");
    PropTypes["elementType"] = () => propTypesProductionShim("elementType");
    PropTypes["elementType"].isRequired = () =>
      propTypesProductionShim("elementType");
    PropTypes["node"] = () => propTypesProductionShim("node");
    PropTypes["node"].isRequired = () => propTypesProductionShim("node");

    PropTypes["arrayOfOri"] = () => () => propTypesProductionShim("arrayOf");
    PropTypes["arrayOfOri"].isRequired = () => () =>
      propTypesProductionShim("arrayOf");
    PropTypes["instanceOfOri"] = () => () =>
      (PropTypes["instanceOfOri"].isRequired = () => () =>
        propTypesProductionShim("instanceOf"));
    PropTypes["objectOfOri"] = () => () => propTypesProductionShim("objectOf");
    PropTypes["objectOfOri"].isRequired = () => () =>
      propTypesProductionShim("objectOf");
    PropTypes["oneOfOri"] = () => () => propTypesProductionShim("oneOf");
    PropTypes["oneOfOri"].isRequired = () => () =>
      propTypesProductionShim("oneOf");
    PropTypes["oneOfTypeOri"] = () => () =>
      (PropTypes["oneOfTypeOri"].isRequired = () => () =>
        propTypesProductionShim("oneOfType"));
    PropTypes["shapeOri"] = () => () => propTypesProductionShim("shape");
    PropTypes["shapeOri"].isRequired = () => () =>
      propTypesProductionShim("shape");
    PropTypes["exact"] = () => () => propTypesProductionShim("exact");
    PropTypes["exact"].isRequired = () => () =>
      propTypesProductionShim("exact");
  }
};

const enhancePropTypes = () => {
  storeOriginalPropTypes();
  createWrappedPropTypes();
};

enhancePropTypes();
