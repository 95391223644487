import { Fragment } from "react";
import { withEchoComponent } from "../../../../../../../../echo-component/function-wrapper/function-wrapper";
import EchoComponent from "../../../../../echo-component";
import PropTypes from "prop-types";

export const EchoListItemElement = (props) => {
  const {
    context,
    child,
    childProps,
    state,
    localContext,
    stateDispatch,
    designerMode,
    path,
    mode,
    rootProps,
  } = props;

  return (
    <Fragment>
      {withEchoComponent(
        <EchoComponent
          context={context}
          source={child.component.source}
          styleOptions={{
            ...child.styleOptions,
          }}
          componentProps={{ ...childProps }}
          childs={child.childrenComponents}
          systemProps={{
            id: child.id,
            guid: child.guid,
            nodeId: child.nodeId,
            depth: child.depth,
            state,
            localContext,
            stateDispatch,
            onMoveElement: rootProps.onMoveElement,
            dataSource: childProps.dataSource,
            designerMode,
            component: child.component,
            childs: child.childrenComponents,
            context,
            path,
          }}
        />,
        mode,
      )}
    </Fragment>
  );
};

EchoListItemElement.propTypes = {
  context: PropTypes.any,
  child: PropTypes.object,
  childProps: PropTypes.object,
  state: PropTypes.object,
  localContext: PropTypes.object,
  stateDispatch: PropTypes.func,
  designerMode: PropTypes.bool,
  path: PropTypes.string,
  mode: PropTypes.string,
  rootProps: PropTypes.object,
};
