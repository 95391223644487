import { Box, Text, Button } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const ErrorMsg = ({ onClick }) => {
  return (
    <Box>
      <Text>Autoryzacja Google nie powiodła się.</Text>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button variant="outline" onClick={onClick}>
          Spróbuj ponownie
        </Button>
      </Box>
    </Box>
  );
};

ErrorMsg.propTypes = {
  onClick: PropTypes.func,
};
