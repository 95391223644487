/* eslint-disable no-extend-native */
/* eslint-disable array-callback-return */
Object.defineProperty(Array.prototype, 'orderBy', {
  value: function (sorts) {
    sorts.map((sort) => {
      sort.uniques = Array.from(new Set(this.map((obj) => obj[sort.key])));

      sort.uniques = sort.uniques.sort((a, b) => {
        if (typeof a == 'string') {
          return sort.inverse ? b.localeCompare(a) : a.localeCompare(b);
        } else if (typeof a == 'number') {
          return sort.inverse ? b - a : a - b;
        } else if (typeof a == 'boolean') {
          let x = sort.inverse
            ? a === b
              ? 0
              : a
              ? -1
              : 1
            : a === b
            ? 0
            : a
            ? 1
            : -1;
          return x;
        }
        return 0;
      });
    });

    const weightOfObject = (obj) => {
      let weight = '';
      sorts.map((sort) => {
        let zeropad = `${sort.uniques.length}`.length;
        weight += sort.uniques
          .indexOf(obj[sort.key])
          .toString()
          .padStart(zeropad, '0');
      });
      //obj.weight = weight; // if you need to see weights
      return weight;
    };

    this.sort((a, b) => {
      return weightOfObject(a).localeCompare(weightOfObject(b));
    });

    return this;
  },
});

/* USAGE
 * let sortered = your_array.orderBy([
 *   {key: "type", inverse: false},
 *   {key: "title", inverse: false},
 *   {key: "spot", inverse: false},
 *   {key: "internal", inverse: true}
 * ]);
 */
