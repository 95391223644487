import React, { useContext } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import PanPinchZoomDragContext from "../context/pan-pinch-zoom-drag-context";
import { Box } from "@chakra-ui/react";

const PPZDraggable = ({ children, scale, ...props }) => {
  const { transformState } = useContext(PanPinchZoomDragContext);
  return (
    <Draggable
      cancel=".disable-ppz-drag, .disable-ppz-drag path, .disable-ppz-drag line"
      scale={scale ?? transformState.scale}
      {...props}
    >
      <Box position="absolute">
        {typeof children === "function"
          ? children({ scale: scale ?? transformState.scale })
          : children}
      </Box>
    </Draggable>
  );
};

PPZDraggable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  defaultClassName: PropTypes.string,
  defaultClassNameDragging: PropTypes.string,
  defaultClassNameDragged: PropTypes.string,
  allowAnyClick: PropTypes.bool,
  axis: PropTypes.string,
  bounds: PropTypes.oneOfType([
    PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
      right: PropTypes.number,
      bottom: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  cancel: PropTypes.string,
  defaultPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  disabled: PropTypes.bool,
  grid: PropTypes.any,
  handle: PropTypes.string,
  offsetParent: PropTypes.any,
  onMouseDown: PropTypes.func,
  onStart: PropTypes.func,
  onDrag: PropTypes.func,
  onStop: PropTypes.func,
  nodeRef: PropTypes.any,
  position: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  positionOffset: PropTypes.shape({
    x: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    y: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  }),
  scale: PropTypes.number,
};

PPZDraggable.defaultProps = {
  defaultClassName: "disable-ppz-pan",
};

export default PPZDraggable;
