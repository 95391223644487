import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import { KvpTable } from "@echo/ui";
import { FiTrash2 } from "react-icons/fi";

const StateDebugger = ({ state, onPropChange }) => {
  const updateProp = useCallback(
    (name, value) => {
      if (typeof onPropChange === "function") {
        onPropChange(name, value);
      }
    },
    [onPropChange],
  );

  const getValue = (value) => {
    switch (typeof value) {
      case "function":
      case "object":
      case "undefined":
        return value === null ? "{null}" : `{${typeof value}}`;
      default:
        return value;
    }
  };

  const data = useMemo(
    () =>
      Object.keys(state || {})
        .map((name) => ({
          name,
          value: getValue(state[name]),
          actions: [
            {
              name: "clear",
              icon: <FiTrash2 />,
              action: () => updateProp(name, undefined),
            },
          ],
        }))
        .filter((obj) => typeof obj.value !== "object"),
    [state, updateProp],
  );

  return (
    <Box>
      <KvpTable caption="Actual state" data={data} />
    </Box>
  );
};

StateDebugger.propTypes = {
  state: PropTypes.object.isRequired,
  onPropChange: PropTypes.func.isRequired,
};

StateDebugger.defaultProps = {};

export default StateDebugger;
