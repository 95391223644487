import { createContext, useState } from "react";
import PropTypes from "prop-types";
import {
  addMinutes,
  differenceInMinutes,
  isAfter,
  isBefore,
  isSameMinute,
  subMinutes,
} from "date-fns";

export const CalendarDragContext = createContext();

export const CalendarDragContextProvider = ({ children }) => {
  const [draggedElement, setDraggedElement] = useState();
  const [dragState, setDragState] = useState();

  const handleDragStart = (el, type) => {
    setDraggedElement(el);
    setDragState(type);
  };

  const handleDragEnd = () => {
    setDraggedElement();
    setDragState();
  };

  const handleDragOver = (area, type) => {
    if (type === "drag") {
      setDraggedElement((prev) => {
        const end = addMinutes(area, differenceInMinutes(prev.to, prev.from));

        return {
          ...prev,
          from: area,
          to: end,
        };
      });
    }

    if (type.includes("resize-")) {
      setDraggedElement((prev) => ({
        ...prev,
        from: type === "resize-top" ? getStartDate(prev, area) : prev.from,
        to: type === "resize-bottom" ? getEndDate(prev, area) : prev.to,
      }));
    }
  };

  const getStartDate = (event, newTime) => {
    if (isAfter(newTime, subMinutes(event.to, 15)))
      return subMinutes(event.to, 15);
    return newTime;
  };

  const getEndDate = (event, newTime) => {
    if (isSameMinute(newTime, event.from) || isBefore(newTime, event.from))
      return subMinutes(event.from, -15);

    return subMinutes(newTime, -15);
  };

  const contextValue = {
    draggedElement,
    dragState,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
  };

  return (
    <CalendarDragContext.Provider value={contextValue}>
      {children}
    </CalendarDragContext.Provider>
  );
};

CalendarDragContextProvider.propTypes = {
  children: PropTypes.node,
};
