import { Button } from "@chakra-ui/react";
import { MdAdd, MdRemove } from "react-icons/md";
import PropTypes from "prop-types";

export const ToggleVisibilityBtn = ({ isVisible, onClick }) => {
  return (
    <Button
      variant="ghost"
      isActive
      textColor="black"
      colorScheme={isVisible ? "red" : "cyan"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      padding="5px 10px"
      onClick={onClick}
    >
      {isVisible ? (
        <MdRemove
          style={{
            padding: "0",
            margin: "0",
          }}
        />
      ) : (
        <MdAdd
          style={{
            padding: "0",
            margin: "0",
          }}
        />
      )}
    </Button>
  );
};

ToggleVisibilityBtn.propTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};
