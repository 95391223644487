import { echoPortal } from "../../components/echo-portal/echo-portal";
import QuestionAlert from "../../components/shared/echo-components/base-components/components/question-alert-component/question-alert.component";

export const alertBlock = (block) => {
  return {
    definition: block,
    execute: (staticParams, params) =>
      new Promise((resolve, reject) => {
        const parsedBody = staticParams?.__processBlock?.body
          ? JSON.parse(staticParams.__processBlock.body)
          : {};

        const mergedParams = {
          ...(staticParams ?? {}),
          ...(params[0] ?? {}),
          ...parsedBody,
        };

        try {
          echoPortal.render((container) => (
            <QuestionAlert
              open={true}
              header={mergedParams.header}
              question={mergedParams.question}
              alertMode={mergedParams.alertMode ?? "Yes/No"}
              onAction={(type) => {
                container.unmount();
                resolve({ __resultCondition: type });
              }}
            />
          ));
        } catch (err) {
          reject(err);
        }
      }),
    getMetadata: () => {},
  };
};
