import { isCharValid } from "./validate-character";

export const getSeparator = (format, date) => {
  const formatChars = format.toLowerCase().split("");
  const separators = formatChars
    .map((c, idx) => ({ char: c, idx }))
    .filter((c) => !isCharValid(c.char));
  let tempSeparators = separators;

  const dateChars = date.split("");
  const usedSeparators = [];

  dateChars.forEach((c) => {
    const f = tempSeparators.find((s) => s.char === c);
    if (f) {
      tempSeparators = tempSeparators.toSpliced(0, 1);
      usedSeparators.push(f);
    }
  });

  const availableSeparators = tempSeparators.filter(
    (s) => !usedSeparators.find((us) => s.idx === us.idx),
  );

  return {
    usedSeparators: usedSeparators.map((s) => s.char),
    usedSeparatorsObj: usedSeparators,
    current: availableSeparators[0]?.char,
    currentObj: availableSeparators[0],
    separators,
  };
};
