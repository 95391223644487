import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@chakra-ui/react';
import {
  BlockTypeQueryEnum,
  getBlockList,
} from '../../services/diagram-service';
import DataGrid from '../shared/echo-components/base-components/components/datagrid-component/datagrid.component';
import EchoRouterContext from '../echo-router/echo-router-context';

const columns = [
  {
    name: 'name',
  },
  {
    name: 'description',
  },
];

const ProcessListPage = () => {
  const [data, setData] = useState([]);

  const { openPage } = useContext(EchoRouterContext);

  const loadData = (callback) =>
    getBlockList(BlockTypeQueryEnum.Public, [0])
      .then(callback)
      .catch(() => toast.error('Błąd. Nie udało się załadować danych listy'));

  const handleRowSelect = (row) =>
    openPage(
      `/diagramDesigner/${row._id}`,
      null,
      `${row.name} - Process/Block designer`,
      true
    );

  const handleOnNewComponentClick = () =>
    openPage('/diagramDesigner/', null, 'New component - Designer', true);

  useEffect(() => {
    let isMounted = true;

    loadData((result) => {
      if (isMounted) {
        setData(result);
      }
    });
    return () => (isMounted = false);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Button
          color='primary'
          variant='contained'
          onClick={handleOnNewComponentClick}
        >
          Nowy proces
        </Button>
      </div>
      <DataGrid
        objectKey='BLOCK_LIST'
        value={data}
        columns={columns}
        onRowClick={(row) => handleRowSelect(row)}
        pageSize={10}
      />
    </div>
  );
};

export default ProcessListPage;
