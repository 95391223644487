import { Box, Grid, List, ListItem, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { ACTIONS, useMappingReducer } from "./mapping-reducer";
import { validateAction } from "./utils/validation-utils";
import { detectAction } from "./utils/action-types";

export const MappingEditor = ({
  sourceProps,
  destinationProps,
  onChange,
  mappings,
}) => {
  const [state, dispatch] = useMappingReducer(
    {
      mappings,
      sourceProps,
      destinationProps,
    },
    onChange,
  );

  const displayLists = [
    {
      key: "sourceProps",
      value: state.sourceProps,
      allowedTo: [ACTIONS.CHANGE_ORDER],
    },
    {
      key: "mappings",
      value: state.mappings,
      allowedTo: [ACTIONS.UNBIND],
    },
    {
      key: "destinationProps",
      value: state.destinationProps.filter(
        (prop) =>
          !state.mappings.map((mapping) => mapping.name).includes(prop.name),
      ),
      allowedTo: [ACTIONS.BIND, ACTIONS.CHANGE_ORDER],
    },
  ];

  const areaDragPos = useRef();
  const itemDragPos = useRef();

  const handleItemDragOver = (e, itemId, areaId) => {
    e.preventDefault();
    itemDragPos.current = itemId;
    handleAreaDragOver(e, areaId);
  };

  const handleAreaDragOver = (e, key) => {
    e.preventDefault();
    areaDragPos.current = key;
  };

  const handleDragEnd = (
    prop,
    sourceListKey,
    sourceOrder,
    destinationListKey,
    destinationOrder,
  ) => {
    const actionType = detectAction(sourceListKey, destinationListKey);

    const validationError = validateAction(
      state,
      displayLists,
      actionType,
      prop,
      sourceListKey,
      sourceOrder,
      destinationOrder,
    );

    if (validationError) {
      console.warn(validationError);
      toast.warn(validationError);
      return;
    }

    dispatch({
      type: actionType,
      prop,
      sourceListKey,
      sourceOrder,
      destinationListKey,
      destinationOrder,
    });
  };

  const handleAreaDragLeave = useCallback(() => {
    areaDragPos.current = null;
  }, []);

  return (
    <Box height="100%" onDragLeave={handleAreaDragLeave}>
      <Text>Mapping editor</Text>
      <Grid
        onDragLeave={handleAreaDragLeave}
        height="100%"
        gridTemplateColumns="repeat(3, 1fr)"
        gap="5px"
      >
        {displayLists.map((list) => (
          <Box
            key={list.key}
            onDragLeave={handleAreaDragLeave}
            border="2px solid black"
            padding="8px"
            onDragOver={(e) => handleAreaDragOver(e, list.key)}
          >
            <List
              onDragLeave={handleAreaDragLeave}
              display="flex"
              flexDirection="column"
              gap="10px"
            >
              {list.value.map((property, propertyOrder) => (
                <ListItem
                  display="flex"
                  alignItems="center"
                  gap="5px"
                  draggable
                  cursor={"grab"}
                  onDragEnd={() =>
                    handleDragEnd(
                      property,
                      list.key,
                      propertyOrder,
                      areaDragPos.current,
                      itemDragPos.current,
                    )
                  }
                  onDragOver={(e) =>
                    handleItemDragOver(e, propertyOrder, list.key)
                  }
                  key={property.name}
                >
                  <Text fontSize="16px">{property.name}</Text>
                  <Text fontSize="12px">{`(${property.type})`}</Text>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

MappingEditor.propTypes = {
  sourceProps: PropTypes.array,
  destinationProps: PropTypes.array,
  onChange: PropTypes.func,
  mappings: PropTypes.array,
};
