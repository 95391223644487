export const checkSystemProperties = (propTypes, componentName) => {
  if (!propTypes.onClick) {
    throw new Error(
      `Component must have 'onClick' property. ComponentName: '${componentName}'`
    );
  }
  if (!propTypes.nodeRef) {
    throw new Error(
      `Component must have 'nodeRef' property. ComponentName: '${componentName}'`
    );
  }
  if (!propTypes.onMouseOver) {
    throw new Error(
      `Component must have 'onMouseOver' property. ComponentName: '${componentName}'`
    );
  }
  if (!propTypes.onMouseLeave) {
    throw new Error(
      `Component must have 'onMouseLeave' property. ComponentName: '${componentName}'`
    );
  }
  if (!propTypes.onMouseUp) {
    throw new Error(
      `Component must have 'onMouseUp' property. ComponentName: '${componentName}'`
    );
  }
  if (!propTypes.onMouseDown) {
    throw new Error(
      `Component must have 'onMouseDown' property. ComponentName: '${componentName}'`
    );
  }
};
