import { Box } from "@chakra-ui/react";

const BoundaryBlock = () => {
  return (
    <Box
      className="disable-ppz-pan"
      height="80px"
      width="80px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        className="disable-ppz-pan"
        width="80px"
        height="60px"
        borderRadius="50%"
        borderColor="green.800"
        backgroundColor="green.500"
        display="flex"
        alignItems="center"
        alignContent="center"
        justifyContent="center"
      >
        <p>START</p>
      </Box>
    </Box>
  );
};

export default BoundaryBlock;
