import PropTypes from "prop-types";
import { Text } from "@chakra-ui/react";
import { withBaseComponent } from "../../../with-base-component";
import { useEffect, useState } from "react";

const TextComponent = ({ fontSize, text, rootProps }) => {
  const { nodeRef, ...restRootProps } = rootProps;
  const [textState, setTextState] = useState(" ");

  useEffect(() => {
    if (typeof text === "string") {
      setTextState(text);
    } else if (typeof text === "function") {
      Promise.resolve(text())
        .then((result) => {
          if (typeof result === "string") {
            setTextState(result);
          } else {
            const name = result.name;
            const toName = result.toName;
            const value = toName ? result[toName] : result[name];
            setTextState(value || "");
          }
        })
        .catch(() => {});
    }
  }, [text]);

  return (
    <Text ref={nodeRef} {...restRootProps} fontSize={fontSize}>
      {textState}
    </Text>
  );
};

TextComponent.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
  rootProps: PropTypes.any,
};

TextComponent.defaultProps = {
  text: "Edit text in properties tab",
};

export default withBaseComponent(TextComponent);
