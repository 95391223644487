import PropTypes from "prop-types";
import { Box } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/react";
import { FiLock, FiMenu, FiUnlock } from "react-icons/fi";
import { forwardRef, useCallback, useContext, useMemo } from "react";
import EchoRouterContext from "../echo-router/echo-router-context";
import { Menu as UIMenu } from "@echo/ui";

const Menu = forwardRef(
  (
    { menu, isOpen, onToggle, openWidth, closeWidth, isLocked, onToggleLock },
    ref,
  ) => {
    const menuItems = useMemo(() => menu ?? [], [menu]);

    const { openPage } = useContext(EchoRouterContext);

    const handleOnSelect = useCallback(
      (_, { key, path }) => {
        if (key && path) {
          openPage(
            path,
            null,
            menuItems.find((item) => item.key === key)?.displayName,
            true,
          );
        }
      },
      [menuItems, openPage],
    );

    const handleToggle = useCallback(() => {
      onToggle();
    }, [onToggle]);

    const handleToggleLock = useCallback(() => {
      onToggleLock();
    }, [onToggleLock]);

    const handleMouseOver = useCallback(() => {
      if (!isLocked && !isOpen) {
        onToggle();
      }
    }, [isLocked, isOpen, onToggle]);

    const handleMouseLeave = useCallback(() => {
      if (!isLocked && isOpen) {
        onToggle();
      }
    }, [isLocked, isOpen, onToggle]);

    return (
      <Box
        borderRight="1px solid var(--chakra-colors-grayCa)"
        boxSizing="border-box"
        height="100%"
        display="flex"
        width={isOpen || isLocked ? `${openWidth}px` : `${closeWidth}px`}
        flexFlow="column"
        transition="width 200ms ease-in-out"
        onMouseLeave={handleMouseLeave}
      >
        <Box
          display="flex"
          height="30px"
          flexShrink={0}
          justifyContent="flex-end"
          borderBottom="1px solid var(--chakra-colors-grayCa)"
          onMouseOver={handleMouseOver}
        >
          <IconButton onClick={handleToggleLock} variant="ghost">
            {isLocked ? <FiLock /> : <FiUnlock />}
          </IconButton>
          <IconButton
            onClick={handleToggle}
            disabled={!isLocked}
            variant="ghost"
          >
            <FiMenu />
          </IconButton>
        </Box>
        <Box
          padding="4px 0px"
          display="flex"
          overflowY="auto"
          flexGrow={1}
          minHeight={0}
          flexDirection="column"
          boxSizing="border-box"
        >
          <Box
            ref={ref}
            flexDirection="column"
            width="100%"
            alignItems="stretch"
            boxSizing="border-box"
            display={isOpen || isLocked ? "flex" : "none"}
          >
            <UIMenu
              onSelect={handleOnSelect}
              activePath={window.location.href?.split("/").slice(-1).pop()}
              data={menuItems}
            />
          </Box>
        </Box>
      </Box>
    );
  },
);

Menu.displayName = "Menu";

Menu.propTypes = {
  menu: PropTypes.any,
  onToggle: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  openWidth: PropTypes.number,
  closeWidth: PropTypes.number,
  onToggleLock: PropTypes.func,
  isOpen: PropTypes.bool,
};

Menu.defaultProps = {
  openWidth: 200,
  closeWidth: 26,
};

export default Menu;
