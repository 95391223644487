import { Box, Button, Center, Divider, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { useAsInternalState } from "../../../hooks/shared/use-as-internal-state";
import PropTypes from "prop-types";
import PropRepresentation from "./flow-settings-tab/prop-representation";
import AddSchemaPropComponent from "./flow-settings-tab/add-schema-prop-component";
import EditSchemaProp from "./flow-settings-tab/edit-schema-prop";

const createStringProp = () => ({
  type: "string",
});

const addProperty = (schema, name, path) => {
  const pathElems = path.split(".");
  let tmpSchema = schema;
  for (let elemIndex in pathElems) {
    if (elemIndex == pathElems.length - 1) {
      tmpSchema.properties[pathElems[elemIndex]] = {
        ...tmpSchema.properties[pathElems[elemIndex]],
        properties: {
          ...tmpSchema.properties[pathElems[elemIndex]].properties,
          [name]: createStringProp(),
        },
      };
    } else {
      tmpSchema = tmpSchema.properties[pathElems[elemIndex]];
    }
  }
  return schema;
};

const editProperty = (schema, path, newProp) => {
  const pathElems = path.split(".");
  let tmpSchema = schema;

  for (let elemIndex in pathElems) {
    if (elemIndex == pathElems.length - 1) {
      tmpSchema.properties[pathElems[elemIndex]] = newProp;
    } else {
      tmpSchema = tmpSchema.properties[pathElems[elemIndex]];
    }
  }

  return schema;
};

const deleteProperty = (schema, path) => {
  const pathElems = path.split(".");
  let tmpSchema = schema;
  for (let elemIndex in pathElems) {
    if (elemIndex == pathElems.length - 1) {
      delete tmpSchema.properties[pathElems[elemIndex]];
    } else {
      tmpSchema = tmpSchema.properties[pathElems[elemIndex]];
    }
  }
  return schema;
};

const getProperty = (schema, path) => {
  const pathElems = path.split(".");
  let tmpSchema = schema;

  for (let elemIndex in pathElems) {
    tmpSchema = tmpSchema.properties[pathElems[elemIndex]];
  }

  return tmpSchema;
};

const FlowSettingsTab = ({
  modelName,
  model,
  updateModel,
  compact,
  editEnabled,
}) => {
  const [selection, setSelection] = useState(null);

  const [schema, setSchema] = useAsInternalState(
    compact ? model.flowSettings[modelName] : model[modelName],
  );

  if (
    !schema ||
    (schema && typeof schema === "object" && Object.keys(schema).length === 0)
  ) {
    setSchema({
      $schema: "http://json-schema.org/draft-04/schema#",
      type: "object",
      properties: {},
      required: [],
    });
  }

  const handleAddProperty = (name, path) => {
    if (path) {
      setSchema({ ...addProperty(schema, name, path) });
    } else {
      setSchema({
        ...schema,
        properties: { ...schema.properties, [name]: createStringProp() },
      });
    }
  };

  const handleIsRequired = (selection, isRequired, schema) => {
    let tmpSchema = schema;

    if (isRequired) {
      tmpSchema = {
        ...tmpSchema,
        required: [...tmpSchema.required, selection],
      };
    } else {
      tmpSchema = {
        ...tmpSchema,
        required: tmpSchema.required.filter((el) => el !== selection),
      };

      if (tmpSchema.required.length < 1) {
        delete tmpSchema.required;
      }
    }

    setSchema(tmpSchema);
  };

  return !editEnabled ? (
    "Input parameters edit not supported in selected block type."
  ) : schema && schema.properties ? (
    <Box display="flex" height="100%" width="100%" flexDirection="row">
      <Box width="50%" flex="1">
        {Object.keys(schema.properties).map((propKey) => {
          return (
            <PropRepresentation
              key={`prop-key-${propKey}`}
              prop={schema.properties[propKey]}
              propKey={propKey}
              path={propKey}
              onAdd={handleAddProperty}
              selection={selection}
              onSelectionChange={(newSelection) =>
                setSelection(selection === newSelection ? null : newSelection)
              }
              isRequired={(schema?.required ?? []).includes[propKey]}
            />
          );
        })}
        <AddSchemaPropComponent onAdd={handleAddProperty} />
      </Box>
      <Center height="100%">
        <Divider height="100%" orientation="vertical" />
      </Center>
      <Stack width="50%" flex="1">
        <Button
          margin="0px 8px"
          onClick={() =>
            updateModel(
              compact
                ? {
                    ...model,
                    flowSettings: {
                      ...model.flowSettings,
                      [modelName]: schema,
                    },
                  }
                : { ...model, [modelName]: schema },
            )
          }
        >
          Update schema
        </Button>
        <Divider />
        {selection && (
          <EditSchemaProp
            isRequired={schema.required && schema.required.includes(selection)}
            onRequiredChange={(value) =>
              handleIsRequired(selection, value, schema)
            }
            prop={getProperty(schema, selection)}
            onPropChange={(newProp) => {
              setSchema({ ...editProperty(schema, selection, newProp) });
            }}
            onPropDelete={() =>
              Promise.all([
                setSelection(null),
                setSchema({ ...deleteProperty(schema, selection) }),
              ])
            }
          />
        )}
      </Stack>
    </Box>
  ) : null;
};

FlowSettingsTab.propTypes = {
  modelName: PropTypes.string.isRequired,
  model: PropTypes.any.isRequired,
  updateModel: PropTypes.func.isRequired,
  compact: PropTypes.bool,
  editEnabled: PropTypes.bool,
};

export default FlowSettingsTab;
