import { useCallback, useContext, useEffect, useState } from "react";
import {
  deleteComponents,
  getBaseComponentsList,
} from "../../services/components-service";
import { Button, Box, HStack } from "@chakra-ui/react";
import EchoRouterContext from "../echo-router/echo-router-context";
import DataGrid from "../shared/echo-components/base-components/components/datagrid-component/datagrid.component";
import { showErrorToast } from "../shared/echo-error-toast";
import { toast } from "react-toastify";

const columns = [
  {
    name: "name",
  },
  {
    name: "description",
  },
  {
    name: "componentPurpose",
  },
  {
    name: "type",
  },
];

const ComponentsPage = () => {
  const [components, setComponents] = useState([]);
  const [selections, setSelections] = useState([]);
  const { openPage } = useContext(EchoRouterContext);

  const loadData = (callback) => {
    getBaseComponentsList(null).then(callback).catch(showErrorToast);
  };

  const handleRowSelect = (row) =>
    openPage(
      `/componentDesigner/${row._id}`,
      { callback: () => loadData(setComponents) },
      `${row.displayName} - Designer`,
      true,
    );

  const handleOnNewComponentClick = () =>
    openPage(
      "/componentDesigner/",
      { callback: () => loadData(setComponents) },
      "New component - Designer",
      true,
    );

  const handleOnDeleteComponentClick = useCallback(() => {
    deleteComponents(selections)
      .then(() => {
        toast.success("Components deleted successfully");
        setSelections([]);
      })
      .catch(showErrorToast);
  }, [selections]);

  const handleSelectionsChange = useCallback((selections) => {
    setSelections(selections);
  }, []);

  useEffect(() => {
    let isMounted = true;

    loadData((result) => {
      if (isMounted && (!Array.isArray(selections) || !selections.length)) {
        setComponents(result);
      }
    });
    return () => (isMounted = false);
  }, [selections]);

  return (
    <Box
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <HStack padding="4px 8px">
        <Button color="primary" onClick={handleOnNewComponentClick}>
          Nowy komponent
        </Button>
        <Button
          isDisabled={!Array.isArray(selections) || !selections.length}
          color="primary"
          onClick={handleOnDeleteComponentClick}
        >
          Usuń zaznaczone
        </Button>
      </HStack>
      <Box padding="0px 10px" height="100%">
        <DataGrid
          objectKey="COMPONENT_LIST"
          value={components}
          columns={columns}
          onRowClick={(row) => handleRowSelect(row)}
          pageSize={15}
          selectionMode="multiple"
          onSelectChange={handleSelectionsChange}
        />
      </Box>
    </Box>
  );
};

ComponentsPage.propTypes = {};

ComponentsPage.defaultProps = {};

export default ComponentsPage;
