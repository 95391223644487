export const readStateBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const { name } = systemParams;
        if (typeof params[1]?.path !== "string" && !staticParams.path) {
          const msg = 'Error. "name" parameter must be specified.';
          window.console.error(msg);
          throw new Error(msg);
        }

        let path =
          (typeof params[1]?.path === "string"
            ? params[1].path
            : staticParams.path) || "<name>";

        if (!path.includes("formSource.") && !path.includes(".")) {
          path = `formSource.${path}`;
        }

        const fixedPath =
          path === undefined || path === null
            ? path
            : path.replace("<name>", name);

        Promise.resolve([componentContext.functions.getStateProp(fixedPath)])
          .then(([res]) => resolve(res))
          .catch(reject);
      }),
  };
};
