import { arrayEqual } from "@echo/tools";
import { useCustomEffect } from "@echo/tools/src/lib/hooks/use-custom-effect";

const checkProperties = (model, deps) => {
  const fixedDepsString = (deps || "").replace(" ", "");

  const depsArrayKeys = fixedDepsString ? fixedDepsString.split(",") : [];

  return (
    depsArrayKeys.length === 0 ||
    (depsArrayKeys.length > 0 &&
      !depsArrayKeys.some((key) => typeof (model || {})[key] === "undefined"))
  );
};

export const useDataGridDataLoader = (
  designerMode,
  loadData,
  dispatch,
  value,
  dataSource,
  page,
  itemsPerPage,
  sort,
  refreshKey,
  columnFilters,
  error,
  mounted,
  model,
  deps,
  filterOptions,
) => {
  useCustomEffect(
    () => {
      let isMounted = true;

      if (!designerMode && checkProperties(model, deps)) {
        loadData((action) => {
          if (isMounted) {
            dispatch(action);
          }
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      [
        value,
        dataSource,
        page,
        itemsPerPage,
        sort,
        refreshKey,
        columnFilters,
        error,
        mounted,
        deps,
        filterOptions,
        columnFilters,
      ],
      { state: { ...model }, deps },
    ],
    (a1, a2) => {
      let depsEqual = true;

      const fixedDepsString = (a1[1]?.deps || "").replace(" ", "");

      const depsArray = fixedDepsString ? fixedDepsString.split(",") : [];

      for (let depName of depsArray) {
        if (
          depName &&
          a1[1]?.state &&
          a2[1]?.state &&
          a1[1]?.state[depName] !== a2[1]?.state[depName]
        ) {
          depsEqual = false;
          break;
        }
      }

      return arrayEqual(a1[0], a2[0]) && depsEqual;
    },
  );
};
