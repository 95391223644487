export const getFirstDraggableBelow = (event) => {
  const x = event.clientX,
    y = event.clientY;

  const elements = document.elementsFromPoint(x, y);

  for (let elemIndex in elements) {
    const elem = elements[elemIndex];
    if (elem) {
      const isDraggable = elem.getAttribute("draggable");
      if (isDraggable === "true") {
        return elem;
      }
    }
  }

  return null;
};
