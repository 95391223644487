import PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { getUserList } from "../../services/user-services";
import { Input } from "@echo/ui";
import { showErrorToast } from "../shared/echo-error-toast";
import { changeContext } from "../../services/login-service";
import { setToken } from "../../services/token-service";

const ChangeUserContextModal = ({ isOpen, onClose }) => {
  const [search, setSearch] = useState();
  const [users, setUsers] = useState([]);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    getUserList(search || "")
      .then(setUsers)
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error. Cannot load available objects",
          location: "Backend. Available objects",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        }),
      );
  }, [search]);

  const handleSearchChange = useCallback(
    (e) => setSearch(e?.target?.value),
    [],
  );

  const handleSelectionChange = useCallback(
    (e) => setSelection(e?.target?.value),
    [],
  );

  const handleSelect = useCallback(() => {
    if (!isNaN(selection)) {
      changeContext(Number(selection)).then((token) => {
        setToken(token);
        window.location.reload();
      });
    }
  }, [selection]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change user context</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            width="248px"
            label="Search"
            value={search}
            onChange={handleSearchChange}
          />
          <Select
            value={selection}
            onChange={handleSelectionChange}
            ml="5px"
            mt="4px"
            width="248px"
            placeholder="Select option"
          >
            {users &&
              users.map((user) => (
                <option key={`user-${user.id}`} value={user.id}>
                  {user.login}
                </option>
              ))}
          </Select>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={!selection}
            colorScheme="blue"
            mr={3}
            onClick={handleSelect}
          >
            Select
          </Button>
          <Button colorScheme="red" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ChangeUserContextModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ChangeUserContextModal.defaultProps = {
  isOpen: false,
};

export default ChangeUserContextModal;
