// @flow

import React, { useState, useEffect, useMemo, useRef } from "react";
import { Box, Button, Input, Th } from "@chakra-ui/react";
import type { Column, Filter as TFilter } from "../../datagrid-v2";
import { useDatagridContext } from "../../hooks/useDatagridContext";
import { Filter } from "../filter/filter";
import { DatePicker } from "../datepicker/datepicker";
import { ColumnResizer } from "../column-resizer/column-resizer";
import { BsTrash } from "react-icons/bs";
import { format } from "date-fns";

type FilterCellType = "date" | "string";

type CellProps = {
  column: Column,
  width: string,
  type?: FilterCellType,
};

export const FilterCell = (props: CellProps) => {
  const { filter, onFilterValueChange, onFilterTypeChange, column } = props;
  const { type, width } = column;

  const filterOptions = [
    { id: 0, label: "Contains", value: "contains" },
    { id: 1, label: "Not contains", value: "notContains" },
    { id: 2, label: "Equal", value: "equal" },
    { id: 3, label: "Not equal", value: "notEqual" },
    { id: 4, label: "Greater than", value: "greaterThan" },
    { id: 5, label: "Greater or equal", value: "greaterOrEqual" },
    { id: 6, label: "Lower than", value: "lowerThan" },
    { id: 7, label: "Lower or equal", value: "lowerOrEqual" },
    { id: 8, label: "Empty", value: "empty" },
    { id: 9, label: "Not Empty", value: "notEmpty" },
  ];

  return (
    <Th
      width={width || "auto"}
      minWidth={width || "auto"}
      maxWidth={width || "auto"}
      position="relative"
      overflow="hidden"
      padding="0"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        padding="4px 16px"
        margin="0"
        gap="5px"
        overflow="hidden"
        position="relative"
      >
        {type.includes("date") ? (
          <Box display="flex" alignItems="center">
            <DatePicker
              value={filter?.value || ""}
              onChange={(val) =>
                onFilterValueChange(val, column.name, column.type)
              }
              dateFormat={column.format || "dd-MM-yyyy"}
              size="xs"
            />
            {filter?.value && (
              <Button
                onClick={() =>
                  onFilterValueChange("", column.name, column.type)
                }
                variant="ghost"
                padding="0"
              >
                <BsTrash height="100%" width="100%" />
              </Button>
            )}
          </Box>
        ) : (
          <Input
            padding="2px 4px"
            variant="outline"
            size="xs"
            width="100%"
            value={filter?.value || ""}
            onChange={(e) =>
              onFilterValueChange(e.target.value, column.name, column.type)
            }
          />
        )}
        <Filter
          options={filterOptions}
          selected={filter?.type || "contains"}
          onChange={(e) =>
            onFilterTypeChange(e.target.value, column.name, column.type)
          }
        />
      </Box>
      <ColumnResizer column={column} />
    </Th>
  );
};
