import { showErrorToast } from "../../../../../../shared/echo-error-toast";
import { useEffect, useReducer } from "react";
import { treeStateReducer } from "../effects/tree-state-reducer";
import { useSourceSchema } from "./use-source-schema";

const isSelectionEqual = (s1, s2) => {
  if (s1 === s2 || (typeof s1 === "undefined" && typeof s2 === "undefined")) {
    return true;
  }
  if (Array.isArray(s1) && Array.isArray(s2) && s1.length === s2.length) {
    for (let i = 0; i < s1.length; i++) {
      if (s1[i].path !== s2[i].path || s1[i].label !== s2[i].label) {
        return false;
      }
    }
  } else {
    return false;
  }
  return true;
};

export const useTreeSource = ({
  dataSource,
  context: componentContext,
  onChange,
  defaultUnselected,
}) => {
  const [schema] = useSourceSchema({ dataSource, componentContext });
  const [state, dispatchState] = useReducer(treeStateReducer, {
    __v: 0,
    selection: null,
  });

  useEffect(() => {
    if (
      schema &&
      typeof schema === "object" &&
      state.__v === 0 &&
      !defaultUnselected
    ) {
      const schemaKeys = Object.keys(schema);
      if (schemaKeys.length > 0 && schema[schemaKeys[0]].__meta) {
        const selection = schema[schemaKeys[0]].__meta;
        Promise.resolve(
          onChange(
            selection[0].value,
            selection[0].label,
            selection.map((s) => s.path).join("."),
            selection.map((s) => s.label).join(".")
          )
        );
        dispatchState({
          type: "SET_SELECTION",
          payload: { selection },
        });
      }
    }
  }, [defaultUnselected, onChange, schema, state.__v]);

  const handleChangeSelectionPath = (selection) => {
    if (!isSelectionEqual(state.selection, selection)) {
      Promise.resolve(
        onChange(
          selection[selection.length - 1].value,
          selection[selection.length - 1].label,
          selection.map((s) => s.path).join("."),
          selection.map((s) => s.label).join("."),
          selection
        )
      ).catch((err) =>
        showErrorToast({
          reasonTitle: "Error while changing treeView state.",
          location: "TreeView component",
          shortMessage: err.toString().slice(0, 300),
          message: err.toString(),
        })
      );
      dispatchState({ type: "SET_SELECTION", payload: { selection } });
    } else {
      Promise.resolve(onChange(null, null, null, null)).catch(() => {});
      dispatchState({ type: "UNSET_SELECTION" });
    }
  };

  return [
    schema,
    state,
    {
      onSelectChange: handleChangeSelectionPath,
    },
  ];
};
