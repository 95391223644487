import { useEffect, useRef } from "react";

export const useTimeoutEffect = (callback, deps, condition, timeout) => {
  const timeoutRef = useRef();

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (condition) {
      timeoutRef.current = setTimeout(() => {
        callback();
      }, timeout);
    }

    return () => clearTimeout(timeoutRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
