import { arrayEqual } from "@echo/tools";
import { useCustomEffect } from "@echo/tools/src/lib/hooks/use-custom-effect";
import { checkProperties } from "../utils/check-properties";

export const useDataGridListener = (
  designerMode,
  loadData,
  dispatch,
  value,
  dataSource,
  page,
  itemsPerPage,
  sort,
  columnFilters,
  globalFilter,
  error,
  mounted,
  refreshPolicy,
  model,
  deps,
) => {
  useCustomEffect(
    () => {
      let isMounted = true;

      const refreshFunc = () => {
        if (!designerMode && checkProperties(model, deps)) {
          loadData(({ data }) => {
            if (isMounted) {
              dispatch({ type: "DATA_ONLY", data });
            }
          });
        }
      };

      window.addEventListener("save-form", refreshFunc);

      return () => {
        isMounted = false;
        window.removeEventListener("save-form", refreshFunc);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      [
        value,
        dataSource,
        page,
        itemsPerPage,
        sort,
        columnFilters,
        globalFilter,
        error,
        mounted,
      ],
      { state: { ...model }, deps },
    ],
    (a1, a2) => {
      let depsEqual = true;

      const fixedDepsString = (a1[1]?.deps || "").replace(" ", "");

      const depsArray = fixedDepsString ? fixedDepsString.split(",") : [];

      for (let depName of depsArray) {
        if (
          depName &&
          a1[1]?.state &&
          a2[1]?.state &&
          a1[1]?.state[depName] !== a2[1]?.state[depName]
        ) {
          depsEqual = false;
          break;
        }
      }

      return arrayEqual(a1[0], a2[0]) && depsEqual;
    },
  );
};
