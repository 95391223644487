const changePropertyInArrayElement = ({
  array,
  searchIdentifier,
  id,
  propertyName,
  newValue,
}) => {
  if (!Array.isArray(array)) {
    throw new Error("'array' property is not array");
  }
  if (!searchIdentifier || typeof searchIdentifier !== 'string') {
    throw new Error("'searchIdentifier' property is not valid");
  }
  if (!id || typeof id !== 'string') {
    throw new Error("'id' property is not valid");
  }
  if (!propertyName || typeof propertyName !== 'string') {
    throw new Error("'propertyName' property is not valid");
  }
  const newArray = [...array];
  const elementIndex = newArray.findIndex(
    (elem) => elem[searchIdentifier] === id
  );
  if (elementIndex === -1) {
    return newArray;
  }
  newArray[elementIndex] = {
    ...newArray[elementIndex],
    [propertyName]: newValue,
  };
  return newArray;
};

export default changePropertyInArrayElement;
