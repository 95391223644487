export const convertDatabaseType = (type) => {
  switch (type.toLowerCase()) {
    case "bit":
      return "boolean";
    case "date":
    case "datetime":
      return "datetime";
    case "tinyint":
    case "int":
      return "number";
    default:
      return "string";
  }
};
