import PropTypes from "prop-types";
import PropertiesValueInput from "../cell-components/properties-table-input";

const InputValueCell = ({ propValue, onChange, disabled }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: propValue ? "space-between" : "flex-end",
        flexDirection: "row",
      }}
    >
      <PropertiesValueInput
        value={propValue}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

InputValueCell.propTypes = {
  propValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

InputValueCell.defaultProps = { propValue: "", disabled: false };

export default InputValueCell;
