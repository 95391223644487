import PropTypes from "prop-types";
import { useDataSource } from "../../../../hooks/use-datasource";
import { useApiContext } from "../../../../../../../echo-components/api-context/use-api-context";
import { EchoListItem } from "./list-item";
import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const ListRenderView = (props) => {
  const { dataSource, id, guid, nodeId, context, enableRowAdd } = props;

  const [, component, mode] = useApiContext(id, guid, nodeId);

  const [enumerableElements] = useDataSource(
    dataSource,
    context,
    {
      callerId: component?.id,
      callerGuid: component?.guid,
      callerNodeId: component?.nodeId,
    },
    {}, //staticParams,
    [], //params
    true,
    (prev, next) => Array.isArray(next) && prev?.length !== next?.length,
  );

  const [rows, setRows] = useState();

  const getEmptyRow = (arr) => {
    const el = arr[0];

    const keys = Object.keys(el);
    const empty = keys.reduce((acc, curr) => {
      return { ...acc, [curr]: null };
    }, {});

    return empty;
  };

  const handleClick = () => {
    setRows((prev) => [...prev, getEmptyRow(prev)]);
  };

  useEffect(() => {
    setRows(enumerableElements);
  }, [enumerableElements]);

  return (
    <>
      {rows &&
        rows.length > 0 &&
        rows.map((el, idx) => (
          <EchoListItem {...props} values={el} mode={mode} key={idx} />
        ))}
      {enableRowAdd && <Button onClick={handleClick}>Dodaj</Button>}
    </>
  );
};

ListRenderView.propTypes = {
  enableRowAdd: PropTypes.bool,
  childs: PropTypes.array,
  dataSource: PropTypes.func,
  id: PropTypes.number,
  guid: PropTypes.string,
  nodeId: PropTypes.number,
  context: PropTypes.any,
  designerMode: PropTypes.any,
  path: PropTypes.any,
  state: PropTypes.any,
  stateDispatch: PropTypes.any,
  localContext: PropTypes.any,
};
