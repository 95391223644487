import { executeBlock } from "../../process-executor/process-executor";

export const executeCallback = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params) =>
      new Promise((resolve, reject) => {
        const component = componentContext?.component;
        if (!component) {
          reject("Component cannot be null");
        }
        if (!staticParams?.name) {
          reject("The 'name' property must be specified");
        }
        const callbackEvents = component?.callbackEvents;
        const callbackEvent = callbackEvents[staticParams.name];

        if (!callbackEvent) {
          reject("Callback event not found");
        }

        const { name, ...restParams } = staticParams;
        const callbackProcess = () =>
          executeBlock(
            staticParams.useRootContext
              ? callbackEvent?.source?.context
              : componentContext,
            callbackEvent.id,
            { ...restParams, callbackEventName: name } ?? {},
            params,
            {
              callbackEventName: callbackEvent?.source?.name,
              source: callbackEvent?.source,
              callerId: callbackEvent?.source?.callerId,
              callerGuid: callbackEvent?.source?.callerGuid,
              callerNodeId: callbackEvent?.source?.callerNodeId,
            },
          );

        Promise.resolve(callbackProcess()).then(resolve).catch(reject);
      }),
  };
};
