/* eslint-disable react/prop-types */
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import "../../../../styles/form-designer/form-designer.css";
import SideComponent from "./side-component";

const SidePanel = ({
  dragMode,
  children,
  components,
  onComponentsChange,
  setMoveButtons,
}) => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
      // height="100%"
      flex={1}
      minHeight={0}
    >
      <Box
        overflowY="auto"
        boxSizing="border-box"
        // position="absolute"
        width="100%"
        // height="100%"
        display="flex"
        flexDirection="column"
        flex={1}
        minHeight={0}
      >
        {children}
      </Box>
      {/* Left side*/}
      <SideComponent
        dragMode={dragMode}
        position="left"
        components={components}
        onComponentsChange={onComponentsChange}
      />
      {/* Right side*/}
      <SideComponent
        dragMode={dragMode}
        position="right"
        components={components}
        onComponentsChange={onComponentsChange}
        setMoveButtons={setMoveButtons}
      />
    </Box>
  );
};

SidePanel.propTypes = {
  // TODO shape
  children: PropTypes.node.isRequired,
};

export default SidePanel;
