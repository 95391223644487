import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const DesignerShim = ({ dragMode }) => {
  return (
    <Box
      display='flex'
      width='100%'
      height='100%'
      alignItems='center'
      justifyContent='center'
      transition='font-size 0.2s ease-in'
      fontSize={dragMode ? 'larger' : undefined}
    >
      Drag component here...
    </Box>
  );
};

DesignerShim.propTypes = {
  dragMode: PropTypes.bool,
};

DesignerShim.defaultProps = {};

export default DesignerShim;
