import { useMemo } from "react";

/**
 * Scale
 * @param {number} size
 * @param {number} scale
 * @param {('ZOOM_IN'|'ZOOM_OUT'|'BOTH')} [direction]
 * @return {number}
 */
export default (size, scale, direction = "ZOOM_OUT") => {
  return useMemo(() => {
    switch (direction) {
      case "ZOOM_IN":
        return scale <= 1 ? size : size / scale;
      case "ZOOM_OUT":
        return scale >= 1 ? size : size / scale;
      case "BOTH":
      default:
        return size / scale;
    }
  }, [direction, scale, size]);
};
