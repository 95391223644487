import defaultTemplate from './templates/default-template';
import javascriptTemplate from './templates/javascript-template';

const getTemplate = (type) => {
  switch (type) {
    case 'javascript':
      return javascriptTemplate;
    default:
      return defaultTemplate;
  }
};

export const extractValue = (inputString, templateType) => {
  const template = getTemplate(templateType);
  return template.deserialize(inputString);
};

export const parseValue = (inputString, templateType) => {
  const template = getTemplate(templateType);
  return template.serialize(inputString);
};
