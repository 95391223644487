export const getDragOverColor = (color) => {
  let r, g, b;

  if (color.match(/^rgb/)) {
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/,
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  const changeFactor = 0.2;
  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return `rgb(${r - changeFactor * r}, ${g - changeFactor * g}, ${
      b - changeFactor * b
    })`;
  } else {
    return `rgb(${r + changeFactor * (Number(r) || 255)}, ${
      g + changeFactor * (Number(g) || 255)
    }, ${b + changeFactor * (Number(b) || 255)})`;
  }
};
