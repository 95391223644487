import { Box } from "@chakra-ui/react";
import { FiCpu } from "react-icons/fi";

const StandardBlock = () => {
  return (
    <Box
      className="disable-ppz-pan"
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="white"
      width="80px"
      height="80px"
      backgroundColor="orange.600"
    >
      <FiCpu
        className="disable-ppz-pan"
        size="35px"
        onMouseMove={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Box>
  );
};

export default StandardBlock;
