import { executeBlock } from "../../../../../../../process-executor/process-executor";
import { showErrorToast } from "../../../../../echo-error-toast";

const resolveDatasource = async (dataSource, props) => {
  const { name, context } = props;
  try {
    const res = await executeBlock(
      context,
      dataSource.id,
      dataSource.staticParams ?? {},
      [name],
      { name },
    );
    return Object.values(res[0]);
  } catch (e) {
    throw new Error(e);
  }
};

const columnMapper = async (c, props) => {
  if (!c.editDataSource) return c;

  try {
    return {
      ...c,
      editDataSource: await resolveDatasource(c.editDataSource, props),
    };
  } catch (e) {
    throw new Error(e);
  }
};

export const resolveColumnsDatasource = async (columns, props) => {
  const promiseArr = columns.map((c) => columnMapper(c, props));
  try {
    const withDatasource = await Promise.all(promiseArr);
    return withDatasource;
  } catch (e) {
    showErrorToast(e);
    return columns;
  }
};
