import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  Collapse,
  HStack,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FiChevronDown, FiChevronRight } from "react-icons/fi";

const TreeView = ({
  canSelect,
  selected,
  content,
  onSelectChange,
  children,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const CollapseButton = children ? (
    <IconButton variant="ghost" size="sm" onClick={onToggle}>
      {isOpen ? <FiChevronDown /> : <FiChevronRight />}
    </IconButton>
  ) : (
    <span style={{ width: "32px", height: "32px" }} />
  );

  return (
    <>
      <HStack display="flex" flexDirection="row">
        {canSelect ? (
          <>
            {CollapseButton}
            <Checkbox isChecked={selected} onChange={onSelectChange}>
              {content}
            </Checkbox>
          </>
        ) : (
          <>
            {CollapseButton}
            <Box onClick={onToggle} cursor="pointer">
              {content}
            </Box>
          </>
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        {children && <Box marginLeft="8px">{children}</Box>}
      </Collapse>
    </>
  );
};

TreeView.propTypes = {
  children: PropTypes.node,
  onSelectChange: PropTypes.func,
  content: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  canSelect: PropTypes.bool,
};

TreeView.defaultProps = {};

export default TreeView;
