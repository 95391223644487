import PropTypes from 'prop-types';

const CollapseButton = ({ position, label, onClick }) => {
  return (
    <button className='collapse-button' onClick={onClick}>
      <div className='rotation-wrapper-outer'>
        <div className='rotation-wrapper-inner'>
          <span className={`button-content-rotate-${position}`}>{label}</span>
        </div>
      </div>
    </button>
  );
};

CollapseButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
};

CollapseButton.defaultProps = {
  onClick: () => {},
};

export default CollapseButton;
