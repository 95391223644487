import { Checkbox } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useMemo } from "react";

export const BoolEditComponent = ({ value, onChange }) => {
  const isChecked = useMemo(() => {
    if (value == 1 || value === true || value === "true") return true;
    return false;
  }, [value]);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) onChange(1);
    else onChange(0);
  };

  return <Checkbox isChecked={isChecked} onChange={handleChange} />;
};

BoolEditComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
