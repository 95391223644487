import { showErrorToast } from "../../components/shared/echo-error-toast";
import { validateParamExists } from "./utils/validate-param";

const validateParams = (staticParams) => {
  const errorMessages = [];
  errorMessages.push(
    validateParamExists(staticParams.componentName, "componentName", true)
  );
  errorMessages.push(
    validateParamExists(staticParams.propertyName, "propertyName", true)
  );
  // errorMessages.push(validateParamExists(staticParams.block, 'block', true));

  return errorMessages.filter((m) => m);
};

const replaceProperty = (
  componentDefinition,
  componentName,
  propertyName,
  propertyDefinition
) => {
  const componentDefinitionClone = { ...componentDefinition };

  if (componentDefinitionClone?.componentProps?.name === componentName) {
    componentDefinitionClone.componentProps[propertyName] = propertyDefinition;
    return componentDefinitionClone;
  }
  if (
    componentDefinitionClone.childrenComponents &&
    Array.isArray(componentDefinitionClone.childrenComponents) &&
    componentDefinitionClone.childrenComponents.length > 0
  ) {
    for (let component of componentDefinitionClone.childrenComponents) {
      const result = replaceProperty(
        component,
        componentName,
        propertyName,
        propertyDefinition
      );
      if (result !== null) {
        return componentDefinitionClone;
      }
    }
  }

  return null;
};

export const getChangeComponentPropBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams) =>
      new Promise((resolve, reject) => {
        const {
          componentName,
          propertyName,
          block,
          boolValue,
          stringValue,
          numberValue,
        } = staticParams;
        const errors = validateParams(staticParams);
        if (errors && Array.isArray(errors) && errors.length > 0) {
          const errorMessage = errors.join(", ");
          showErrorToast({
            reasonTitle: "Cannot change component prop.",
            location: "changeComponentProp",
            shortMessage: " Please fill all required block parameters.",
            message: errorMessage,
          });
          reject(new Error(errorMessage));
        }

        let toReplace = null;

        if (block) {
          toReplace = {
            staticParams: staticParams.blockStaticParams,
            type: "PROCESS",
            id: block,
            name: `${componentName}_${propertyName}_PROCESS_REPLACEMENT`,
          };
        } else if (boolValue) {
          switch ((boolValue || "").toLowerCase()) {
            case "true":
              toReplace = true;
              break;
            case "false":
              toReplace = false;
              break;
            default:
              toReplace = undefined;
          }
        } else if (stringValue) {
          toReplace = stringValue;
        } else if (numberValue) {
          toReplace = isNaN(numberValue) ? undefined : Number(numberValue);
        }

        const resultComponentDefinition = replaceProperty(
          componentContext.component,
          componentName,
          propertyName,
          toReplace
        );

        if (resultComponentDefinition) {
          componentContext.functions.updateComponentDefinition(
            resultComponentDefinition
          );

          resolve(true);
        } else {
          reject("Cannot find component with specified name");
        }
      }),
  };
};
