import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Box,
  IconButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FiMapPin } from "react-icons/fi";
import ChangeCompanyModal from "./change-company-modal";

const CompanyHeaderComponent = ({ company }) => {
  const changeCompanyModal = useDisclosure();
  if (company) {
    return (
      <Box
        display="flex"
        flexFlow="row"
        alignItems="center"
        alignContent="center"
        justifyContent="left"
        color="white"
        gap="8px"
      >
        <Popover>
          <PopoverTrigger>
            <IconButton variant="outline">
              <FiMapPin color="white" size="16px" />
            </IconButton>
          </PopoverTrigger>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader color="black">Company details</PopoverHeader>
            <PopoverBody>
              <Box
                color="black"
                display="flex"
                flexFlow="column"
                width="100%"
                height="100%"
              >
                {company.code && <Box>Code: {company.code}</Box>}
                {company.address && <Box>Address: {company.address}</Box>}
                {company.zip && <Box>Zip: {company.zip}</Box>}
                {company.city && <Box>City: {company.city}</Box>}
                {company.trn && <Box>Trn: {company.trn}</Box>}
                {company.databaseName && (
                  <Box>Database name: {company.databaseName}</Box>
                )}
                <Button
                  onClick={() => changeCompanyModal?.onOpen()}
                  variant="outlined"
                >
                  Zmień spółkę
                </Button>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {company.name}
        <ChangeCompanyModal {...changeCompanyModal} />
      </Box>
    );
  } else {
    return null;
  }
};

CompanyHeaderComponent.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    code: PropTypes.string,
    databaseName: PropTypes.string,
    guid: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    trn: PropTypes.string,
    zip: PropTypes.string,
  }),
};

CompanyHeaderComponent.defaultProps = {};

export default CompanyHeaderComponent;
