const elements = [
  'window.Function',
  ['ctx', 'bodyId', 'params', 'staticParams'],
];

export default {
  serialize: (valueString) => {
    return JSON.stringify([...elements, valueString]);
  },
  deserialize: (valueString) => {
    if (!valueString) {
      return '';
    }
    try {
      const array = JSON.parse(valueString);
      return array[2];
    } catch (ex) {
      window.console.error(ex);
      return 'Parse error. See console for details.';
    }
  },
};
