import { executeBlock } from "../process-executor";
import { getMimeTypeFromExtension } from "./utils/get-mime-type-from-extension";

const lowerFirstChar = (str) => {
  const arr = str.split("");
  const firstChar = arr[0].toLowerCase();
  const result = [firstChar, ...arr.slice(1, arr.length)];
  return result.join("");
};

const lowerObjectProperties = (obj) => {
  const keys = Object.keys(obj);

  const result = keys.reduce((acc, curr) => {
    const key = lowerFirstChar(curr);
    return {
      ...acc,
      [key]: obj[curr],
    };
  }, {});

  return result;
};

const getBlob = (byteNums, mimeType) => {
  const byteArray = new Uint8Array(byteNums);
  const blob = new Blob([byteArray], { type: mimeType });
  return blob;
};

const createLink = (blob, name) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  return link;
};

const base64ToDownloadableFile = (file) => {
  const { value, name, extension } = file;
  const byteCharactes = atob(value);
  const byteNumbers = new Array(byteCharactes.length);
  const mimeType = getMimeTypeFromExtension(extension);

  for (let i = 0; i < byteCharactes.length; i++) {
    byteNumbers[i] = byteCharactes.charCodeAt(i);
  }

  const blob = getBlob(byteNumbers, mimeType);
  const link = createLink(blob, name);
  return link;
};

const getFiles = async (ctx, filesSource) => {
  try {
    const { id } = filesSource;
    const res = await executeBlock(ctx, id, {}, [], {});
    return res.map(lowerObjectProperties);
  } catch (e) {
    throw new Error(e);
  }
};

const downloadFromLink = (link) => {
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFiles = (files) => {
  const links = files.map(base64ToDownloadableFile);
  links.forEach(downloadFromLink);
};

const isFileObj = (obj) =>
  Object.keys(obj).includes((k) => k.toLowerCase() === "value");

const checkType = (filesSource) => {
  if (filesSource.type === "PROCESS") return "process";

  if (typeof filesSource === "object") {
    const isFile = isFileObj(filesSource);
    const isArray = Array.isArray(filesSource) && isFileObj(filesSource[0]);

    if (isArray) return "array";
    if (isFile) return "file";
  }

  return "invalid";
};

export const downloadFileBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { filesSource } = staticParams;

      const type = checkType(filesSource);

      switch (type) {
        case "process": {
          const files = await getFiles(componentContext, filesSource);
          downloadFiles(files);
          break;
        }
        case "array": {
          downloadFiles(filesSource);
          break;
        }
        case "file": {
          base64ToDownloadableFile(filesSource);
          break;
        }
        case "invalid":
          throw new Error("Wrong type of filesSource.");
      }

      return staticParams;
    },
  };
};
