import {
  caseInsensitivePropertyExists,
  getPropertyCaseInsensitive,
} from "../property/case-insensitive-property-utils";

/**
 * Check case insensitive if object has id property that has not undefined value
 *
 * @param {any} obj - object to check if has property
 * @return {boolean}
 */
export const caseInsensitiveIdCheckExists = (obj) => {
  if (caseInsensitivePropertyExists(obj, "id")) {
    return typeof getPropertyCaseInsensitive(obj, "id") !== "undefined";
  }

  return false;
};
