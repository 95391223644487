import { mapObject } from "../../services/mapper-service";
import { executeBlock } from "../process-executor";

const getFirstProcessBlock = (block) => {
  const processLinks = block.links;
  return block.blocks.find(
    (processBlock) =>
      !processLinks.some(
        (processLink) =>
          processLink.destinationProcessBlockId === processBlock.id,
      ),
  );
};

const createNextDiagramStructure = (processBlock, diagramStructure) => {
  const structure = {
    block: diagramStructure.block,
    processBlock: processBlock,
    nextProcessLinks: diagramStructure.block.links.filter(
      (link) => link.sourceProcessBlockId === processBlock.id,
    ),
  };

  structure.next = createNext(structure);
  structure.goNext = (input) => goNext(input, structure);
  structure.getNextLink = (input) => getNextLink(input, structure);
  return structure;
};

const createNext = (diagramStructure) => {
  const processBlocks = diagramStructure.block.blocks;

  // Find next blocks
  const nextBlocksIds = diagramStructure.nextProcessLinks.map(
    (link) => link.destinationProcessBlockId,
  );
  const nextBlocks = processBlocks.filter((processBlock) =>
    nextBlocksIds.includes(processBlock.id),
  );

  return nextBlocks.map((nextBlock) =>
    createNextDiagramStructure(nextBlock, diagramStructure),
  );
};

const getNextLink = (input, diagramStructure) => {
  const resultCondition =
    Array.isArray(input) &&
    input.length > 0 &&
    typeof input[0] === "object" &&
    input[0].__resultCondition
      ? input[0].__resultCondition
      : input.__resultCondition;
  let conditionPassingLink = resultCondition
    ? diagramStructure.nextProcessLinks.find((link) => {
        return link.condition === resultCondition;
      })
    : diagramStructure.nextProcessLinks[0];

  return conditionPassingLink;
};

const goNext = (input, diagramStructure) => {
  if (!diagramStructure?.next?.length) {
    return null;
  } else if (diagramStructure.nextProcessLinks.some((link) => link.condition)) {
    const conditionPassingLink = getNextLink(input, diagramStructure);

    if (!conditionPassingLink) {
      return null;
    }

    var nextBlockId = conditionPassingLink.destinationProcessBlockId;
    const res = diagramStructure.next.find(
      (nextStructureElement) =>
        nextStructureElement.processBlock.id === nextBlockId,
    );
    return res;
  } else {
    return diagramStructure.next[0];
  }
};

const createDiagramStructure = (block) => {
  const processBlock = getFirstProcessBlock(block);
  const diagramStructure = {
    block,
    processBlock: processBlock,
    nextProcessLinks: block.links.filter(
      (link) => link.sourceProcessBlockId === processBlock.id,
    ),
  };
  diagramStructure.next = createNext(diagramStructure);
  diagramStructure.goNext = (input) => goNext(input, diagramStructure);
  diagramStructure.getNextLink = (input) =>
    getNextLink(input, diagramStructure);
  return diagramStructure;
};

const execute = (
  diagramInputParams,
  staticParams,
  params,
  diagramStructure,
  formSource,
  componentContext,
  systemParams,
) =>
  new Promise((resolve, reject) => {
    let processBlockParams = diagramStructure.processBlock.staticParams
      ? JSON.parse(diagramStructure.processBlock.staticParams)
      : {};

    Object.keys(processBlockParams).forEach((paramKey) => {
      if (
        typeof processBlockParams[paramKey] === "string" &&
        processBlockParams[paramKey].startsWith("@")
      ) {
        delete processBlockParams[paramKey];
      }
    });

    executeBlock(
      componentContext,
      diagramStructure.processBlock.blockId,
      {
        ...staticParams,
        ...processBlockParams,
        __model: staticParams.__model || formSource,
        __processBlock: diagramStructure.processBlock,
      },
      params,
      systemParams,
    )
      .then((result) => {
        const res =
          Array.isArray(result) && result.length === 1 ? result[0] : result; // TODO temporary solution. We don't have mappings

        processBlockParams = JSON.parse(
          diagramStructure.processBlock.staticParams || "{}",
        );

        let newStaticParams = Array.isArray(res)
          ? res
          : { ...diagramInputParams, ...staticParams, ...res };

        if (!Array.isArray(newStaticParams)) {
          Object.keys(processBlockParams).forEach((paramKey) => {
            if (
              typeof processBlockParams[paramKey] === "string" &&
              processBlockParams[paramKey].startsWith("@")
            ) {
              const newKey = processBlockParams[paramKey].substring(1);
              newStaticParams[paramKey] = newStaticParams[newKey];
            }
          });
        }

        const nextLink = diagramStructure.getNextLink(newStaticParams);
        const next = diagramStructure.goNext(newStaticParams);

        Promise.resolve(
          nextLink?.mappings &&
            Array.isArray(nextLink?.mappings) &&
            nextLink?.mappings?.length
            ? mapObject(nextLink?.mappings, newStaticParams)
            : newStaticParams,
        ).then((mappedObject) => {
          newStaticParams = { ...newStaticParams, ...mappedObject };

          if (next === null) {
            resolve(res);
          } else {
            execute(
              diagramInputParams,
              newStaticParams,
              res?.__params || params,
              next,
              formSource,
              componentContext,
              systemParams,
            )
              .then(resolve)
              .catch(reject);
          }
        });
      })
      .catch(reject);
  });

const getDiagramParams = (block, params) => {
  const blockParametersSchema = JSON.parse(block.inParameters);

  if (!blockParametersSchema.properties) return [];

  const { properties } = blockParametersSchema;
  const parametersNames = Object.keys(properties);
  const diagramParams = parametersNames.map((key) => ({
    name: key,
    value: params[key],
  }));

  return diagramParams;
};

export const getDiagramBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const diagramStructure = createDiagramStructure(block);
        const { formSource } = componentContext.functions.getModel();
        const diagramParams = getDiagramParams(block, staticParams);

        const processBlockParams = JSON.parse(
          diagramStructure.processBlock.staticParams || "{}",
        );

        let newStaticParams = { ...staticParams };

        if (!Array.isArray(newStaticParams)) {
          Object.keys(processBlockParams).forEach((paramKey) => {
            if (
              typeof processBlockParams[paramKey] === "string" &&
              processBlockParams[paramKey].startsWith("@")
            ) {
              const newKey = processBlockParams[paramKey].substring(1);

              newStaticParams[paramKey] =
                newStaticParams[newKey] || formSource[newKey];
            }
          });
        }

        execute(
          {
            ...staticParams,
            __processBlock: diagramStructure.processBlock,
            queryParams: [
              ...(staticParams.queryParams ?? []),
              ...diagramParams,
            ],
          },
          newStaticParams,
          params,
          diagramStructure,
          formSource,
          componentContext,
          systemParams,
        )
          .then(resolve)
          .catch(reject);
      }),
  };
};
