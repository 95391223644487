import PropTypes from 'prop-types';
import EchoComboValueCell from './echo-combo-value-cell';

const EchoBoolValueCell = ({ propName, propValue, onChange }) => {
  const handleChange = (value) => {
    onChange(
      typeof value === 'object'
        ? value
        : value === 'true'
        ? true
        : value === 'false'
        ? false
        : undefined
    );
  };

  return (
    <EchoComboValueCell
      propValue={propValue}
      propName={propName}
      onChange={handleChange}
      enableProcessSelect
      options={[
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ]}
    />
  );
};

EchoBoolValueCell.propTypes = {
  propName: PropTypes.string,
  propValue: PropTypes.any,
  onChange: PropTypes.func,
};

EchoBoolValueCell.defaultProps = {
  propValue: undefined,
};

export default EchoBoolValueCell;
