import { executeBlock, executeBlockByGroup } from "../process-executor";

export const readFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const { callerId, callerGuid, callerNodeId } = systemParams;
        const action = componentContext.functions.getReadAction(
          false,
          callerId,
          callerGuid,
          callerNodeId
        );
        if (action === null) {
          // TODO check every call
          resolve(null);
        }
        if (action.type === "PROCESS") {
          Promise.resolve([
            executeBlock(
              componentContext,
              action.id,
              staticParams,
              [
                ...(params?.filter((c) => c !== null) ?? []),
                { action: "read" },
                { isFormAction: true },
                { componentId: componentContext?.component?.id },
                { isOpen: true },
                ...(staticParams.params || []),
              ],
              systemParams
            ),
          ])
            .then(([res]) => resolve(res))
            .catch(reject);
        } else if (action.type === "BLOCK_GROUP") {
          Promise.resolve([
            executeBlockByGroup(
              componentContext,
              { group: action.name, tag: "READ" },
              { ...staticParams, ...action.staticParams },
              [
                ...(params?.filter((c) => c !== null) ?? []),
                { action: "read" },
                { isFormAction: true },
                { componentId: componentContext?.component?.id },
                { isOpen: true },
                ...(staticParams.params || []),
              ],
              systemParams
            ),
          ])
            .then(([res]) => resolve(res))
            .catch(reject);
        } else {
          throw new Error("Unexpected action type");
        }
      }),
  };
};
