import { getRandomIntInclusive } from "@echo/tools";
import { uuidv4 } from "@echo/tools/src/lib/uuidv4/uuidv4";

export const resetComponentId = (component) => {
  return {
    ...component,
    id: -getRandomIntInclusive(1, 999999999),
    rootComponentId: null,
    childrenComponents: [...resetComponentChildrenIds(component, null)],
  };
};

export const resetComponentChildrenIds = (
  component,
  rootComponentId,
  depth = 2,
) => {
  const newChildren = component.childrenComponents.map((child) => {
    const { childrenComponents } = child;

    if (childrenComponents && childrenComponents.length) {
      return {
        ...child,
        id: -getRandomIntInclusive(1, 999999999),
        guid: uuidv4(),
        nodeId: uuidv4(),
        rootComponentId,
        childrenComponents: resetComponentChildrenIds(
          child,
          rootComponentId,
          depth + 1,
        ),
      };
    }

    return {
      ...child,
      id: -getRandomIntInclusive(1, 999999999),
      guid: uuidv4(),
      nodeId: uuidv4(),
      rootComponentId,
    };
  });

  return newChildren;
};
