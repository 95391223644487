import { useEffect, useState } from 'react';

export const useAsInternalState = (
  obj,
  reactOnChange = false,
  defaultState = null
) => {
  const [objState, setObjState] = useState(defaultState);

  useEffect(
    () => {
      setObjState(obj);
    },
    reactOnChange ? [obj] : []
  );

  return [objState, setObjState];
};
