// @flow

import * as React from "react";
import type { Column } from "../../datagrid-v2";
import { Cell } from "../cell/cell";
import { InputEditCell } from "./edit-cell/input-edit-cell";
import { InputDateEditCell } from "./edit-cell/input-date-edit-cell";
import { InputNumericEditCell } from "./edit-cell/input-numeric-edit-cell";
import { BoolEditCell } from "./edit-cell/bool-edit-cell";
import { ComboEditCell } from "./edit-cell/combo-edit-cell";

type InputCellProps = {
  column: Column,
  value: string,
  onChange: (value: any, columnName: string) => void,
  autoFocus: boolean,
};

export const EditCell = (props: InputCellProps): React.Node => {
  const { column, onChange, value, autoFocus } = props;
  const { type, editDataSource: options } = column;

  const handleChange = (value) => {
    if (value === "") onChange(null, column);
    else onChange(value, column);
  };

  return (
    <Cell column={column} width={column.width} key={`${column.name}`}>
      <ConditionCell
        onChange={handleChange}
        column={column}
        value={value}
        autoFocus={autoFocus}
      />
    </Cell>
  );
};

const getConditions = (type, editType) => {
  const isString = type === "string";
  const isNumber = type === "number";
  const isDate = type === "datetime";
  const isBool = type === "boolean" || editType === "checkbox";
  const isCombo = editType === "combo";

  return { isString, isNumber, isDate, isBool, isCombo };
};

const ConditionCell = (props) => {
  const { column, ...inputProps } = props;
  const { type, editType, editDataSource: options, format } = column;

  const { isNumber, isDate, isBool, isCombo } = getConditions(type, editType);

  if (isNumber) return <InputNumericEditCell format={format} {...inputProps} />;
  if (isDate) return <InputDateEditCell dateFormat={format} {...inputProps} />;
  if (isCombo) return <ComboEditCell options={options} {...inputProps} />;
  if (isBool) return <BoolEditCell {...inputProps} />;

  return <InputEditCell {...inputProps} />;
};
