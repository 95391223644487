import { useEffect, useMemo, useState } from "react";
import { resolveColumnsProcess } from "../utils/resolve-columns";
import { resolveColumnsDatasource } from "../utils/resolve-column-datasource";

export const useColumns = (props) => {
  const { designerMode, columns } = props;
  const [cols, setCols] = useState([]);
  const resolvedColumns = useMemo(
    () => {
      if (!designerMode && columns) {
        return resolveColumnsProcess(columns, props);
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns],
  );

  useEffect(() => {
    if (!designerMode && resolvedColumns.length > 0) {
      resolveColumnsDatasource(resolvedColumns, props).then(setCols);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resolvedColumns]);

  return { resolvedColumns: cols };
};
