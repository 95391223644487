/* eslint-disable no-constant-condition */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getUserList } from "../../services/user-services";
import "../../styles/user/user-list.css";
import { Input } from "@chakra-ui/react";
import { debounce } from "../../utils/debounce/debounce";

// const columns = [
//   { field: 'login', headerName: 'Login', flex: 0.5 },
//   { field: 'name', headerName: 'Imię', flex: 0.3 },
//   { field: 'surname', headerName: 'Nazwisko', flex: 0.3 },
//   { field: 'phone', headerName: 'Telefon', flex: 0.3 },
//   { field: 'email', headerName: 'E-mail', flex: 0.3 },
//   {
//     field: 'administrator',
//     headerName: 'Administrator',
//     valueGetter: (params) => `${(params.value = '1' ? 'Tak' : 'Nie')}`,
//     flex: 0.3,
//   },
//   {
//     field: 'isActive',
//     headerName: 'Aktywny',
//     valueGetter: (params) => `${(params.value = '0' ? 'Tak' : 'Nie')}`,
//     flex: 0.3,
//   },
//   { field: 'layout', headerName: 'Widok', flex: 0.3 },
//   {
//     field: 'lastPasswordChangeDate',
//     headerName: 'Data ostatniej zmiany hasła',
//     flex: 0.3,
//   },
// ];

const getData = (search) => getUserList(search);

const UserList = () => {
  const [data, setData] = useState({
    elements: [],
    isLoading: true,
  });
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let cancel = false;
    debounce(() => {
      if (!cancel) {
        getData(searchText)
          .then((resultData) => {
            setData({
              elements: resultData ?? [],
              isLoading: false,
            });
          })
          .catch((err) => {
            window.console.error(err);
          });
      }
    }, 300)();

    return () => (cancel = true);
  }, [searchText, data.page]);

  return (
    <div className="user-list">
      <div className="list-search-container">
        <h3>Wybierz użytkownika z listy aby przejść do jego edycji</h3>
        <Input
          style={{ width: 468 }}
          id="search-input"
          placeholder="Szukaj"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="data-grid-container">
        {/* <DataGrid
          rows={data.elements}
          columns={columns}
          pageSize={20}
          loading={data.isLoading}
          autoHeight={data.elements}
          onRowClick={(e) => history.push('/user-settings/' + e.row.login)}
          onPageChange={({ page }) =>
            setData({ ...data, page, isLoading: true })
          }
        /> */}
      </div>
    </div>
  );
};

UserList.propTypes = {
  companyName: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func }),
};

UserList.defaultProps = {
  history: {
    push: () =>
      window.console.error("'history' prop with func prop not provided"),
  },
};

export default UserList;
