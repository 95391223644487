export const getOrderBy = (sort) =>
  Object.keys(sort).map((key) => ({
    key,
    inverse: sort[key] === 'desc',
  }));

export const getQueryParams = (state) => {
  return [
    {
      name: 'Page',
      value: state.page.toString(),
    },
    {
      name: 'ItemsPerPage',
      value: state.itemsPerPage.toString(),
    },
    {
      name: 'Search',
      value: state.globalSearch?.toString() ?? '',
    },
  ];
};
