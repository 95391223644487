import PropTypes from "prop-types";
import { Button, Checkbox, Select, Stack } from "@chakra-ui/react";

const EditSchemaProp = ({
  prop,
  isRequired,
  onPropChange,
  onRequiredChange,
  onPropDelete,
}) => {
  const handleChange = (opt) => {
    onPropChange({ ...prop, type: opt });
  };

  const handleOnRequiredChange = (e) => {
    const value = e.target.checked;
    onRequiredChange(value);
  };

  return (
    <Stack padding="0px 8px">
      <Select
        value={prop.type}
        placeholder="Select property type"
        onChange={(e) => handleChange(e.target.value)}
      >
        <option value="string">string</option>
        <option value="number">number</option>
        <option value="object">object</option>
        <option value="array">array</option>
      </Select>
      <Checkbox isChecked={isRequired} onChange={handleOnRequiredChange}>
        Is required
      </Checkbox>
      <Button onClick={onPropDelete}>Delete property</Button>
    </Stack>
  );
};

EditSchemaProp.propTypes = {
  prop: PropTypes.shape({
    type: PropTypes.oneOf(["string", "number", "object", "array"]).isRequired,
  }),
  isRequired: PropTypes.bool,
  onPropChange: PropTypes.func,
  onRequiredChange: PropTypes.func,
  onPropDelete: PropTypes.func,
};

EditSchemaProp.defaultProps = {};

export default EditSchemaProp;
