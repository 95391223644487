import { getCompanies } from "../../../services/company-service";
import { useEffect, useState } from "react";
import { showErrorToast } from "../../shared/echo-error-toast";

export const useCompanies = () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const sub = getCompanies()
      .then((result) => {
        if (result && Array.isArray(result) && result.length > 0) {
          setCompanies(result);
        } else if (result && Array.isArray(result) && result.length === 0) {
          showErrorToast({
            reasonTitle: "Error",
            location: "Login page",
            shortMessage: "Error. Cannot load companies",
            message:
              "There is no companies defined in the database. Please contact administrator.",
          });
        }
      })
      .catch((err) =>
        showErrorToast({
          reasonTitle: "Error",
          location: "Login page",
          shortMessage: "Error. Cannot load companies",
          message: err.toString(),
        }),
      );
    return () => sub;
  }, []);

  return [companies];
};
