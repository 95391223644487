import { useContext } from "react";
import EchoComponentsApiContext from "./api-context";

export const useApiContext = (id, guid, nodeId) => {
  const context = useContext(EchoComponentsApiContext);
  const { getComponentDefinition, getMode, getRootComponentDefinition } =
    context?.api ?? {};

  const component = getComponentDefinition(id, guid, nodeId);
  const rootComponent = getRootComponentDefinition(id);
  const mode = getMode();

  return [context, component, mode, rootComponent];
};
