import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const InputEditCell = (props) => {
  const { value, onChange, autoFocus } = props;
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(value);
  };

  return (
    <Input
      value={inputValue}
      onBlur={handleChange}
      onChange={(e) => setInputValue(e.target.value)}
      autoFocus={autoFocus}
      width="100%"
    />
  );
};

InputEditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};
