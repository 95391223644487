import { Box, Checkbox, Input, List, ListItem } from "@chakra-ui/react";
import PropTypes from "prop-types";

export const CalendarsList = ({
  calendars,
  onCalendarVisibilityChange,
  onCalendarBackgroundChange,
}) => {
  return (
    <List display="flex" flexDirection="column" gap="10px">
      {calendars.map((el) => (
        <CalendarListItem
          onVisibilityChange={onCalendarVisibilityChange}
          onBackgroundChange={onCalendarBackgroundChange}
          {...el}
          key={el.id}
        />
      ))}
    </List>
  );
};

const CalendarListItem = ({
  id,
  name,
  isVisible,
  background,
  onVisibilityChange,
  onBackgroundChange,
}) => {
  const handleChange = (e, calendarId, settingId) => {
    const checked = e.target.checked;
    onVisibilityChange(calendarId, checked, settingId);
  };

  return (
    <ListItem display="flex" alignItems="center" gap="10px" fontSize="18px">
      <Checkbox
        isChecked={isVisible.value}
        onChange={(e) => handleChange(e, id, isVisible.settingId)}
      />

      <Box>{name}</Box>

      <ColorPicker
        value={background.value}
        onChange={(color) =>
          onBackgroundChange(color, id, background.settingId)
        }
      />
    </ListItem>
  );
};

CalendarsList.propTypes = {
  calendars: PropTypes.array,
  onCalendarVisibilityChange: PropTypes.func,
  onCalendarBackgroundChange: PropTypes.func,
};

CalendarListItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  isVisible: PropTypes.bool,
  background: PropTypes.string,
  onVisibilityChange: PropTypes.func,
  onBackgroundChange: PropTypes.func,
};

const ColorPicker = ({ value, onChange }) => {
  let timeout;

  const handleChange = (e) => {
    const color = e.target.value;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      onChange(color);
    }, 500);
  };

  return (
    <Box
      overflow="hidden"
      height="20px"
      width="20px"
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      background={value}
    >
      <Input
        opacity="0"
        height="250%"
        width="250%"
        variant="unstyled"
        padding="0"
        margin="0"
        type="color"
        value={value}
        onChange={handleChange}
        border="none"
        outline="none"
      />
    </Box>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};
