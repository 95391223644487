const findPropertyInModelForComponent = (id, childs, propertyName) => {
  for (const index in childs) {
    if (childs[index].id === id) {
      return childs[index][propertyName];
    } else if (
      childs[index].childrenComponents &&
      childs[index].childrenComponents.length
    ) {
      const result = findPropertyInModelForComponent(
        id,
        childs[index].childrenComponents,
        propertyName
      );
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
};

const findModelInChildrenComponents = (id, childrenComponents) => {
  for (const index in childrenComponents) {
    if (childrenComponents[index].id === id) {
      return childrenComponents[index];
    } else if (
      childrenComponents[index].childrenComponents &&
      childrenComponents[index].childrenComponents.length
    ) {
      const result = findModelInChildrenComponents(
        id,
        childrenComponents[index].childrenComponents
      );
      if (result !== null) {
        return result;
      }
    }
  }
  return null;
};

export const useProperties = (
  id,
  propertyName,
  model,
  actionDispatch,
  onUpdate
) => {
  const findPropertyInModel = (id, propertyName) => {
    if (!model) {
      return {};
    }
    if (id === model.id) {
      return model[propertyName];
    } else if (typeof id === 'number') {
      return findPropertyInModelForComponent(
        id,
        model.childrenComponents,
        propertyName
      );
    } else {
      return {};
    }
  };

  const getComponent = (id) => {
    if (!model) {
      return {};
    }
    if (id === model.id) {
      return model;
    } else if (typeof id === 'number') {
      return findModelInChildrenComponents(id, model.childrenComponents);
    } else {
      return {};
    }
  };

  return [
    {
      id,
      component: getComponent(id),
      onAction: (id, action) => actionDispatch(id, action),
      model: findPropertyInModel(id, propertyName),
      onModelChange: onUpdate,
    },
  ];
};
