import { isMatch } from "date-fns";
import { pl } from "date-fns/locale";

export const getCursorPos = (e) => e.target.selectionEnd;

export const isCharValid = (c) => c === "d" || c === "m" || c === "y";

export const isNextCharValid = (value, format) => {
  const lastIdx = value.length;
  if (lastIdx === format.length) return true;
  const lowerCaseChar = format[lastIdx]?.toLowerCase();
  return isCharValid(lowerCaseChar);
};

export const isCurrentCharValid = (value, format) => {
  const lastIdx = value.length;
  if (lastIdx === format.length) return true;
  const lowerCaseChar = format[lastIdx - 1]?.toLowerCase();
  return isCharValid(lowerCaseChar);
};

export const isPrevCharValid = (value, format) => {
  const lastIdx = value.length;
  if (lastIdx === format.length) return true;
  const lowerCaseChar = format[lastIdx - 1]?.toLowerCase();
  return isCharValid(lowerCaseChar);
};

const allowedKeys = [
  "Backspace",
  "Tab",
  "Enter",
  "ArrowLeft",
  "ArrowRight",
  "Delete",
  "Shift",
  // "Ctrl",
];

export const isKeyAllowed = (k) =>
  /^[a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/.test(k) || allowedKeys.includes(k);

export const isDateMatched = (date, format) =>
  isMatch(date, format, { locale: pl });

export const isSameOrGreaterLength = (val1, val2) => val1.length >= val2.length;
