import { executeBlock, executeBlockByGroup } from '../process-executor';

export const deleteFormBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: (staticParams, params, systemParams) =>
      new Promise((resolve, reject) => {
        const action = componentContext.functions.getDeleteAction();
        const formSource =
          componentContext.functions.getStateProp('formSource');
        const id = formSource?.id ?? formSource?.Id;

        if (action.type === 'PROCESS') {
          Promise.resolve([
            executeBlock(
              componentContext,
              action.id,
              {
                ...staticParams,
                queryParams: [{ name: 'id', value: id }],
              },
              params,
              systemParams
            ),
          ])
            .then(([res]) => {
              resolve(res);
              componentContext.history.goBack();
            })
            .catch(reject);
        } else if (action.type === 'BLOCK_GROUP') {
          Promise.resolve([
            executeBlockByGroup(
              componentContext,
              { group: action.name, tag: 'DELETE' },
              {
                ...staticParams,
                ...action.staticParams,
                queryParams: [{ name: 'id', value: id }],
              },
              params,
              systemParams
            ),
          ])
            .then(([res]) => {
              resolve(res);
              componentContext.history.goBack();
            })
            .catch(reject);
        } else {
          throw new Error('Unexpected action type');
        }
      }),
  };
};
