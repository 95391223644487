import { formatIntegers } from "./format-integers";
import { getValueWithFraction } from "./fraction";
import { splitFormat } from "./split-format";
import { splitValue } from "./split-value";

const getDefaultValue = (separator, fraction) => {
  let result = "0";
  if (separator) result += separator;
  if (fraction > 0) {
    for (let i = 0; i < fraction; i++) {
      result += "0";
    }
  }
  return result;
};

const getNumericValue = (value, groupSeparator, decimalSeparator) => {
  let result = value;
  if (groupSeparator && result.includes(groupSeparator)) {
    result = result.replaceAll(groupSeparator, "");
  }
  if (decimalSeparator && result.includes(decimalSeparator)) {
    result = result.replace(decimalSeparator, ".");
  }
  return parseFloat(result);
};

export const formatValue = (value, format) => {
  const {
    intFormat,
    fractionFormat,
    groupSeparator,
    decimalSeparator,
    decimalPlaces,
  } = splitFormat(format);

  if (!value) return getDefaultValue(decimalSeparator, decimalPlaces);

  const stringValue = value.toString();
  const isNegative = stringValue.startsWith("-");

  const numericValue = getNumericValue(
    stringValue,
    groupSeparator,
    decimalSeparator,
  );

  const roundedValue = numericValue.toString().replace(".", decimalSeparator);

  const valueWithFraction = getValueWithFraction(
    roundedValue,
    decimalSeparator,
    decimalPlaces,
  );

  const { integers, fraction } = splitValue(
    valueWithFraction,
    decimalSeparator,
  );

  const formattedInts = formatIntegers(integers, intFormat, groupSeparator);

  const result =
    (isNegative ? "-" : "") +
    (formattedInts ? formattedInts : "") +
    (decimalSeparator ? decimalSeparator : "") +
    (fraction ? fraction : "");

  return result;
};

export const getBaseValue = (value, format) => {
  const {
    intFormat,
    fractionFormat,
    groupSeparator,
    decimalSeparator,
    decimalPlaces,
  } = splitFormat(format);

  return value
    .toString()
    .replaceAll(groupSeparator, "")
    .replace(decimalSeparator, ".");
};
