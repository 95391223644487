import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useOutsideClick,
} from "@chakra-ui/react";
import { useDayzed } from "dayzed";
import {
  Month_Names_Short_PL,
  Weekday_Names_Short_PL,
} from "./utils/calendar-utils";
import { CalendarPanel } from "./components/calendar-panel";
import PropTypes from "prop-types";
import { Input, mobileCheck } from "@echo/ui";
import {
  getSqlDateString,
  useDate,
} from "../echo-components/base-components/components/datagrid-component/hooks/use-formatted-date";
import { isMatch } from "date-fns";
import { toast } from "react-toastify";
import { useDatepickerHandlers } from "./hooks/use-datepicker-handlers";

// utils
const DefaultConfigs = {
  dateFormat: "dd-MM-yyyy",
  monthNames: Month_Names_Short_PL,
  dayNames: Weekday_Names_Short_PL,
};

export const SingleDatepicker = forwardRef(
  (
    {
      style,
      label,
      dateTimeFormat = "dd-MM-yyyy",
      configs = DefaultConfigs,
      propsConfigs,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      onTouchStart,
      nodeRef,
      onClick,
      onMouseOver,
      onMouseLeave,
      isReadOnly,
      isInvalid,
      isRequired,
      ...props
    },
    ref,
  ) => {
    const { date, name, disabled, onDateChange, id } = props;

    const dateFormat = useMemo(() => {
      const arr = dateTimeFormat.split("");
      if (!dateTimeFormat.toLowerCase().includes("h")) return dateTimeFormat;

      const index = arr.findIndex((c) => c.toLowerCase() === "h");
      const withoutTime = arr.splice(0, index - 1).join("");
      return withoutTime;
    }, [dateTimeFormat]);

    const {
      date: dateValue,
      time: timeValue,
      setDate,
      setTime,
    } = useDate(date, dateTimeFormat);

    const isMobile = mobileCheck();
    const isDateTime = dateTimeFormat
      ? dateTimeFormat.toLowerCase().includes("h")
      : false;

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [error, setError] = useState(false);

    const {
      onKeyDown,
      onChange,
      onSelect,
      onClick: onInputClick,
      onDateSelect,
      onPaste,
    } = useDatepickerHandlers(dateFormat, setDate);

    const validate = (date, format) => {
      const isCorrect = isMatch(date, format);

      if (!date || date === "" || isCorrect) {
        setError(false);
        return true;
      }

      setError(true);
      return false;
    };

    const handleKeyDown = (e) => onKeyDown(e);
    const handleDateChange = (e) => onChange(e);
    const handleSelect = (e) => onSelect(e);

    const handleOnClick = (e) => {
      if (isReadOnly) return;
      onInputClick(e);
      if (!onClick) {
        setPopoverOpen(!popoverOpen);
      } else {
        onClick(e, () => {
          setPopoverOpen(!popoverOpen);
        });
      }
    };

    const handlePaste = (e) => onPaste(e);

    const handleOnDateSelected = ({ selectable, date }) => {
      setError(false);
      (ref ?? initialFocusRef).current.focus();
      const isSelected = onDateSelect({ selectable, date });
      if (isSelected) setPopoverOpen(false);
    };

    const handleBlur = useCallback(() => {
      const isCorrect = validate(dateValue, dateFormat);
      if (!isCorrect) return;
      try {
        if (dateValue === "" || !dateValue) {
          onDateChange(dateValue);
          return;
        }
        const sqlDateString = getSqlDateString(
          { date: dateValue, time: timeValue },
          dateTimeFormat,
        );
        onDateChange(sqlDateString);
      } catch (e) {
        console.error(e);
      }
    }, [dateValue, timeValue, dateTimeFormat, popoverOpen]);

    // chakra popover utils
    const popoverRef = useRef(null);
    const initialFocusRef = useRef(null);

    useOutsideClick({
      ref: popoverRef,
      handler: () => setPopoverOpen(false),
    });

    // dayzed utils

    const dayzedData = useDayzed({
      showOutsideDays: true,
      onDateSelected: handleOnDateSelected,
      selected: date,
    });

    const handleTimeChange = useCallback(
      (e) => {
        const time = e.target.value;
        const sqlDateString = getSqlDateString(
          { date: dateValue, time },
          dateTimeFormat,
        );
        setTime(time);
        onDateChange(sqlDateString);
      },
      [dateValue, timeValue, dateTimeFormat],
    );

    const styleModifier = {
      name: "zIndex",
      enabled: true,
      phase: "main",
      fn({ state }) {
        state.styles.popper["z-index"] = 9999999;
      },
    };

    const dateInputStyles = {
      ...style,
      marginRight: isDateTime ? "0" : "",
    };

    const timeInputStyles = {
      ...style,
      marginLeft: isDateTime ? "0" : "",
    };

    useEffect(() => {
      if (error)
        toast.error(`${label} - provided date is incorrect.`, {
          autoClose: 3000,
        });
    }, [error]);

    return (
      <Popover
        placement="bottom-start"
        // variant="responsive"
        variant="responsive"
        strategy="fixed"
        isOpen={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        initialFocusRef={ref ?? initialFocusRef}
        isLazy
        modifiers={[styleModifier]}
      >
        <PopoverTrigger>
          <Box
            position="relative"
            style={{ order: style?.order }}
            display="flex"
            alignItems="center"
            gap="0"
            height={style?.height}
            data-testid="datepicker-trigger-test"
            onBlur={handleBlur}
          >
            <Input
              id={id}
              onSelect={handleSelect}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onTouchEnd={onTouchEnd}
              onTouchStart={onTouchStart}
              onFocus={() => setError(false)}
              style={dateInputStyles}
              nodeRef={nodeRef}
              onMouseOver={onMouseOver}
              onPaste={handlePaste}
              onMouseLeave={onMouseLeave}
              isDisabled={disabled}
              label={label}
              ref={ref ?? initialFocusRef}
              onClick={handleOnClick}
              onDoubleClick={(e) => {
                if (!isMobile) e.target.setSelectionRange(0, dateValue.length);
              }}
              isReadOnly={isReadOnly || isMobile}
              name={name}
              value={dateValue}
              onChange={handleDateChange}
              isInvalid={error || isInvalid}
              isRequired={isRequired}
              {...propsConfigs?.inputProps}
              data-testid="datepicker-input-test"
            />
            {isDateTime && (
              <Input
                onClick={(e) => e.stopPropagation()}
                isDisabled={disabled}
                isInvalid={isInvalid}
                isRequired={isRequired}
                onChange={handleTimeChange}
                value={timeValue || "00:00"}
                style={{ ...timeInputStyles, width: "fit-content" }}
                type="time"
              />
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          ref={popoverRef}
          width="100%"
          data-testid="datepicker-content-test"
        >
          <PopoverBody>
            <CalendarPanel
              renderProps={dayzedData}
              configs={configs}
              propsConfigs={propsConfigs}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  },
);

SingleDatepicker.displayName = "SingleDatepicker";

SingleDatepicker.propTypes = {
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onTouchEnd: PropTypes.func,
  onTouchStart: PropTypes.func,
  onFormCreateAction: PropTypes.func,
  onFormReadAction: PropTypes.func,
  onCancelHover: PropTypes.func,
  style: PropTypes.object,
  nodeRef: PropTypes.object,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
  date: PropTypes.any,
  dateTimeFormat: PropTypes.string,
  configs: PropTypes.shape({
    dateFormat: PropTypes.string,
    monthNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    dayNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  propsConfigs: PropTypes.shape({
    dateNavBtnProps: PropTypes.any, //ButtonProps,
    dayOfMonthBtnProps: PropTypes.shape({
      electedBg: PropTypes.any, //BackgroundProps['bg'],
      disabledBg: PropTypes.any, //BackgroundProps['bg'],
    }),
    inputProps: PropTypes.any, //InputProps,
  }),
  disabled: PropTypes.bool,
  onDateChange: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
};
