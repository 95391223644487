import { moveItem } from "./utils/order-utils";

export const ACTIONS = {
  BIND: "BIND",
  UNBIND: "UNBIND",
  UPDATE_PROPS: "UPDATE_PROPS",
  CHANGE_ORDER: "CHANGE_ORDER",
};

const mappingReducer = (prevState, action) => {
  switch (action.type) {
    case ACTIONS.BIND:
      return {
        ...prevState,
        mappings: [
          ...prevState.mappings,
          {
            name: action.prop.name,
            type: action.prop.type,
            destinationLocator: action.prop.locator,
            sourceLocator:
              prevState.sourceProps[prevState.mappings.length].locator,
          },
        ],
      };
    case ACTIONS.UNBIND:
      return {
        ...prevState,
        mappings: prevState.mappings.filter(
          (mapping) => mapping.name !== action.prop.name,
        ),
      };
    case ACTIONS.CHANGE_ORDER:
      return {
        ...prevState,
        [action.sourceListKey]: moveItem(
          prevState[action.sourceListKey],
          action.sourceOrder,
          action.destinationOrder,
        ),
      };
    case ACTIONS.UPDATE_PROPS:
      return prevState;
    default:
      return prevState;
  }
};

export const useMappingReducer = (state, onChange) => {
  const dispatch = (action) => onChange(mappingReducer(state, action));

  return [state, dispatch];
};
