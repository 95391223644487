import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { PanPinchZoomDragContext } from "@echo/pan-pinch-zoom-drag";
import { Box, IconButton, Stack } from "@chakra-ui/react";
import Draggable from "react-draggable";
import {
  BlockTypeQueryEnum,
  getBlockList,
} from "../../../services/diagram-service";
import { getBlockByType } from "./blocks/utils/get-by-type";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useRefDimensions, Input } from "@echo/ui";
import { toast } from "react-toastify";

const Toolbox = ({ onDrop }) => {
  const [blocks, setBlocks] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(0);
  const elementsRef = useRef();

  const [dimensions] = useRefDimensions(elementsRef);

  const elementsForPage =
    Math.floor(dimensions.height / 124) * Math.floor(dimensions.width / 84);

  useEffect(() => {
    getBlockList(
      BlockTypeQueryEnum.Public |
        BlockTypeQueryEnum.Frontend |
        BlockTypeQueryEnum.Backend |
        BlockTypeQueryEnum.AllApplication,
      [],
    )
      .then((result) =>
        setBlocks(
          result.map((block) => ({
            data: block,
            component: getBlockByType(block.executeType),
          })),
        ),
      )
      .catch(() => toast.error("Cannot load blocks list"));
  }, []);

  const filteredBlocks = blocks.filter((block) =>
    block.data.name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const { areaState, updateToolboxState } = useContext(PanPinchZoomDragContext);

  const handleOnDrop = (...args) => {
    if (onDrop && typeof onDrop === "function") {
      onDrop(...args);
    }
  };

  const handleSearchChange = (e) => {
    Promise.all([setSearchValue(e.target.value), setPage(0)]);
  };

  const handleOnStop = (block) => {
    if (areaState?.isMouseOver) {
      Promise.all([
        updateToolboxState({ dragComponent: null }),
        handleOnDrop(areaState.mousePosition, block),
      ]);
    } else {
      updateToolboxState({ dragComponent: null });
    }
  };

  const handleOnStart = (dragComponent) => {
    updateToolboxState({ dragComponent });
  };

  return (
    <Stack
      minWidth="172px"
      height="100%"
      display="flex"
      flexFlow="column"
      padding="8px 0px"
    >
      <Input
        alignSelf="center"
        width="90%"
        flex="0 1 auto"
        value={searchValue}
        placeholder="Search..."
        onChange={handleSearchChange}
      />
      <IconButton
        variant="outline"
        onClick={() => setPage(page - 1)}
        disabled={page === 0}
        flex="0 1 auto"
        width="90%"
        alignSelf="center"
      >
        <FiChevronUp />
      </IconButton>
      <Box
        ref={elementsRef}
        padding="4px"
        minWidth="172px"
        display="grid"
        maxHeight="calc(100% - 100px)"
        flex="1 1 auto"
        gridTemplateColumns="repeat(auto-fill, 80px)"
        justifyContent="space-around"
        alignContent="start"
        gridGap="4px"
      >
        {filteredBlocks
          .slice(page * elementsForPage, (page + 1) * elementsForPage)
          .map((block) => (
            <Draggable
              key={`toolbox-block-${block.data.id}`}
              onStop={() => handleOnStop(block)}
              onStart={() => handleOnStart(<Box>{block.component}</Box>)}
              position={{ x: 0, y: 0 }}
            >
              <Box
                alignItems="center"
                alignContent="center"
                justifyContent="space-between"
                flexDirection="column"
                fontSize="small"
                borderColor="gray.300"
                borderStyle="solid"
                width="80px"
                height="120px"
                textAlign="center"
                borderWidth="1px"
                borderRadius="3px"
                display="flex"
                _hover={{ backgroundColor: "gray.200" }}
              >
                <Box transform="scale(0.5)">{block.component}</Box>
                <Box overflow="hidden" width="70px">
                  {block.data.name}
                </Box>
              </Box>
            </Draggable>
          ))}
      </Box>
      <IconButton
        variant="outline"
        disabled={(page + 1) * elementsForPage >= filteredBlocks.length}
        flex="0 1 auto"
        width="90%"
        alignSelf="center"
        onClick={() => setPage(page + 1)}
      >
        <FiChevronDown />
      </IconButton>
    </Stack>
  );
};

Toolbox.propTypes = {
  onDrop: PropTypes.func,
};

Toolbox.defaultProps = {};

export default Toolbox;
