/* eslint-disable react/forbid-foreign-prop-types */
import { Children, cloneElement } from "react";
import isClass from "../../utils/javascript/is-class";
import { getShim } from "../../utils/shims/shims";
import { checkSystemProperties } from "./check-system-properties";

const clearDesignerProps = (props) => {
  const result = { ...(props ?? {}) };
  for (const propName in props) {
    const prop = result[propName];
    if (
      prop &&
      typeof prop === "object" &&
      (prop.type === "PROCESS" || prop.type === "STATE_PROP")
    ) {
      delete result[propName];
    }
  }
  return result;
};

const getEchoComponent = (component, designerMode) => {
  let resultComponent = component;
  while (resultComponent.type && resultComponent.type.isWrapper) {
    if (
      resultComponent.type &&
      resultComponent.type.isWrapper &&
      isClass(resultComponent.type)
    ) {
      const cmp = new resultComponent.type(
        designerMode
          ? {
              ...clearDesignerProps(resultComponent.props),
              componentProps: clearDesignerProps(
                resultComponent.props.componentProps ?? {},
              ),
            }
          : resultComponent.props,
      ); // React... type property is class
      resultComponent = cmp.render();
    } else if (
      resultComponent.type &&
      resultComponent.type.isWrapper &&
      typeof resultComponent.type === "function"
    ) {
      resultComponent = resultComponent.type(
        designerMode
          ? {
              ...clearDesignerProps(resultComponent.props),
              componentProps: clearDesignerProps(
                resultComponent.props.componentProps ?? {},
              ),
            }
          : resultComponent.props,
      );
    }
  }

  return resultComponent;
};

export const withEchoComponentRenderer = (component) => {
  const resultComponent = getEchoComponent(component, false);

  return Children.only(cloneElement(resultComponent));
};

export const withEchoComponentDesigner = (component) => {
  let resultComponent = getEchoComponent(component, true);

  const { propTypes } = resultComponent.type;
  checkSystemProperties(propTypes, resultComponent.type.name);
  const shimObject = {};

  for (const propName in propTypes) {
    const { info } = propTypes[propName];
    if (!info) {
      throw new Error(`Invalid propType for component ${component.type.name}`);
    }
    if (info.isRequired) {
      shimObject[propName] = getShim(propName, info);
    }
  }

  const resultProps = {
    ...resultComponent.props,
    ...shimObject,
    ...(component.props.systemProps ?? {}),

    designerMode: resultComponent.props.designerMode, // TODO check system props
    /* Swap childs.
     * If component is defined in echo, we wanna render those components.
     * Components dropped on designer will render in EchoDefinedComponent
     * if component have container for childs. Container for childs can be
     * dropped while designing.
     */
    // TODO container for childs
    childs:
      component.props.source === "!?echo-defined"
        ? resultComponent.props.childs
        : component.props.systemProps.childs,
  };

  return Children.only(cloneElement(resultComponent, resultProps));
};

export const withEchoComponent = (component, mode = "renderer") => {
  if (mode === "renderer") {
    return withEchoComponentRenderer(component);
  } else {
    return withEchoComponentDesigner(component);
  }
};
