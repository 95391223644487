import { useReducer } from "react";

export const useDataReducer = () => {
  const initialState = {
    source: null,
    data: [],
    columns: [],
    filters: {},
    itemsCount: 0,
    page: 0,
    loading: true,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_STATE": {
        return { ...state, ...action.payload.value };
      }
      case "SET_FIELD": {
        return { ...state, [action.payload.field]: action.payload.value };
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleData = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "data", value } });

  const handleColumns = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "columns", value } });

  const handleFilters = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "filters", value } });

  const handleItemsCount = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "itemsCount", value } });

  const handlePage = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "page", value } });

  const handleLoading = (value) =>
    dispatch({ type: "SET_FIELD", payload: { field: "loading", value } });

  const setField = (type, value) => {
    switch (type) {
      case "data":
        handleData(value);
        break;
      case "columns":
        handleColumns(value);
        break;
      case "filters":
        handleFilters(value);
        break;
      case "itemsCount":
        handleItemsCount(value);
        break;
      case "page":
        handlePage(value);
        break;
      case "loading":
        handleLoading(value);
        break;
    }
  };

  const setState = (value) =>
    dispatch({ type: "SET_STATE", payload: { value } });

  return { state, setState, setField };
};
