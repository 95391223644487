const replaceConditions = (pattern, state) => {
  let result = pattern.toString();
  const regex = /@\w+[?]@?\w+:@?\w+/g;

  const matches = result.match(regex);

  if (matches) {
    const resolvedConditions = matches.map((match) => {
      const condition = match.split("?");
      const conditionResults = condition[1].split(":");

      const conditionResult = state[condition[0].substring(1)]
        ? conditionResults[0]
        : conditionResults[1];

      return { match, conditionResult };
    });

    for (let resolvedCondition of resolvedConditions) {
      result = result.replace(
        resolvedCondition.match,
        resolvedCondition.conditionResult,
      );
    }
  }

  return result;
};

export const fillPattern = (pattern, state) => {
  if (!pattern || !state) {
    return "";
  }

  let result = replaceConditions(pattern, state);

  const names = Object.keys(state);
  for (let name of names) {
    result = result.replaceAll(`@${name}`, state[name] ? state[name] : "nowy");
  }

  return result;
};
