export const setProp = (data, propPath, propValue, spreadObject) => {
  const pathElements = propPath ? propPath.split(".") : [];
  let stateTemp = data;
  for (let tabIndex = 0; tabIndex < pathElements.length - 1; tabIndex++) {
    const pathElem = pathElements[tabIndex];
    if (!stateTemp[pathElem]) {
      stateTemp[pathElem] = {};
    }
    stateTemp = stateTemp[pathElem];
  }
  if (
    propValue &&
    !Array.isArray(propValue) &&
    typeof propValue === "object" &&
    spreadObject
  ) {
    stateTemp[pathElements[pathElements.length - 1]] = {
      ...(stateTemp[pathElements[pathElements.length - 1]] || {}),
      ...propValue,
    };
  } else {
    stateTemp[pathElements[pathElements.length - 1]] = propValue;
  }
  return { ...data };
};
