import PropTypes from "prop-types";
import ValueCell from "./value-cell/value-cell";
import "../../../../styles/shared/input.css";
import { Fragment } from "react";
import { Tooltip } from "@chakra-ui/react";

const PropertiesTableRowDivider = () => (
  <div style={{ height: "1px", backgroundColor: "#95999b", width: "100%" }} />
);

const PropertiesTableRow = ({
  type,
  propName,
  propValue,
  propType,
  onChange,
  customTypes,
  disabled,
  model,
  options,
  tooltip,
  onAction,
}) => {
  const elementDisabled =
    typeof disabled === "function" ? disabled(model) : disabled;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: type === "option-parent" ? "#cecece" : undefined,
      }}
    >
      <div style={{ display: "flex", width: "50%", flexShrink: 0 }}>
        <Tooltip
          label={tooltip}
          hasArrow
          padding="2px 5px"
          display="flex"
          alignItems="center"
          aria-multiline
        >
          <div
            style={{
              width: "100%",
              padding: "1px",
              marginLeft:
                type === "sub-option" || type === "last-sub-option"
                  ? "0px"
                  : "8px",
              borderLeft:
                type === "sub-option" || type === "last-sub-option"
                  ? "16px solid #cecece"
                  : undefined,
              borderBottom:
                type === "last-sub-option" ? "4px solid #cecece" : undefined,
              paddingLeft:
                type === "sub-option" || type === "last-sub-option"
                  ? "20px"
                  : "1px",
              fontSize:
                type === "sub-option" || type === "last-sub-option"
                  ? "0.8rem"
                  : undefined,
              lineHeight:
                type === "sub-option" || type === "last-sub-option"
                  ? "1.25rem"
                  : undefined,
              color: elementDisabled ? "#b0b1b2" : "#1b1b1c",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {propName}
          </div>
        </Tooltip>
      </div>
      <span
        style={{
          backgroundColor: "#95999b",
          position: "relative",
          minWidth: "1px",
          maxWidth: "1px",
          display: "inline-block",
          margin: "0 0.25%",
        }}
      />
      <div
        style={{
          padding: "1px",
          width: "100%",
          maxWidth: "50%",
          flexShrink: 2,
          color: "#b0b1b2",
          overflow: "hidden",
          borderRight:
            type === "sub-option" || type === "last-sub-option"
              ? "8px solid #cecece"
              : undefined,
          paddingRight:
            type === "sub-option" || type === "last-sub-option"
              ? "0px"
              : type === "option-parent"
                ? "8px"
                : "1px",
          borderBottom:
            type === "last-sub-option" ? "4px solid #cecece" : undefined,
          fontSize:
            type === "sub-option" || type === "last-sub-option"
              ? "0.8rem"
              : undefined,
          lineHeight:
            type === "sub-option" || type === "last-sub-option"
              ? "1.25rem"
              : undefined,
        }}
      >
        <ValueCell
          onAction={onAction}
          customTypes={customTypes}
          options={options}
          propType={propType}
          propValue={propValue}
          propName={propName}
          onChange={onChange}
          disabled={elementDisabled}
          model={model}
        />
      </div>
    </div>
  );
};

PropertiesTableRow.propTypes = {
  propName: PropTypes.string.isRequired,
  propValue: PropTypes.any,
  propType: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.any,
  customTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ),
  disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  model: PropTypes.object,
  onAction: PropTypes.func,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([
    "option",
    "option-parent",
    "sub-option",
    "last-sub-option",
  ]),
};

PropertiesTableRow.defaultProps = {
  model: {},
  disabled: false,
};

const PropertiesTable = ({ model, onModelChange, options, customTypes }) => {
  const handleItemChange = (propName, newValue) => {
    onModelChange({ ...model, [propName]: newValue }, propName, newValue);
  };

  const getOption = (item, type) => (
    <PropertiesTableRow
      type={type}
      customTypes={customTypes}
      tooltip={item.tooltip}
      propName={item.propName}
      onAction={item.onAction}
      propValue={model[item.propName]}
      propType={item.propType}
      onChange={(newValue) => handleItemChange(item.propName, newValue)}
      disabled={item.disabled}
      options={item.options}
      model={model}
    />
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        border: "1px solid black",
      }}
    >
      {options.map((item, index) => (
        <Fragment key={`properties-table-row-${index}`}>
          {getOption(
            item,
            item?.subOptions?.length ? "option-parent" : "option",
          )}
          {(index !== options.length - 1 || item?.subOptions?.length) && (
            <PropertiesTableRowDivider />
          )}
          {item?.subOptions?.length &&
            item.subOptions.map((subOption, subIndex) => (
              <Fragment key={`properties-table-row-${index}-${subIndex}`}>
                {getOption(
                  subOption,
                  subIndex === item.subOptions.length - 1
                    ? "last-sub-option"
                    : "sub-option",
                )}
                {subIndex !== item.subOptions.length - 1 && (
                  <PropertiesTableRowDivider />
                )}
              </Fragment>
            ))}
        </Fragment>
      ))}
    </div>
  );
};

PropertiesTable.propTypes = {
  options: PropTypes.array,
  model: PropTypes.object,
  onModelChange: PropTypes.func,
  customTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.func.isRequired,
    }),
  ),
};

PropertiesTable.defaultProps = {
  stateSchema: {},
  customTypes: [],
};

export default PropertiesTable;
