import React from "react";
import { Input } from "@chakra-ui/react";
import { useInputContext } from "../hooks/use-input-context";
import PropTypes from "prop-types";

export const InputField = (props) => {
  const { isDisabled, isReadOnly } = props;
  const { value, inputActions, inputRef } = useInputContext();
  const { handleBlur, handleChange, handleKeyDown } = inputActions;

  const handleFocus = (e) => {
    const value = e.target.value;
    e.target.setSelectionRange(0, value.length);
  };

  return (
    <Input
      ref={inputRef}
      paddingRight="24px"
      textAlign="right"
      width="100%"
      height="100%"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      inputMode="decimal"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      {...props}
    />
  );
};

InputField.propTypes = {
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};
