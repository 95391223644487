import { showErrorToast } from "../../../components/shared/echo-error-toast";
import {
  authenticate,
  getMessagesList,
} from "../../../services/email-service/email-service";
import { pagesStore } from "./utils/pages-store";

const getColumns = (data) => {
  if (!data[0]) return [];
  const objKeys = Object.keys(data[0]);
  const columns = objKeys.map((k) => ({
    columnName: k,
    dataTypeName: k.toLowerCase().includes("date") ? "date" : typeof data[0][k],
    allowDBNull: true,
  }));

  return columns;
};

const getRequestParams = (staticParams) => {
  const { queryParams, queryString, mailbox } = staticParams;
  const page = queryParams?.find((p) => p.name === "Page").value || 0;
  const count = queryParams?.find((p) => p.name === "ItemsPerPage").value || 20;

  return {
    currentPage: page,
    count,
    queryString: queryString || undefined,
    mailbox: mailbox || "INBOX",
  };
};

export const getEmailsListBlock = (block, componentContext) => {
  return {
    definition: block,
    execute: async (staticParams) => {
      const { componentName } = staticParams;
      const userId = componentContext.userContext.userId;
      const pagesItemName = `${componentName}_pages`;
      const { pages, addPage, clearStore } = pagesStore(pagesItemName);
      const { currentPage, ...reqParams } = getRequestParams(staticParams);

      if (currentPage === 0) clearStore(pagesItemName);

      const authData = await authenticate(userId);

      if (!authData || (authData && !authData.refreshToken))
        return { data: [], metadata: { itemsCount: 0, columns: [], page: 0 } };

      const { refreshToken } = authData;
      const pageToken = pages?.[currentPage];
      const params = { ...reqParams, pageToken, refreshToken };

      try {
        const res = await getMessagesList(params);

        if (!res.ok) {
          const err = await res.json();
          showErrorToast(err);
          return {
            data: [],
            metadata: { itemsCount: 0, columns: [], page: 0 },
          };
        }

        const { mails: data, total, nextPage } = await res.json();

        if (nextPage) addPage(nextPage, pagesItemName);

        const columns = getColumns(data);
        return {
          data,
          metadata: { itemsCount: total, columns, page: currentPage },
        };
      } catch (e) {
        throw new Error(e);
      }
    },
  };
};
