export const sortByNameAndVisibility = (a, b) => {
  if (a.isVisible !== b.isVisible) {
    return a.isVisible ? -1 : 1;
  }

  const nameA = a.name.split(/(\d+)/);
  const nameB = b.name.split(/(\d+)/);

  for (let i = 0; i < Math.max(nameA.length, nameB.length); i++) {
    const partA = nameA[i] || "";
    const partB = nameB[i] || "";

    const numA = parseInt(partA, 10);
    const numB = parseInt(partB, 10);

    if (isNaN(numA) || isNaN(numB)) {
      if (partA < partB) return -1;
      if (partA > partB) return 1;
    } else {
      if (numA < numB) return -1;
      if (numA > numB) return 1;
    }
  }

  return 0;
};
