const hasFractionDigits = (value, separator) => {
  const arr = value.split(separator);
  return arr[1] ? true : false;
};

const getRoundedFraction = (value, separator, digits) => {
  const valueWithDot = value.replace(separator, ".");
  const numberValue = parseFloat(valueWithDot);
  const roundedValue = numberValue.toFixed(digits);
  return roundedValue.replace(".", separator);
};

const getDefaultFraction = (digits) => {
  let result = "";
  for (let i = 0; i < digits; i++) {
    result += "0";
  }
  return result;
};

export const getValueWithFraction = (value, separator, digits) => {
  if (!separator) return value;
  const hasFraction = hasFractionDigits(value, separator);

  if (!hasFraction) {
    const defaultFraction = getDefaultFraction(digits);
    return value + separator + defaultFraction;
  }

  const valueWithFraction = getRoundedFraction(value, separator, digits);
  return valueWithFraction;
};
