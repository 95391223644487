import { getParsedValue } from "./get-parsed-value";

/**
 *
 * @param {string | number} val1
 * @param {string | number} val2
 * @returns val1 + val2 as number or string
 */
export const add = (val1, val2) => {
  const parsedVal1 = getParsedValue(val1);
  const parsedVal2 = getParsedValue(val2);
  const value = parsedVal1 + parsedVal2;

  return {
    number: value,
    string: value.toString(),
  };
};

/**
 *
 * @param {string | number} val1
 * @param {string | number} val2
 * @returns val1 - val2 as number or string
 */
export const subtract = (val1, val2) => {
  const parsedVal1 = getParsedValue(val1);
  const parsedVal2 = getParsedValue(val2);

  const value = parsedVal1 - parsedVal2;
  return {
    number: value,
    string: value.toString(),
  };
};
