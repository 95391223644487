import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import DataGrid from "../echo-components/base-components/components/datagrid-component/datagrid.component";
import { useContext, useEffect, useState } from "react";
import { getPermissions } from "../../../services/permission-service";
import UserContext from "../../../user-context";

const columns = [
  {
    name: "companyName",
    displayName: "Company name",
  },
  {
    name: "user",
    displayName: "User name",
  },
  {
    name: "roleName",
    displayName: "Role name",
  },
  {
    name: "type",
    displayName: "Permission type",
  },
  {
    name: "location",
    displayName: "Location",
  },
];

const PermissionList = ({
  onSelectionChange,
  componentId,
  onRowDoubleClick,
}) => {
  const [permissions, setPermissions] = useState();
  const { companyId } = useContext(UserContext);

  useEffect(() => {
    getPermissions(companyId, null, null, 2070, componentId).then(
      setPermissions
    );
  }, [companyId, componentId]);

  return (
    <Box height="480px" boxSizing="border-box">
      <DataGrid
        objectKey="COMPONENT_LIST"
        value={(permissions || []).map((p) => ({
          _id: p.id,
          id: p.id,
          companyName: p?.company?.name,
          user: p?.user?.login,
          userId: p?.user?.id,
          roleName: p?.role?.name,
          roleId: p?.role?.id,
          type: p?.type,
          location: p.location,
        }))}
        columns={columns}
        onRowClick={onSelectionChange}
        pageSize={15}
        onRowDoubleClick={onRowDoubleClick}
      />
    </Box>
  );
};

PermissionList.propTypes = {
  permissions: PropTypes.arrayOf(
    PropTypes.shape({
      propertyNameMap: PropTypes.string.isRequired,
      permissionCheckProcess: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
      }),
    })
  ),
  onSelectionChange: PropTypes.func,
  componentId: PropTypes.number,
  onRowDoubleClick: PropTypes.func,
};

PermissionList.defaultProps = {};

export default PermissionList;
