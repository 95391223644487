// @flow

import * as React from "react";

import { Checkbox } from "@chakra-ui/react";

type CheckboxProps = {
  value: any,
  onChange: (e: any) => void,
  isChecked: boolean,
};

export const CustomCheckbox = (props: CheckboxProps): React.Node => {
  const { value, onChange, isChecked } = props;

  return <Checkbox onChange={onChange} value={value} isChecked={isChecked} />;
};
