import PropTypes from "prop-types";
import { shimFunctions } from "../../../../utils/shims/shims";

const ComponentShim = () => {
  return <div />;
};

ComponentShim.propTypes = {
  nodeRef: PropTypes.any,
  onClick: PropTypes.funcOf(PropTypes.object),
  onMouseLeave: PropTypes.funcOf(PropTypes.object),
  onMouseOver: PropTypes.funcOf(PropTypes.object),
};

ComponentShim.defaultProps = {
  nodeRef: { current: null },
  onClick: shimFunctions.shimFunction1,
  onMouseLeave: shimFunctions.shimFunction1,
  onMouseOver: shimFunctions.shimFunction1,
};

export default ComponentShim;
