import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const PropertiesValueInput = ({ value, onChange, disabled, clearOnEdit }) => {
  const [focus, setFocus] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const handleOnChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    setCurrentValue(value || "");
  }, [value]);

  return (
    <input
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className="table-input"
      size="small"
      value={focus && clearOnEdit ? "" : currentValue}
      disabled={disabled}
      onChange={handleOnChange}
    />
  );
};

PropertiesValueInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearOnEdit: PropTypes.bool,
};

PropertiesValueInput.defaultProps = {
  disabled: false,
  clearOnEdit: false,
  value: "",
};

export default PropertiesValueInput;
