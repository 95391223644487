const mapModel = (model, modelKey) => {
  let resultParams = [];
  const modelKeys = Object.keys(model);
  for (let propertyKey of modelKeys) {
    const modelProperty = model[propertyKey];
    // Arrays for now are not supported
    if (!Array.isArray(modelProperty)) {
      const paramKey = `${modelKey}_${propertyKey}`;
      if (
        typeof modelProperty !== "object" ||
        typeof modelProperty !== "undefined" ||
        modelProperty == null
      ) {
        resultParams.push({
          name: paramKey,
          value: modelProperty || null,
        });
      } else {
        resultParams = [...resultParams, ...mapModel(modelProperty, paramKey)];
      }
    }
  }

  return resultParams;
};

export const mapForwardedModels = (activePage) => {
  if (
    !activePage ||
    !activePage.state ||
    !activePage.state.modelsCollection ||
    typeof activePage.state.modelsCollection !== "object" ||
    Array.isArray(activePage.state.modelsCollection)
  ) {
    return null;
  }

  const modelsCollection = activePage.state.modelsCollection;
  let result = [];

  for (let modelKey of Object.keys(modelsCollection)) {
    const model = modelsCollection[modelKey];
    if (model && typeof model === "object" && !Array.isArray(model)) {
      result = [...result, ...mapModel(model, modelKey)];
    }
  }

  return result;
};
