// @flow

import * as React from "react";
import {
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { Calendar } from "./calendar/calendar";
import { useDatePicker } from "./hooks/use-datepicker";
import { useDate } from "./hooks/use-date";
import { format } from "date-fns";
import { useInputSelection } from "./hooks/use-input-selection";
import { useRef } from "react";

type DatePickerProps = {
  value: Date,
  onChange: () => void,
  dateFormat: string,
  variant: "outline" | "filled" | "flushed" | "unstyled",
  autoFocus?: boolean,
  inputStyles?: any,
};

export const DatePicker = (props: DatePickerProps): React.Node => {
  const { value, dateFormat, variant, size, onChange, inputStyles } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentDate, current, prev, next, handleDateChange } =
    useDatePicker();

  const { getSelectionRange } = useInputSelection();

  const { dateTimeValue, handleDate } = useDate(value, dateFormat, onChange);

  const calendarDays = [prev, current, next];

  const handleDateSelect = (date: Date) => {
    const value = format(date, dateFormat || "dd-MM-yyyy");
    handleDate({ date: value, time: "" });
  };

  const handleInputDateChange = (e) => {
    const date = e.target.value;
    handleDate({ date });
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (e.target && typeof e.target.setSelectionRange === "function") {
      const selectionPos = e.target.selectionStart;
      const { start, end } = getSelectionRange(
        dateTimeValue.date || "",
        selectionPos,
      );
      e.target.setSelectionRange(start, end);
    }

    if (!isOpen) {
      onOpen();
      return;
    }
  };

  const triggerRef = useRef();
  const popoverRef = useRef();

  return (
    <Popover
      placement="bottom"
      variant="responsive"
      isOpen={isOpen}
      onClose={onClose}
      strategy="fixed"
      initialFocusRef={triggerRef}
    >
      <PopoverTrigger>
        <Input
          ref={triggerRef}
          value={dateTimeValue.date || ""}
          onChange={handleInputDateChange}
          onClick={handleClick}
          onDoubleClick={(e) =>
            e.target.setSelectionRange(0, e.target.value.length)
          }
          variant={variant || "outline"}
          size={size}
          width="100%"
          padding="2px 4px"
          {...inputStyles}
        />
      </PopoverTrigger>
      <PopoverBody style={{ width: "0", padding: "0" }}>
        <PopoverContent
          ref={popoverRef}
          width="100%"
          style={{ right: "0", fontWeight: "normal", fontSize: "14px" }}
        >
          {calendarDays && (
            <Calendar
              calendarDays={calendarDays}
              date={currentDate}
              onDateChange={handleDateChange}
              onDateSelected={handleDateSelect}
              selectedDates={[value]}
            />
          )}
        </PopoverContent>
      </PopoverBody>
    </Popover>
  );
};
