import PropTypes from "prop-types";
import PropertiesValueInput from "../cell-components/properties-table-input";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import EditorComponent from "../cell-components/editor-component";

const CodeEditorValueCell = ({ propValue, propType, onChange, disabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <PropertiesValueInput
        value={propValue.replace(/\n/g, " ")}
        onChange={onChange}
        disabled={disabled}
      />
      <Button onClick={onOpen} variant="ghost">
        ...
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent padding="10px">
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <EditorComponent
              value={propValue}
              onChange={(newValue) => {
                if (newValue && newValue !== propValue) {
                  onChange(newValue);
                }
              }}
              options={{
                mode:
                  propType === "postgresql-editor"
                    ? "text/x-pgsql"
                    : "text/text",
                lineNumbers: true,
              }}
              style={{
                height: "300px",
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

CodeEditorValueCell.propTypes = {
  propValue: PropTypes.any,
  propType: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

CodeEditorValueCell.defaultProps = { propValue: "", disabled: false };

export default CodeEditorValueCell;
