import { toast } from "react-toastify";
import { ErrorMsg } from "./error-msg-component";
import { authenticateToGoogle, googleAuthStatus } from "./google-auth";
import { setAuthStatus } from "./store-google-token";

export const handleAuthResult = async (userId, result) => {
  const { success, error } = googleAuthStatus;
  const keys = Object.keys(result);
  if (!result || keys.length < 1) {
    const newResult = await new Promise((resolve) => {
      setAuthStatus(error);
      toast.error(
        <ErrorMsg
          onClick={async () => {
            toast.dismiss({ id: "auth-error" });
            const auth = await authenticateToGoogle(userId);
            resolve(auth);
          }}
        />,
        {
          closeOnClick: false,
          autoClose: false,
          toastId: "auth-error",
        },
      );
    });
    return await handleAuthResult(userId, newResult);
  }

  setAuthStatus(success);
  toast.success(`Zalogowano pomyślnie ${result.email}.`, {
    toastId: "auth-success",
  });

  return { data: result, authenticated: true };
};
