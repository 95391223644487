import { baseGet, basePost } from "./base-fetch/base-fetch";

export const executeBlock = (block) => basePost("Process/executeBlock", block);

export const getBlockList = (type, executeTypes) =>
  baseGet("Process/getProcessList", { type, executeTypes });

export const getBlock = (blockId) =>
  new Promise((resolve, reject) => {
    let blocks = [];
    const blockStorageData = window.localStorage.getItem("blocks");

    if (blockStorageData === null) {
      blocks = [];
    }

    blocks = JSON.parse(blockStorageData);
    if (!blocks || !Array.isArray(blocks)) {
      blocks = [];
    }

    const cachedBlock = blocks.find((b) => {
      // eslint-disable-next-line eqeqeq
      return b.id == blockId && new Date(b.expirationDate) > new Date();
    });

    if (cachedBlock) {
      resolve(cachedBlock.block);
    } else {
      baseGet("Process/getBlock", { blockId })
        .then((block) => {
          blocks.push({
            id: blockId,
            block,
            expirationDate: new Date(new Date().getTime() + 60 * 5000),
          });
          window.localStorage.setItem(
            "blocks",
            JSON.stringify(
              blocks.filter((b) => new Date(b.expirationDate) > new Date()),
            ),
          );
          resolve(block);
        })
        .catch(reject);
    }
  });

export const getBlockByGroup = (group, tag) =>
  baseGet("Process/getBlockByGroup", { group, tag });

export const saveBlock = (block) =>
  new Promise((resolve, reject) => {
    basePost("Process/saveProcess", block)
      .then((response) => {
        let blocks = [];
        const blockStorageData = window.localStorage.getItem("blocks");

        if (blockStorageData === null) {
          blocks = [];
        }

        blocks = JSON.parse(blockStorageData);
        if (!blocks || !Array.isArray(blocks)) {
          blocks = [];
        }
        window.localStorage.setItem(
          "blocks",
          // eslint-disable-next-line eqeqeq
          JSON.stringify(blocks.filter((b) => b.id != block.id)),
        );
        resolve(response);
      })
      .catch(reject);
  });

export const BlockTypeQueryEnum = {
  None: 0,
  Private: 1,
  Public: 1 << 1,
  Frontend: 1 << 2,
  Backend: 1 << 3,
  AllApplication: 1 << 4,
};
