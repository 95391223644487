import StandardBlock from "../standard";
import ConditionalBlock from "../conditional";
import BoundaryBlock from "../boundary-block";

export const getBlockByType = (executeType) => {
  switch (executeType) {
    case -1:
    case -2:
      return <BoundaryBlock />;
    case 5:
    case 28:
    case 61:
      return <ConditionalBlock />;
    default:
      return <StandardBlock />;
  }
};
