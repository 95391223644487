import { useRef } from "react";
import { useSize } from "@chakra-ui/react-use-size";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { FilterCellInput } from "./filter-cell-input";

export const FilterCell = ({
  column,
  width,
  columnFiltersState,
  handleChangeColumnFilterValue,
  handleColumnFiltersOptionChange,
}) => {
  const columnRef = useRef();
  const columnDimensions = useSize(columnRef) ?? { width: null };
  const columnWidth = columnDimensions.width;

  return (
    <Box
      ref={columnRef}
      style={{
        borderRight: "1px solid black",
        padding: "5px 2px 5px 5px",
      }}
      as="td"
      className="filter-cell"
      width={width || "auto"}
      minWidth={width || "auto"}
      maxWidth={width || "auto"}
    >
      <FilterCellInput
        type={column.formatType || column.type}
        column={column}
        columnFiltersState={columnFiltersState}
        handleChangeColumnFilterValue={handleChangeColumnFilterValue}
        handleColumnFiltersOptionChange={handleColumnFiltersOptionChange}
        hideFilter={columnWidth < 50}
      />
    </Box>
  );
};

FilterCell.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    editType: PropTypes.string,
    formatType: PropTypes.string,
    format: PropTypes.string,
  }),
  width: PropTypes.number,
  columnFiltersState: PropTypes.object,
  handleChangeColumnFilterValue: PropTypes.func,
  handleColumnFiltersOptionChange: PropTypes.func,
};

FilterCell.defaultProps = {};
