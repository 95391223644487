const extractValue = (value) => {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else if (value === "null") {
    return null;
  } else if (value === "undefined") {
    return undefined;
  } else if (
    value.startsWith('"') ||
    value.startsWith("'") ||
    value.startsWith("`")
  ) {
    return value.substring(1, value.length - 2);
  } else {
    return Number(value.replace(",", "."));
  }
};

export const parseCommaSeparatedParams = (parametersString) => {
  if (!parametersString) {
    return null;
  }
  const parametersObject = {};
  parametersString.split(",").forEach((keyValuePair) => {
    const kvp = keyValuePair.split("=");
    parametersObject[kvp[0].trim()] = extractValue(
      kvp.length > 1 ? kvp[1].trim() : "undefined",
    );
  });

  return parametersObject;
};

export const validateParametersString = (parametersString) =>
  /^\s*([a-zA-Z_]\w*[\s]*=[\s]*(-?[\s]*(0|[1-9][0-9]*|0[.][0-9]+|[1-9][.][0-9]+)|false|true|null|undefined|["][^"]*["]|['][^']*[']|[`][^`]*[`])[\s]*((?!.)|(?=\s)|,\s*(?=\S)))*$/.test(
    parametersString ?? "",
  );
