import PropTypes from "prop-types";
import { withBaseComponent } from "../../../with-base-component";
import EchoComponent from "../../../echo-component";
import DesignerShim from "../../../../shim-components/designer-shim/designer-shim";
import { useApiContext } from "../../../../../echo-components/api-context/use-api-context";
import { getDesignerStyleOptions } from "../../../../../echo-components/component-designer/utils/get-designer-style-options";

const ChildrenComponent = ({
  definition,
  onMouseOver,
  onCancelHover,
  localContext,
  onMoveElement,
  path,
  forceRendererMode,
  context,
}) => {
  const [apiContext, componentDefinition, mode] = useApiContext(
    definition?.id,
    definition?.guid,
    definition?.nodeId,
  );

  const dragMode = apiContext?.api?.getDragMode();

  const systemPropsObject = {
    id: componentDefinition.id,
    guid: componentDefinition.guid,
    nodeId: componentDefinition.nodeId,
    depth: componentDefinition.depth,
    localContext,
    dragMode,
    onMoveElement: onMoveElement,
    context,
    designerMode: mode === "designer" && !forceRendererMode ? true : false,
    component: componentDefinition.component,
    styleOptions: componentDefinition.styleOptions,
    childs: componentDefinition.childrenComponents,
    onComponentClick: apiContext?.api?.setActivePropertiesComponentId,
    activeAreaId: apiContext.api.GetDropAreaId(),
    onDropAreaChange: apiContext.api.ChangeDropAreaId,
    activePropertiesComponentId:
      apiContext?.api?.getActivePropertiesComponentId(),
    onMouseOver: () => onCancelHover(),
    onMouseLeave: () => apiContext.api.ChangeDropAreaId(componentDefinition.id),
    onCancelHover,
    path,
  };

  if (mode === "designer") {
    systemPropsObject.onClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      apiContext?.api?.setActivePropertiesComponentId(componentDefinition.id);
    };
  }

  return definition ? (
    <EchoComponent
      source={componentDefinition.component.source}
      context={context}
      styleOptions={{
        ...componentDefinition.styleOptions,
        ...getDesignerStyleOptions(
          false,
          mode === "designer" ? true : false,
          apiContext?.api?.getActivePropertiesComponentId(),
          0,
          componentDefinition.id,
        ),
      }}
      componentProps={{
        ...componentDefinition.componentProps,
        ...localContext,
        context,
      }}
      systemProps={systemPropsObject}
    />
  ) : (
    <div
      onMouseOver={onMouseOver}
      style={{ display: "flex", height: "100%", width: "100%" }}
    >
      {mode === "designer" ? <DesignerShim /> : null}
    </div>
  );
};

ChildrenComponent.propTypes = {
  definition: PropTypes.any,
  onMouseOver: PropTypes.func,
  onCancelHover: PropTypes.func,
  // TODO temporary
  localContext: PropTypes.any,
  onMoveElement: PropTypes.func,
  forceRendererMode: PropTypes.bool,
  mode: PropTypes.string,
  context: PropTypes.any,
  path: PropTypes.string.isRequired,
};

ChildrenComponent.defaultProps = {
  definition: null,
};

export default withBaseComponent(ChildrenComponent);
