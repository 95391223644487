// export const testStyleProps = [
//   {
//     styleProperty: "width",
//     displayName: "Szerokość",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "string",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "minWidth",
//     displayName: "Szerokość",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit|fit-content",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz|Wypełnij zawartość",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Szerokość (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Szerokość (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "height",
//     displayName: "Wysokość",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit|fit-content",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz|Wypełnij zawartość",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Wysokość (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Wysokość (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "marginTop",
//     displayName: "Margines górny",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines górny (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines górny (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "marginLeft",
//     displayName: "Margines lewy",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines lewy (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines lewy (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "marginRight",
//     displayName: "Margines prawy",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines prawy (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines prawy (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "marginBottom",
//     displayName: "Margines dolny",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "unset|auto|inherit",
//         displayName: "Nieustawiony|Automatycznie|Dziedzicz",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines dolny (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines dolny (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "borderColor",
//     displayName: "Kolor ramki",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "text",
//         valuePattern: "{value}",
//         displayName: "Kolor",
//       },
//     ],
//   },
//   {
//     styleProperty: "borderStyle",
//     displayName: "Styl ramki",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "string",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "borderWidth",
//     displayName: "Szerokość ramki",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Szerokość",
//       },
//     ],
//   },
//   {
//     styleProperty: "gap",
//     displayName: "Gap",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "inherit|initial|revert|revert-layer|unset",
//         displayName: "inherit|initial|revert|revert-layer|unset",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "gap (pixels)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Gap (percentage)",
//       },
//     ],
//   },
//   {
//     styleProperty: "flexDirection",
//     displayName: "Kierunek elementów",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "row|row-reverse|column|column-reverse",
//         displayName: "Wiesz|Wiersz (wstecz)|Kolumna|Kolumna (wstecz)",
//       },
//     ],
//   },
//   {
//     styleProperty: "justifyContent",
//     displayName: "Rozstawienie elementów",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern:
//           "space-between|space-around|space-evenly|center|start|end|flex-start|flex-end",
//         displayName:
//           "space-between|space-around|space-evenly|center|start|end|flex-start|flex-end",
//       },
//     ],
//   },
//   {
//     styleProperty: "alignItems",
//     displayName: "Dopasowanie elementów",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "center|start|end|flex-start|flex-end",
//         displayName: "center|start|end|flex-start|flex-end",
//       },
//     ],
//   },
//   {
//     styleProperty: "alignContent",
//     displayName: "Dopasowanie zawartości",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern:
//           "flex-start|flex-end|center|space-between|space-around|space-evenly|stretch|start|end|baseline|first baseline|last",
//         displayName:
//           "flex-start|flex-end|center|space-between|space-around|space-evenly|stretch|start|end|baseline|first baseline|last",
//       },
//     ],
//   },
//   {
//     styleProperty: "flexWrap",
//     displayName: "Zwijanie elementów",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "nowrap|wrap|wrap-reverse",
//         displayName: "nowrap|wrap|wrap-reverse",
//       },
//     ],
//   },
//   {
//     styleProperty: "order",
//     displayName: "Kolejność",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "flex",
//     displayName: "Flex",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "flexGrow",
//     displayName: "Rozszerzanie (flex)",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "flexShrink",
//     displayName: "Kurczenie (flex)",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "flexBasis",
//     displayName: "Bazowa szerokość (flex)",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "auto",
//         displayName: "Automatycznie",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Wartość",
//       },
//     ],
//   },
//   {
//     styleProperty: "alignSelf",
//     displayName: "Dopasowanie siebie",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "auto|flex-start|flex-end|center|baseline|stretch",
//         displayName: "auto|flex-start|flex-end|center|baseline|stretch",
//       },
//     ],
//   },
//   {
//     styleProperty: "backgroundColor",
//     displayName: "Kolor tła",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "text",
//         valuePattern: "{value}",
//         displayName: "Kolor",
//       },
//     ],
//   },
//   {
//     styleProperty: "color",
//     displayName: "Kolor czcionki",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "text",
//         valuePattern: "{value}",
//         displayName: "Kolor",
//       },
//     ],
//   },
//   {
//     styleProperty: "fontSize",
//     displayName: "Rozmiar czcionki",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}",
//         displayName: "Rozmiar czcionki",
//       },
//     ],
//   },
//   {
//     styleProperty: "textAlign",
//     displayName: "Wyrównanie tekstu",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "combo",
//         valuePattern: "center|start|end|justify",
//         displayName: "center|start|end|justify",
//       },
//     ],
//   },
//   {
//     styleProperty: "paddingLeft",
//     displayName: "Margines wewnętrzny lewy",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines wewnętrzny lewy (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines wewnętrzny lewy (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "paddingRight",
//     displayName: "Margines wewnętrzny prawy",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "number",
//         valuePattern: "{value}px",
//         displayName: "Margines wewnętrzny prawy (pixele)",
//       },
//       {
//         valueType: "number",
//         valuePattern: "{value}%",
//         displayName: "Margines wewnętrzny prawy (procentowo)",
//       },
//     ],
//   },
//   {
//     styleProperty: "padding",
//     displayName: "Margines wewnętrzny",
//     availableForControls: "!?all",
//     availableValues: [
//       {
//         valueType: "string",
//         valuePattern: "{value}",
//         displayName: "Margines wewnętrzny",
//       },
//     ],
//   },
// ];

export const testStyleProps = [
  {
    styleProperty: "width",
    displayName: "Szerokość",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "minWidth",
    displayName: "Szerokość",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Minimalna szerokość",
      },
    ],
  },
  {
    styleProperty: "height",
    displayName: "Wysokość",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wysokość",
      },
    ],
  },
  {
    styleProperty: "padding",
    displayName: "Margines wewnętrzny",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines wewnętrzny",
      },
    ],
  },
  {
    styleProperty: "margin",
    displayName: "Margines",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines",
      },
    ],
  },
  {
    styleProperty: "marginTop",
    displayName: "Margines górny",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines górny",
      },
    ],
  },

  {
    styleProperty: "marginRight",
    displayName: "Margines prawy",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines prawy",
      },
    ],
  },
  {
    styleProperty: "marginBottom",
    displayName: "Margines dolny",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines dolny",
      },
    ],
  },
  {
    styleProperty: "marginLeft",
    displayName: "Margines lewy",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Margines lewy",
      },
    ],
  },
  {
    styleProperty: "borderColor",
    displayName: "Kolor ramki",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Kolor",
      },
    ],
  },
  {
    styleProperty: "borderStyle",
    displayName: "Styl ramki",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "borderWidth",
    displayName: "Szerokość ramki",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Szerokość",
      },
    ],
  },
  {
    styleProperty: "flexDirection",
    displayName: "Kierunek elementów",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern: "row|row-reverse|column|column-reverse",
        displayName: "Wiesz|Wiersz (wstecz)|Kolumna|Kolumna (wstecz)",
      },
    ],
  },
  {
    styleProperty: "alignItems",
    displayName: "Dopasowanie elementów",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern: "center|start|end|flex-start|flex-end",
        displayName: "center|start|end|flex-start|flex-end",
      },
    ],
  },
  {
    styleProperty: "justifyContent",
    displayName: "Rozstawienie elementów",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern:
          "space-between|space-around|space-evenly|center|start|end|flex-start|flex-end",
        displayName:
          "space-between|space-around|space-evenly|center|start|end|flex-start|flex-end",
      },
    ],
  },
  {
    styleProperty: "gap",
    displayName: "Gap",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Gap",
      },
    ],
  },
  {
    styleProperty: "order",
    displayName: "Kolejność",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "alignContent",
    displayName: "Dopasowanie zawartości",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern:
          "flex-start|flex-end|center|space-between|space-around|space-evenly|stretch|start|end|baseline|first baseline|last",
        displayName:
          "flex-start|flex-end|center|space-between|space-around|space-evenly|stretch|start|end|baseline|first baseline|last",
      },
    ],
  },
  {
    styleProperty: "flexWrap",
    displayName: "Zwijanie elementów",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern: "nowrap|wrap|wrap-reverse",
        displayName: "nowrap|wrap|wrap-reverse",
      },
    ],
  },
  {
    styleProperty: "flex",
    displayName: "Flex",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "flexGrow",
    displayName: "Rozszerzanie (flex)",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "flexShrink",
    displayName: "Kurczenie (flex)",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "flexBasis",
    displayName: "Bazowa szerokość (flex)",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "alignSelf",
    displayName: "Dopasowanie siebie",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern: "auto|flex-start|flex-end|center|baseline|stretch",
        displayName: "auto|flex-start|flex-end|center|baseline|stretch",
      },
    ],
  },
  {
    styleProperty: "backgroundColor",
    displayName: "Kolor tła",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Kolor",
      },
    ],
  },
  {
    styleProperty: "color",
    displayName: "Kolor czcionki",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Kolor",
      },
    ],
  },
  {
    styleProperty: "fontSize",
    displayName: "Rozmiar czcionki",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "number",
        valuePattern: "{value}",
        displayName: "Rozmiar czcionki",
      },
    ],
  },
  {
    styleProperty: "textAlign",
    displayName: "Wyrównanie tekstu",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "combo",
        valuePattern: "center|start|end|justify",
        displayName: "center|start|end|justify",
      },
    ],
  },
  {
    styleProperty: "whiteSpace",
    displayName: "Zawijanie wiersza",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "string",
        valuePattern: "{value}",
        displayName: "Wartość",
      },
    ],
  },
  {
    styleProperty: "position",
    displayName: "Pozycja elementu",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Position",
      },
    ],
  },
  {
    styleProperty: "inset",
    displayName: "odległość od krawędzi",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Inset",
      },
    ],
  },
  {
    styleProperty: "overflowX",
    displayName: "",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Overflow X",
      },
    ],
  },
  {
    styleProperty: "overflowY",
    displayName: "",
    availableForControls: "!?all",
    availableValues: [
      {
        valueType: "text",
        valuePattern: "{value}",
        displayName: "Overflow Y",
      },
    ],
  },
];
