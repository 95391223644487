import {
  getCurrentPart,
  getPartsFormats,
  getPartsRanges,
} from "./get-parts-ranges";
import { getSeparator } from "./get-separator";
import { goToNextPart, goToPrevPart } from "./switch-part";
import { isDateMatched } from "./validate-character";

export const handleEnter = (e, date, format, callback) => {
  const pos = e.target.selectionEnd;
  const { current } = getSeparator(format, date);
  const currentPart = getPart(pos, date, format);
  const datePart = date.slice(currentPart.start, currentPart.end);
  const formatParts = getPartsFormats(format);
  const formatPart = formatParts.filter(({ id }) => id <= currentPart.id)[
    currentPart.id
  ]?.format;
  const isMatched = isDateMatched(datePart, formatPart);

  if (e.shiftKey) {
    goToPrevPart(e, format, currentPart, date);
    return;
  }
  if (isMatched || datePart === "") {
    if (current) {
      if (parseInt(datePart) < 10) {
        const newDatePart = "0" + datePart;
        callback(date.slice(0, -datePart.length) + newDatePart + current);
      } else {
        callback(date + current);
      }
    }
    goToNextPart(e, format, currentPart, date);
  }
};

export const handleBackspace = (e, date, format) => {
  const pos = e.target.selectionEnd;
  const { usedSeparatorsObj } = getSeparator(format, date);
  const currentPart = getPart(pos, date, format);

  const separator = usedSeparatorsObj.findLast((s) => s.idx <= pos)?.char;
  if (date[pos - 1] === separator) {
    e.preventDefault();
    goToPrevPart(e, format, currentPart, date);
  }
};

const getPart = (pos, date, format) => {
  const { usedSeparators } = getSeparator(format, date);
  const parts = getPartsRanges(date, usedSeparators);
  const currentPart = getCurrentPart(pos, parts);
  return currentPart;
};
