import getToken from "./token-service";

export const signIn = (login, password, companyId, groupId) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(process.env.API_URL + "Auth/Login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
      },
      body: JSON.stringify({ login, password, companyId, groupId }),
    })
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Login error. Please check login or password.");
        }
        return response.text();
      })
      .then((result) => {
        resolve(result);
      })
      .catch(reject);
  });

export const changeContext = (userId) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(process.env.API_URL + "Auth/changeContext?userId=" + userId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Login error. Please check login or password.");
        }
        return response.text();
      })
      .then((result) => {
        resolve(result);
      })
      .catch(reject);
  });

// export const checkUser = (login, password) =>
//   new Promise((resolve, reject) => {
//     // eslint-disable-next-line no-undef
//     fetch(process.env.API_URL + "Auth/CheckUser", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "text/plain",
//       },
//       body: JSON.stringify({ login, password }),
//     })
//       .then((response) => {
//         if (response.status === 401) {
//           throw new Error("Login error. Please check login or password.");
//         }
//         return response.json();
//       })
//       .then((result) => {
//         resolve(result);
//       })
//       .catch(reject);
//   });

export const checkUser = async (login, password) => {
  try {
    const res = await fetch(process.env.API_URL + "Auth/CheckUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
      },
      body: JSON.stringify({ login, password }),
    });

    if (!res.ok) {
      const error = await res.text();
      throw error;
    }

    const data = await res.json();
    return data;
  } catch (e) {
    throw new Error(e);
  }
};

export const setUserPassword = (password, newPassword) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(process.env.API_URL + "Auth/changePassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        password,
        newPassword,
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });

export const resetUserPassword = (id) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    fetch(process.env.API_URL + "Auth/resetPassword", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        password: "ustawHaslo",
        newPassword: "",
      }),
    })
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch(reject);
  });
